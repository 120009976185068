import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: any; output: any; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: { input: any; output: any; }
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AddFeedbackMessageInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
};

export type AddFeedbackMessagePayload = {
  __typename?: 'AddFeedbackMessagePayload';
  feedbackMessage?: Maybe<FeedbackMessage>;
};

export type AdminDashboardGraph = {
  __typename?: 'AdminDashboardGraph';
  date: Scalars['DateTime']['output'];
  totalAmountApproved: Scalars['Decimal']['output'];
};

export type AdminSummaryReport = {
  __typename?: 'AdminSummaryReport';
  count: Scalars['Int']['output'];
  reportType: Scalars['String']['output'];
  total: Scalars['Decimal']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type ApprovePaymentInput = {
  amountApproved?: InputMaybe<Scalars['Decimal']['input']>;
  paymentApprovalStatusId: Scalars['Int']['input'];
  paymentId: Scalars['Int']['input'];
  reasonId?: InputMaybe<Scalars['Int']['input']>;
  rejectionComment?: InputMaybe<Scalars['String']['input']>;
};

export type ApprovePaymentPayload = {
  __typename?: 'ApprovePaymentPayload';
  payment?: Maybe<Payment>;
};

export type Bank = {
  __typename?: 'Bank';
  bankBranches: Array<BankBranch>;
  code: Scalars['String']['output'];
  country: Country;
  countryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BankBranch = {
  __typename?: 'BankBranch';
  bankId: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BankBranchFilterInput = {
  and?: InputMaybe<Array<BankBranchFilterInput>>;
  bankId?: InputMaybe<IntOperationFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BankBranchFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type BankBranchSortInput = {
  bankId?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BankBranchesConnection = {
  __typename?: 'BankBranchesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BankBranchesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BankBranch>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BankBranchesEdge = {
  __typename?: 'BankBranchesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BankBranch;
};

export type BankFilterInput = {
  and?: InputMaybe<Array<BankFilterInput>>;
  bankBranches?: InputMaybe<ListFilterInputTypeOfBankBranchFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<CountryFilterInput>;
  countryId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BankFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type BankSortInput = {
  code?: InputMaybe<SortEnumType>;
  country?: InputMaybe<CountrySortInput>;
  countryId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BanksConnection = {
  __typename?: 'BanksConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BanksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Bank>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BanksEdge = {
  __typename?: 'BanksEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Bank;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of items. */
export type CompaniesConnection = {
  __typename?: 'CompaniesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CompaniesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Company>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CompaniesEdge = {
  __typename?: 'CompaniesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Company;
};

export type Company = {
  __typename?: 'Company';
  code: Scalars['String']['output'];
  country: Country;
  countryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Array<Currency>;
  customerSegments: Array<CustomerSegment>;
  customerTranscription: Scalars['Boolean']['output'];
  customers: Array<Customer>;
  defaultCurrencyCode: Scalars['String']['output'];
  defaultCurrencySymbol: Scalars['String']['output'];
  defaultSupplier?: Maybe<Supplier>;
  defaultSupplierId?: Maybe<Scalars['Int']['output']>;
  eamOrganization: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locations: Array<Location>;
  mobileNumber?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sunBusinessUnit: Scalars['String']['output'];
  suppliers: Array<Supplier>;
  telephoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<User>;
};

export type CompanyCurrency = {
  __typename?: 'CompanyCurrency';
  companyId: Scalars['Int']['output'];
  currencyId: Scalars['Int']['output'];
};

export type CompanyCurrencyFilterInput = {
  and?: InputMaybe<Array<CompanyCurrencyFilterInput>>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  currencyId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CompanyCurrencyFilterInput>>;
};

export type CompanyCurrencySortInput = {
  companyId?: InputMaybe<SortEnumType>;
  currencyId?: InputMaybe<SortEnumType>;
};

export type CompanyFilterInput = {
  and?: InputMaybe<Array<CompanyFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<CountryFilterInput>;
  countryId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  currency?: InputMaybe<ListFilterInputTypeOfCurrencyFilterInput>;
  customerSegments?: InputMaybe<ListFilterInputTypeOfCustomerSegmentFilterInput>;
  customerTranscription?: InputMaybe<BooleanOperationFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCustomerFilterInput>;
  defaultCurrencyCode?: InputMaybe<StringOperationFilterInput>;
  defaultCurrencySymbol?: InputMaybe<StringOperationFilterInput>;
  defaultSupplier?: InputMaybe<SupplierFilterInput>;
  defaultSupplierId?: InputMaybe<IntOperationFilterInput>;
  eamOrganization?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  locations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  mobileNumber?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CompanyFilterInput>>;
  sunBusinessUnit?: InputMaybe<StringOperationFilterInput>;
  suppliers?: InputMaybe<ListFilterInputTypeOfSupplierFilterInput>;
  telephoneNumber?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type CompanySms = {
  __typename?: 'CompanySMS';
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  login: Scalars['String']['output'];
  sender: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type CompanySocialMedia = {
  __typename?: 'CompanySocialMedia';
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  socialMediaLink: Scalars['String']['output'];
  socialMediaType: SocialMediaType;
  socialMediaTypeId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanySocialMediaFilterInput = {
  and?: InputMaybe<Array<CompanySocialMediaFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CompanySocialMediaFilterInput>>;
  socialMediaLink?: InputMaybe<StringOperationFilterInput>;
  socialMediaType?: InputMaybe<SocialMediaTypeFilterInput>;
  socialMediaTypeId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CompanySocialMediaSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  socialMediaLink?: InputMaybe<SortEnumType>;
  socialMediaType?: InputMaybe<SocialMediaTypeSortInput>;
  socialMediaTypeId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type CompanySortInput = {
  code?: InputMaybe<SortEnumType>;
  country?: InputMaybe<CountrySortInput>;
  countryId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customerTranscription?: InputMaybe<SortEnumType>;
  defaultCurrencyCode?: InputMaybe<SortEnumType>;
  defaultCurrencySymbol?: InputMaybe<SortEnumType>;
  defaultSupplier?: InputMaybe<SupplierSortInput>;
  defaultSupplierId?: InputMaybe<SortEnumType>;
  eamOrganization?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  mobileNumber?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sunBusinessUnit?: InputMaybe<SortEnumType>;
  telephoneNumber?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type Contact = {
  __typename?: 'Contact';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  location: Location;
  locationId: Scalars['Int']['output'];
  physicalAddress: Scalars['String']['output'];
  postalAddress?: Maybe<Scalars['String']['output']>;
  telephoneNumber: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactFilterInput = {
  and?: InputMaybe<Array<ContactFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ContactFilterInput>>;
  physicalAddress?: InputMaybe<StringOperationFilterInput>;
  postalAddress?: InputMaybe<StringOperationFilterInput>;
  telephoneNumber?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ContactSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  physicalAddress?: InputMaybe<SortEnumType>;
  postalAddress?: InputMaybe<SortEnumType>;
  telephoneNumber?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ContactsConnection = {
  __typename?: 'ContactsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContactsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ContactsEdge = {
  __typename?: 'ContactsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Contact;
};

export type Content = {
  __typename?: 'Content';
  author: User;
  authorId: Scalars['Int']['output'];
  company: Company;
  companyId: Scalars['Int']['output'];
  contentType: ContentType;
  contentTypeId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  featuredImageUrl?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isPublished: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContentFilterInput = {
  and?: InputMaybe<Array<ContentFilterInput>>;
  author?: InputMaybe<UserFilterInput>;
  authorId?: InputMaybe<IntOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contentType?: InputMaybe<ContentTypeFilterInput>;
  contentTypeId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  featuredImageUrl?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isPublished?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ContentFilterInput>>;
  title?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ContentSortInput = {
  author?: InputMaybe<UserSortInput>;
  authorId?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  contentType?: InputMaybe<ContentTypeSortInput>;
  contentTypeId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  featuredImageUrl?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isPublished?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type ContentType = {
  __typename?: 'ContentType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type ContentTypeFilterInput = {
  and?: InputMaybe<Array<ContentTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ContentTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type ContentTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ContentsConnection = {
  __typename?: 'ContentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Content>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ContentsEdge = {
  __typename?: 'ContentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Content;
};

export type Country = {
  __typename?: 'Country';
  adGroupId: Scalars['UUID']['output'];
  adGroupName: Scalars['String']['output'];
  alpha2?: Maybe<Scalars['String']['output']>;
  alpha3?: Maybe<Scalars['String']['output']>;
  callingCode: Scalars['String']['output'];
  cluster?: Maybe<Scalars['Int']['output']>;
  companies: Array<Company>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  utcOffset: Scalars['Int']['output'];
};

export type CountryFilterInput = {
  adGroupId?: InputMaybe<UuidOperationFilterInput>;
  adGroupName?: InputMaybe<StringOperationFilterInput>;
  alpha2?: InputMaybe<StringOperationFilterInput>;
  alpha3?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CountryFilterInput>>;
  callingCode?: InputMaybe<StringOperationFilterInput>;
  cluster?: InputMaybe<IntOperationFilterInput>;
  companies?: InputMaybe<ListFilterInputTypeOfCompanyFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CountryFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  utcOffset?: InputMaybe<IntOperationFilterInput>;
};

export type CountrySortInput = {
  adGroupId?: InputMaybe<SortEnumType>;
  adGroupName?: InputMaybe<SortEnumType>;
  alpha2?: InputMaybe<SortEnumType>;
  alpha3?: InputMaybe<SortEnumType>;
  callingCode?: InputMaybe<SortEnumType>;
  cluster?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  utcOffset?: InputMaybe<SortEnumType>;
};

export type CreateNotificationsInput = {
  notificationTypeId: Scalars['Int']['input'];
  orderReference: Scalars['String']['input'];
};

export type CreateNotificationsPayload = {
  __typename?: 'CreateNotificationsPayload';
  notification?: Maybe<Notification>;
};

export type Currency = {
  __typename?: 'Currency';
  company: Array<Company>;
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  currencyName: Scalars['String']['output'];
  currencySymbol: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** A connection to a list of items. */
export type CurrencyConnection = {
  __typename?: 'CurrencyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CurrencyEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Currency>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CurrencyEdge = {
  __typename?: 'CurrencyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Currency;
};

export type CurrencyFilterInput = {
  and?: InputMaybe<Array<CurrencyFilterInput>>;
  company?: InputMaybe<ListFilterInputTypeOfCompanyFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  currencyCode?: InputMaybe<StringOperationFilterInput>;
  currencyName?: InputMaybe<StringOperationFilterInput>;
  currencySymbol?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CurrencyFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CurrencySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  currencyCode?: InputMaybe<SortEnumType>;
  currencyName?: InputMaybe<SortEnumType>;
  currencySymbol?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type Customer = {
  __typename?: 'Customer';
  active: Scalars['Boolean']['output'];
  altPhoneNumber?: Maybe<Scalars['Long']['output']>;
  businessUnitCurrency?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerAccountBalance?: Maybe<Scalars['Decimal']['output']>;
  customerAvailableCredit?: Maybe<Scalars['Decimal']['output']>;
  customerAvailableCreditWithoutOrders?: Maybe<Scalars['Decimal']['output']>;
  customerCode: Scalars['String']['output'];
  customerCountry?: Maybe<Scalars['String']['output']>;
  customerCrLimit?: Maybe<Scalars['Decimal']['output']>;
  customerCrLimitExpDate?: Maybe<Scalars['Date']['output']>;
  customerCurrency?: Maybe<Scalars['String']['output']>;
  customerName: Scalars['String']['output'];
  customerOnOrderBalance?: Maybe<Scalars['Decimal']['output']>;
  customerOpenOrders?: Maybe<Scalars['Decimal']['output']>;
  customerOverdueInvoiceAmt?: Maybe<Scalars['Decimal']['output']>;
  customerSegment?: Maybe<CustomerSegment>;
  customerSegmentId?: Maybe<Scalars['Int']['output']>;
  customerStatus?: Maybe<Scalars['Short']['output']>;
  customerStatusDesc?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['Int']['output'];
  phoneNumber?: Maybe<Scalars['Long']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<User>;
  vehicles: Array<Vehicle>;
};

/** A connection to a list of items. */
export type CustomerAccountUsersConnection = {
  __typename?: 'CustomerAccountUsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerAccountUsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomerAccountUsersEdge = {
  __typename?: 'CustomerAccountUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: User;
};

export type CustomerFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  altPhoneNumber?: InputMaybe<LongOperationFilterInput>;
  and?: InputMaybe<Array<CustomerFilterInput>>;
  businessUnitCurrency?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  customerAccountBalance?: InputMaybe<DecimalOperationFilterInput>;
  customerAvailableCredit?: InputMaybe<DecimalOperationFilterInput>;
  customerAvailableCreditWithoutOrders?: InputMaybe<DecimalOperationFilterInput>;
  customerCode?: InputMaybe<StringOperationFilterInput>;
  customerCountry?: InputMaybe<StringOperationFilterInput>;
  customerCrLimit?: InputMaybe<DecimalOperationFilterInput>;
  customerCrLimitExpDate?: InputMaybe<DateOperationFilterInput>;
  customerCurrency?: InputMaybe<StringOperationFilterInput>;
  customerName?: InputMaybe<StringOperationFilterInput>;
  customerOnOrderBalance?: InputMaybe<DecimalOperationFilterInput>;
  customerOpenOrders?: InputMaybe<DecimalOperationFilterInput>;
  customerOverdueInvoiceAmt?: InputMaybe<DecimalOperationFilterInput>;
  customerSegment?: InputMaybe<CustomerSegmentFilterInput>;
  customerSegmentId?: InputMaybe<IntOperationFilterInput>;
  customerStatus?: InputMaybe<ShortOperationFilterInput>;
  customerStatusDesc?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  exchangeRate?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFilterInput>>;
  phoneNumber?: InputMaybe<LongOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  vehicles?: InputMaybe<ListFilterInputTypeOfVehicleFilterInput>;
};

/** A connection to a list of items. */
export type CustomerSalesVoucherOrdersConnection = {
  __typename?: 'CustomerSalesVoucherOrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerSalesVoucherOrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SalesVoucherOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomerSalesVoucherOrdersEdge = {
  __typename?: 'CustomerSalesVoucherOrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SalesVoucherOrder;
};

export type CustomerSegment = {
  __typename?: 'CustomerSegment';
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerSegmentFilterInput = {
  and?: InputMaybe<Array<CustomerSegmentFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerSegmentFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CustomerSegmentSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type CustomerSegmentsConnection = {
  __typename?: 'CustomerSegmentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomerSegmentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CustomerSegment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomerSegmentsEdge = {
  __typename?: 'CustomerSegmentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomerSegment;
};

export type CustomerSortInput = {
  active?: InputMaybe<SortEnumType>;
  altPhoneNumber?: InputMaybe<SortEnumType>;
  businessUnitCurrency?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customerAccountBalance?: InputMaybe<SortEnumType>;
  customerAvailableCredit?: InputMaybe<SortEnumType>;
  customerAvailableCreditWithoutOrders?: InputMaybe<SortEnumType>;
  customerCode?: InputMaybe<SortEnumType>;
  customerCountry?: InputMaybe<SortEnumType>;
  customerCrLimit?: InputMaybe<SortEnumType>;
  customerCrLimitExpDate?: InputMaybe<SortEnumType>;
  customerCurrency?: InputMaybe<SortEnumType>;
  customerName?: InputMaybe<SortEnumType>;
  customerOnOrderBalance?: InputMaybe<SortEnumType>;
  customerOpenOrders?: InputMaybe<SortEnumType>;
  customerOverdueInvoiceAmt?: InputMaybe<SortEnumType>;
  customerSegment?: InputMaybe<CustomerSegmentSortInput>;
  customerSegmentId?: InputMaybe<SortEnumType>;
  customerStatus?: InputMaybe<SortEnumType>;
  customerStatusDesc?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  exchangeRate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type CustomerTranscriptionItemInput = {
  bankBranchId?: InputMaybe<Scalars['Int']['input']>;
  bankId?: InputMaybe<Scalars['Int']['input']>;
  depositorContact?: InputMaybe<Scalars['String']['input']>;
  depositorName?: InputMaybe<Scalars['String']['input']>;
  documentDate: Scalars['DateTime']['input'];
  documentReference?: InputMaybe<Scalars['String']['input']>;
  documentTypeId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentTranscriptionStatusId?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of items. */
export type CustomersConnection = {
  __typename?: 'CustomersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Customer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomersEdge = {
  __typename?: 'CustomersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Customer;
};

export type DashboardReport = {
  __typename?: 'DashboardReport';
  dashboardReportTypeEnumId: Scalars['Int']['output'];
  dataPoints: Array<KeyValuePairOfDateTimeAndDecimal>;
  difference: Scalars['Decimal']['output'];
  percentage: Scalars['Decimal']['output'];
  total: Scalars['Decimal']['output'];
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  neq?: InputMaybe<Scalars['Date']['input']>;
  ngt?: InputMaybe<Scalars['Date']['input']>;
  ngte?: InputMaybe<Scalars['Date']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  nlt?: InputMaybe<Scalars['Date']['input']>;
  nlte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DeleteContactInput = {
  id: Scalars['Int']['input'];
};

export type DeleteContactPayload = {
  __typename?: 'DeleteContactPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteContentInput = {
  id: Scalars['Int']['input'];
};

export type DeleteContentPayload = {
  __typename?: 'DeleteContentPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type DeletePredefinedReportFilterInput = {
  id: Scalars['Int']['input'];
};

export type DeletePredefinedReportFilterPayload = {
  __typename?: 'DeletePredefinedReportFilterPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteProductConstraintInput = {
  id: Scalars['Int']['input'];
};

export type DeleteProductConstraintPayload = {
  __typename?: 'DeleteProductConstraintPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteProductInput = {
  id: Scalars['Int']['input'];
};

export type DeleteProductPayload = {
  __typename?: 'DeleteProductPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type DescriptionValue = {
  __typename?: 'DescriptionValue';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type DescriptionValueFilterInput = {
  and?: InputMaybe<Array<DescriptionValueFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DescriptionValueFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type DescriptionValueSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type Destination = {
  __typename?: 'Destination';
  code: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['Int']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  lookup?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  transportType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DestinationFilterInput = {
  and?: InputMaybe<Array<DestinationFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lookup?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DestinationFilterInput>>;
  region?: InputMaybe<StringOperationFilterInput>;
  transportType?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type DestinationSortInput = {
  code?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lookup?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  region?: InputMaybe<SortEnumType>;
  transportType?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type DestinationsConnection = {
  __typename?: 'DestinationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<DestinationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Destination>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DestinationsEdge = {
  __typename?: 'DestinationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Destination;
};

export type DocumentStatus = {
  __typename?: 'DocumentStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type DocumentStatusFilterInput = {
  and?: InputMaybe<Array<DocumentStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DocumentStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type DocumentStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type DocumentTypeFilterInput = {
  and?: InputMaybe<Array<DocumentTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DocumentTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type DocumentTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type Driver = {
  __typename?: 'Driver';
  comment?: Maybe<Scalars['String']['output']>;
  country: Country;
  countryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  dispatch: Scalars['Boolean']['output'];
  eamId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isBanned: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  licenseExpDate?: Maybe<Scalars['Date']['output']>;
  licenseNo: Scalars['String']['output'];
  phoneNo?: Maybe<Scalars['String']['output']>;
  receiving: Scalars['Boolean']['output'];
  transporterCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DriverFilterInput = {
  and?: InputMaybe<Array<DriverFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<CountryFilterInput>;
  countryId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  dispatch?: InputMaybe<BooleanOperationFilterInput>;
  eamId?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isBanned?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  licenseExpDate?: InputMaybe<DateOperationFilterInput>;
  licenseNo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DriverFilterInput>>;
  phoneNo?: InputMaybe<StringOperationFilterInput>;
  receiving?: InputMaybe<BooleanOperationFilterInput>;
  transporterCode?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type DriverSortInput = {
  comment?: InputMaybe<SortEnumType>;
  country?: InputMaybe<CountrySortInput>;
  countryId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  dispatch?: InputMaybe<SortEnumType>;
  eamId?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isBanned?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  licenseExpDate?: InputMaybe<SortEnumType>;
  licenseNo?: InputMaybe<SortEnumType>;
  phoneNo?: InputMaybe<SortEnumType>;
  receiving?: InputMaybe<SortEnumType>;
  transporterCode?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type DriversConnection = {
  __typename?: 'DriversConnection';
  /** A list of edges. */
  edges?: Maybe<Array<DriversEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Driver>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DriversEdge = {
  __typename?: 'DriversEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Driver;
};

export type FeedbackMessage = {
  __typename?: 'FeedbackMessage';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FeedbackMessageFilterInput = {
  and?: InputMaybe<Array<FeedbackMessageFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FeedbackMessageFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type FeedbackMessageSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  message?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type KeyValuePairOfDateTimeAndDecimal = {
  __typename?: 'KeyValuePairOfDateTimeAndDecimal';
  key: Scalars['DateTime']['output'];
  value: Scalars['Decimal']['output'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  lang: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LanguageFilterInput = {
  and?: InputMaybe<Array<LanguageFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lang?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LanguageFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LanguageSortInput = {
  code?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lang?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type ListFilterInputTypeOfBankBranchFilterInput = {
  all?: InputMaybe<BankBranchFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<BankBranchFilterInput>;
  some?: InputMaybe<BankBranchFilterInput>;
};

export type ListFilterInputTypeOfCompanyFilterInput = {
  all?: InputMaybe<CompanyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CompanyFilterInput>;
  some?: InputMaybe<CompanyFilterInput>;
};

export type ListFilterInputTypeOfCurrencyFilterInput = {
  all?: InputMaybe<CurrencyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CurrencyFilterInput>;
  some?: InputMaybe<CurrencyFilterInput>;
};

export type ListFilterInputTypeOfCustomerFilterInput = {
  all?: InputMaybe<CustomerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CustomerFilterInput>;
  some?: InputMaybe<CustomerFilterInput>;
};

export type ListFilterInputTypeOfCustomerSegmentFilterInput = {
  all?: InputMaybe<CustomerSegmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CustomerSegmentFilterInput>;
  some?: InputMaybe<CustomerSegmentFilterInput>;
};

export type ListFilterInputTypeOfLocationFilterInput = {
  all?: InputMaybe<LocationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<LocationFilterInput>;
  some?: InputMaybe<LocationFilterInput>;
};

export type ListFilterInputTypeOfNotificationPreferenceTypeFilterInput = {
  all?: InputMaybe<NotificationPreferenceTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NotificationPreferenceTypeFilterInput>;
  some?: InputMaybe<NotificationPreferenceTypeFilterInput>;
};

export type ListFilterInputTypeOfNotificationUserFilterInput = {
  all?: InputMaybe<NotificationUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NotificationUserFilterInput>;
  some?: InputMaybe<NotificationUserFilterInput>;
};

export type ListFilterInputTypeOfPaymentApprovalFilterInput = {
  all?: InputMaybe<PaymentApprovalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PaymentApprovalFilterInput>;
  some?: InputMaybe<PaymentApprovalFilterInput>;
};

export type ListFilterInputTypeOfPaymentDocumentFilterInput = {
  all?: InputMaybe<PaymentDocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PaymentDocumentFilterInput>;
  some?: InputMaybe<PaymentDocumentFilterInput>;
};

export type ListFilterInputTypeOfPaymentFilterInput = {
  all?: InputMaybe<PaymentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PaymentFilterInput>;
  some?: InputMaybe<PaymentFilterInput>;
};

export type ListFilterInputTypeOfPaymentTranscriptionFilterInput = {
  all?: InputMaybe<PaymentTranscriptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PaymentTranscriptionFilterInput>;
  some?: InputMaybe<PaymentTranscriptionFilterInput>;
};

export type ListFilterInputTypeOfRoleFilterInput = {
  all?: InputMaybe<RoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RoleFilterInput>;
  some?: InputMaybe<RoleFilterInput>;
};

export type ListFilterInputTypeOfSalesVoucherHistoryFilterInput = {
  all?: InputMaybe<SalesVoucherHistoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SalesVoucherHistoryFilterInput>;
  some?: InputMaybe<SalesVoucherHistoryFilterInput>;
};

export type ListFilterInputTypeOfSalesVoucherItemFilterInput = {
  all?: InputMaybe<SalesVoucherItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SalesVoucherItemFilterInput>;
  some?: InputMaybe<SalesVoucherItemFilterInput>;
};

export type ListFilterInputTypeOfSalesVoucherOrderFilterInput = {
  all?: InputMaybe<SalesVoucherOrderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SalesVoucherOrderFilterInput>;
  some?: InputMaybe<SalesVoucherOrderFilterInput>;
};

export type ListFilterInputTypeOfSupplierFilterInput = {
  all?: InputMaybe<SupplierFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SupplierFilterInput>;
  some?: InputMaybe<SupplierFilterInput>;
};

export type ListFilterInputTypeOfUserDeviceFilterInput = {
  all?: InputMaybe<UserDeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UserDeviceFilterInput>;
  some?: InputMaybe<UserDeviceFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
};

export type ListFilterInputTypeOfVehicleFilterInput = {
  all?: InputMaybe<VehicleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<VehicleFilterInput>;
  some?: InputMaybe<VehicleFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type Location = {
  __typename?: 'Location';
  allowDeliveryTypeSelection: Scalars['Boolean']['output'];
  allowDriverSelection: Scalars['Boolean']['output'];
  allowTruckSelection: Scalars['Boolean']['output'];
  autoConfirmNewSOs: Scalars['Boolean']['output'];
  businessUnitLine?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['Int']['output'];
  contact?: Maybe<Contact>;
  costCentre?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  defaultDeliveryType?: Maybe<Scalars['String']['output']>;
  defaultTransporter?: Maybe<Scalars['String']['output']>;
  descriptionValue: DescriptionValue;
  descriptionValueId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  parentLocation?: Maybe<Location>;
  parentLocationId?: Maybe<Scalars['Int']['output']>;
  pmEmployee: Scalars['Boolean']['output'];
  quotationRentionDuration: Scalars['Int']['output'];
  truckSelectionAllowed: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  users: Array<User>;
  vatCode?: Maybe<Scalars['String']['output']>;
};

export type LocationFilterInput = {
  allowDeliveryTypeSelection?: InputMaybe<BooleanOperationFilterInput>;
  allowDriverSelection?: InputMaybe<BooleanOperationFilterInput>;
  allowTruckSelection?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<LocationFilterInput>>;
  autoConfirmNewSOs?: InputMaybe<BooleanOperationFilterInput>;
  businessUnitLine?: InputMaybe<StringOperationFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  contact?: InputMaybe<ContactFilterInput>;
  costCentre?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  defaultDeliveryType?: InputMaybe<StringOperationFilterInput>;
  defaultTransporter?: InputMaybe<StringOperationFilterInput>;
  descriptionValue?: InputMaybe<DescriptionValueFilterInput>;
  descriptionValueId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  latitude?: InputMaybe<DecimalOperationFilterInput>;
  longitude?: InputMaybe<DecimalOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationFilterInput>>;
  parentLocation?: InputMaybe<LocationFilterInput>;
  parentLocationId?: InputMaybe<IntOperationFilterInput>;
  pmEmployee?: InputMaybe<BooleanOperationFilterInput>;
  quotationRentionDuration?: InputMaybe<IntOperationFilterInput>;
  truckSelectionAllowed?: InputMaybe<BooleanOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  vatCode?: InputMaybe<StringOperationFilterInput>;
};

export type LocationSortInput = {
  allowDeliveryTypeSelection?: InputMaybe<SortEnumType>;
  allowDriverSelection?: InputMaybe<SortEnumType>;
  allowTruckSelection?: InputMaybe<SortEnumType>;
  autoConfirmNewSOs?: InputMaybe<SortEnumType>;
  businessUnitLine?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  contact?: InputMaybe<ContactSortInput>;
  costCentre?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  defaultDeliveryType?: InputMaybe<SortEnumType>;
  defaultTransporter?: InputMaybe<SortEnumType>;
  descriptionValue?: InputMaybe<DescriptionValueSortInput>;
  descriptionValueId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentLocation?: InputMaybe<LocationSortInput>;
  parentLocationId?: InputMaybe<SortEnumType>;
  pmEmployee?: InputMaybe<SortEnumType>;
  quotationRentionDuration?: InputMaybe<SortEnumType>;
  truckSelectionAllowed?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  vatCode?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type LocationsConnection = {
  __typename?: 'LocationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LocationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Location>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LocationsEdge = {
  __typename?: 'LocationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Location;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addFeedbackMessage: AddFeedbackMessagePayload;
  approvePayment: ApprovePaymentPayload;
  createNotifications: CreateNotificationsPayload;
  deleteContact: DeleteContactPayload;
  deleteContent: DeleteContentPayload;
  deletePredefinedReportFilter: DeletePredefinedReportFilterPayload;
  deleteProduct: DeleteProductPayload;
  deleteProductConstraint: DeleteProductConstraintPayload;
  readAllUserNotification: ReadAllUserNotificationPayload;
  readUserNotification: ReadUserNotificationPayload;
  removeUserDevice: RemoveUserDevicePayload;
  statementRequest: StatementRequestPayload;
  unlinkUserAccount: UnlinkUserAccountPayload;
  updateUser: UpdateUserPayload;
  updateUserAccount: UpdateUserAccountPayload;
  updateUserActive: UpdateUserActivePayload;
  updateUserDefaultLanguage: UpdateUserDefaultLanguagePayload;
  updateUserProfile: UpdateUserProfilePayload;
  updateUserTermsAndCondition: UpdateUserTermsAndConditionPayload;
  upsertBank: UpsertBankPayload;
  upsertBankBranch: UpsertBankBranchPayload;
  upsertCompany: UpsertCompanyPayload;
  upsertCompanyCurrency: UpsertCompanyCurrencyPayload;
  upsertCompanySocialMedia: UpsertCompanySocialMediaPayload;
  upsertContact: UpsertContactPayload;
  upsertContent: UpsertContentPayload;
  upsertCurrency: UpsertCurrencyPayload;
  upsertCustomer: UpsertCustomerPayload;
  upsertCustomerVehicle: UpsertCustomerVehiclePayload;
  upsertLocation: UpsertLocationPayload;
  upsertPayment: UpsertPaymentPayload;
  upsertPaymentApproval: UpsertPaymentApprovalPayload;
  upsertPaymentTranscription: UpsertPaymentTranscriptionPayload;
  upsertPredefinedReportFilter: UpsertPredefinedReportFilterPayload;
  upsertProduct: UpsertProductPayload;
  upsertProductConstraint: UpsertProductConstraintPayload;
  upsertSMSConfiguration: UpsertSmsConfigurationPayload;
  upsertSalesVoucher: UpsertSalesVoucherPayload;
  upsertSettlementBank: UpsertSettlementBankPayload;
  upsertTermsAndCondition: UpsertTermsAndConditionPayload;
  upsertUserDevice: UpsertUserDevicePayload;
  upsertUserNotificationPreference: UpsertUserNotificationPreferencePayload;
  voidSalesVoucher: VoidSalesVoucherPayload;
};


export type MutationAddFeedbackMessageArgs = {
  input: AddFeedbackMessageInput;
};


export type MutationApprovePaymentArgs = {
  input: ApprovePaymentInput;
};


export type MutationCreateNotificationsArgs = {
  input: CreateNotificationsInput;
};


export type MutationDeleteContactArgs = {
  input: DeleteContactInput;
};


export type MutationDeleteContentArgs = {
  input: DeleteContentInput;
};


export type MutationDeletePredefinedReportFilterArgs = {
  input: DeletePredefinedReportFilterInput;
};


export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};


export type MutationDeleteProductConstraintArgs = {
  input: DeleteProductConstraintInput;
};


export type MutationReadUserNotificationArgs = {
  input: ReadUserNotificationInput;
};


export type MutationRemoveUserDeviceArgs = {
  input: RemoveUserDeviceInput;
};


export type MutationStatementRequestArgs = {
  input: StatementRequestInput;
};


export type MutationUnlinkUserAccountArgs = {
  input: UnlinkUserAccountInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserAccountArgs = {
  input: UpdateUserAccountInput;
};


export type MutationUpdateUserActiveArgs = {
  input: UpdateUserActiveInput;
};


export type MutationUpdateUserDefaultLanguageArgs = {
  input: UpdateUserDefaultLanguageInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpsertBankArgs = {
  input: UpsertBankInput;
};


export type MutationUpsertBankBranchArgs = {
  input: UpsertBankBranchInput;
};


export type MutationUpsertCompanyArgs = {
  input: UpsertCompanyInput;
};


export type MutationUpsertCompanyCurrencyArgs = {
  input: UpsertCompanyCurrencyInput;
};


export type MutationUpsertCompanySocialMediaArgs = {
  input: UpsertCompanySocialMediaInput;
};


export type MutationUpsertContactArgs = {
  input: UpsertContactInput;
};


export type MutationUpsertContentArgs = {
  input: UpsertContentInput;
};


export type MutationUpsertCurrencyArgs = {
  input: UpsertCurrencyInput;
};


export type MutationUpsertCustomerArgs = {
  input: UpsertCustomerInput;
};


export type MutationUpsertCustomerVehicleArgs = {
  input: UpsertCustomerVehicleInput;
};


export type MutationUpsertLocationArgs = {
  input: UpsertLocationInput;
};


export type MutationUpsertPaymentArgs = {
  input: UpsertPaymentInput;
};


export type MutationUpsertPaymentApprovalArgs = {
  input: UpsertPaymentApprovalInput;
};


export type MutationUpsertPaymentTranscriptionArgs = {
  input: UpsertPaymentTranscriptionInput;
};


export type MutationUpsertPredefinedReportFilterArgs = {
  input: UpsertPredefinedReportFilterInput;
};


export type MutationUpsertProductArgs = {
  input: UpsertProductInput;
};


export type MutationUpsertProductConstraintArgs = {
  input: UpsertProductConstraintInput;
};


export type MutationUpsertSmsConfigurationArgs = {
  input: UpsertSmsConfigurationInput;
};


export type MutationUpsertSalesVoucherArgs = {
  input: UpsertSalesVoucherInput;
};


export type MutationUpsertSettlementBankArgs = {
  input: UpsertSettlementBankInput;
};


export type MutationUpsertTermsAndConditionArgs = {
  input: UpsertTermsAndConditionInput;
};


export type MutationUpsertUserDeviceArgs = {
  input: UpsertUserDeviceInput;
};


export type MutationUpsertUserNotificationPreferenceArgs = {
  input: UpsertUserNotificationPreferenceInput;
};


export type MutationVoidSalesVoucherArgs = {
  input: VoidSalesVoucherInput;
};

export type NavigationState = {
  __typename?: 'NavigationState';
  apparentDataLoss: Scalars['Boolean']['output'];
  hasGoBackRequest: Scalars['Boolean']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  body: Scalars['String']['output'];
  componentId?: Maybe<Scalars['String']['output']>;
  componentName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  notificationType: NotificationType;
  notificationTypeId: Scalars['Int']['output'];
  notificationUsers: Array<NotificationUser>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NotificationFilterInput = {
  and?: InputMaybe<Array<NotificationFilterInput>>;
  body?: InputMaybe<StringOperationFilterInput>;
  componentId?: InputMaybe<StringOperationFilterInput>;
  componentName?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  notificationType?: InputMaybe<NotificationTypeFilterInput>;
  notificationTypeId?: InputMaybe<IntOperationFilterInput>;
  notificationUsers?: InputMaybe<ListFilterInputTypeOfNotificationUserFilterInput>;
  or?: InputMaybe<Array<NotificationFilterInput>>;
  title?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type NotificationPreferenceType = {
  __typename?: 'NotificationPreferenceType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
  users: Array<User>;
};

export type NotificationPreferenceTypeFilterInput = {
  and?: InputMaybe<Array<NotificationPreferenceTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NotificationPreferenceTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type NotificationSortInput = {
  body?: InputMaybe<SortEnumType>;
  componentId?: InputMaybe<SortEnumType>;
  componentName?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  notificationType?: InputMaybe<NotificationTypeSortInput>;
  notificationTypeId?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type NotificationStatus = {
  __typename?: 'NotificationStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type NotificationStatusFilterInput = {
  and?: InputMaybe<Array<NotificationStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NotificationStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type NotificationStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type NotificationTypeFilterInput = {
  and?: InputMaybe<Array<NotificationTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NotificationTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type NotificationTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type NotificationUser = {
  __typename?: 'NotificationUser';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  notification: Notification;
  notificationId: Scalars['Int']['output'];
  notificationStatus: NotificationStatus;
  notificationStatusId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export type NotificationUserFilterInput = {
  and?: InputMaybe<Array<NotificationUserFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  notification?: InputMaybe<NotificationFilterInput>;
  notificationId?: InputMaybe<IntOperationFilterInput>;
  notificationStatus?: InputMaybe<NotificationStatusFilterInput>;
  notificationStatusId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NotificationUserFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<IntOperationFilterInput>;
};

export type NotificationUserSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  notification?: InputMaybe<NotificationSortInput>;
  notificationId?: InputMaybe<SortEnumType>;
  notificationStatus?: InputMaybe<NotificationStatusSortInput>;
  notificationStatusId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  user?: InputMaybe<UserSortInput>;
  userId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type NotificationsConnection = {
  __typename?: 'NotificationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<NotificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Notification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NotificationsEdge = {
  __typename?: 'NotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Notification;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type OrderStatusFilterInput = {
  and?: InputMaybe<Array<OrderStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<OrderStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type OrderStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Decimal']['output'];
  amountApproved?: Maybe<Scalars['Decimal']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customReference?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  paymentApprovals: Array<PaymentApproval>;
  paymentDocuments: Array<PaymentDocument>;
  paymentMode: PaymentMode;
  paymentModeId: Scalars['Int']['output'];
  paymentReference: Scalars['String']['output'];
  paymentStatus: PaymentStatus;
  paymentStatusId: Scalars['Int']['output'];
  paymentTranscriptions: Array<PaymentTranscription>;
  reason?: Maybe<Reason>;
  reasonId?: Maybe<Scalars['Int']['output']>;
  rejectionComment?: Maybe<Scalars['String']['output']>;
  salesVoucher: SalesVoucher;
  salesVoucherId: Scalars['Int']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentApproval = {
  __typename?: 'PaymentApproval';
  amountApproved: Scalars['Decimal']['output'];
  approvalComment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  orderDate?: Maybe<Scalars['DateTime']['output']>;
  payment: Payment;
  paymentApprovalStatus: PaymentApprovalStatus;
  paymentApprovalStatusId: Scalars['Int']['output'];
  paymentApprovalType: PaymentApprovalType;
  paymentApprovalTypeId: Scalars['Int']['output'];
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentId: Scalars['Int']['output'];
  reason?: Maybe<Reason>;
  reasonId?: Maybe<Scalars['Int']['output']>;
  rejectionComment?: Maybe<Scalars['String']['output']>;
  settlementBank?: Maybe<SettlementBank>;
  settlementBankId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentApprovalFilterInput = {
  amountApproved?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<PaymentApprovalFilterInput>>;
  approvalComment?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  createdById?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentApprovalFilterInput>>;
  orderDate?: InputMaybe<DateTimeOperationFilterInput>;
  payment?: InputMaybe<PaymentFilterInput>;
  paymentApprovalStatus?: InputMaybe<PaymentApprovalStatusFilterInput>;
  paymentApprovalStatusId?: InputMaybe<IntOperationFilterInput>;
  paymentApprovalType?: InputMaybe<PaymentApprovalTypeFilterInput>;
  paymentApprovalTypeId?: InputMaybe<IntOperationFilterInput>;
  paymentDate?: InputMaybe<DateTimeOperationFilterInput>;
  paymentId?: InputMaybe<IntOperationFilterInput>;
  reason?: InputMaybe<ReasonFilterInput>;
  reasonId?: InputMaybe<IntOperationFilterInput>;
  rejectionComment?: InputMaybe<StringOperationFilterInput>;
  settlementBank?: InputMaybe<SettlementBankFilterInput>;
  settlementBankId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type PaymentApprovalSortInput = {
  amountApproved?: InputMaybe<SortEnumType>;
  approvalComment?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  orderDate?: InputMaybe<SortEnumType>;
  payment?: InputMaybe<PaymentSortInput>;
  paymentApprovalStatus?: InputMaybe<PaymentApprovalStatusSortInput>;
  paymentApprovalStatusId?: InputMaybe<SortEnumType>;
  paymentApprovalType?: InputMaybe<PaymentApprovalTypeSortInput>;
  paymentApprovalTypeId?: InputMaybe<SortEnumType>;
  paymentDate?: InputMaybe<SortEnumType>;
  paymentId?: InputMaybe<SortEnumType>;
  reason?: InputMaybe<ReasonSortInput>;
  reasonId?: InputMaybe<SortEnumType>;
  rejectionComment?: InputMaybe<SortEnumType>;
  settlementBank?: InputMaybe<SettlementBankSortInput>;
  settlementBankId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type PaymentApprovalStatus = {
  __typename?: 'PaymentApprovalStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type PaymentApprovalStatusFilterInput = {
  and?: InputMaybe<Array<PaymentApprovalStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentApprovalStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type PaymentApprovalStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type PaymentApprovalType = {
  __typename?: 'PaymentApprovalType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type PaymentApprovalTypeFilterInput = {
  and?: InputMaybe<Array<PaymentApprovalTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentApprovalTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type PaymentApprovalTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type PaymentApprovalsConnection = {
  __typename?: 'PaymentApprovalsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaymentApprovalsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PaymentApproval>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentApprovalsEdge = {
  __typename?: 'PaymentApprovalsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PaymentApproval;
};

export type PaymentDocument = {
  __typename?: 'PaymentDocument';
  createdAt: Scalars['DateTime']['output'];
  documentName?: Maybe<Scalars['String']['output']>;
  documentUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  payment: Payment;
  paymentId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentDocumentFilterInput = {
  and?: InputMaybe<Array<PaymentDocumentFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  documentName?: InputMaybe<StringOperationFilterInput>;
  documentUrl?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentDocumentFilterInput>>;
  payment?: InputMaybe<PaymentFilterInput>;
  paymentId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type PaymentFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  amountApproved?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<PaymentFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  customReference?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentFilterInput>>;
  paymentApprovals?: InputMaybe<ListFilterInputTypeOfPaymentApprovalFilterInput>;
  paymentDocuments?: InputMaybe<ListFilterInputTypeOfPaymentDocumentFilterInput>;
  paymentMode?: InputMaybe<PaymentModeFilterInput>;
  paymentModeId?: InputMaybe<IntOperationFilterInput>;
  paymentReference?: InputMaybe<StringOperationFilterInput>;
  paymentStatus?: InputMaybe<PaymentStatusFilterInput>;
  paymentStatusId?: InputMaybe<IntOperationFilterInput>;
  paymentTranscriptions?: InputMaybe<ListFilterInputTypeOfPaymentTranscriptionFilterInput>;
  reason?: InputMaybe<ReasonFilterInput>;
  reasonId?: InputMaybe<IntOperationFilterInput>;
  rejectionComment?: InputMaybe<StringOperationFilterInput>;
  salesVoucher?: InputMaybe<SalesVoucherFilterInput>;
  salesVoucherId?: InputMaybe<IntOperationFilterInput>;
  transactionId?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type PaymentMode = {
  __typename?: 'PaymentMode';
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  documentType: DocumentType;
  documentTypeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  paymentModeStatus: PaymentModeStatus;
  paymentModeStatusId: Scalars['Int']['output'];
  paymentModeType: PaymentModeType;
  paymentModeTypeId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentModeFilterInput = {
  and?: InputMaybe<Array<PaymentModeFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  documentType?: InputMaybe<DocumentTypeFilterInput>;
  documentTypeId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PaymentModeFilterInput>>;
  paymentModeStatus?: InputMaybe<PaymentModeStatusFilterInput>;
  paymentModeStatusId?: InputMaybe<IntOperationFilterInput>;
  paymentModeType?: InputMaybe<PaymentModeTypeFilterInput>;
  paymentModeTypeId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type PaymentModeSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  documentType?: InputMaybe<DocumentTypeSortInput>;
  documentTypeId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  paymentModeStatus?: InputMaybe<PaymentModeStatusSortInput>;
  paymentModeStatusId?: InputMaybe<SortEnumType>;
  paymentModeType?: InputMaybe<PaymentModeTypeSortInput>;
  paymentModeTypeId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type PaymentModeStatus = {
  __typename?: 'PaymentModeStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type PaymentModeStatusFilterInput = {
  and?: InputMaybe<Array<PaymentModeStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentModeStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type PaymentModeStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type PaymentModeType = {
  __typename?: 'PaymentModeType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type PaymentModeTypeFilterInput = {
  and?: InputMaybe<Array<PaymentModeTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentModeTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type PaymentModeTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type PaymentSortInput = {
  amount?: InputMaybe<SortEnumType>;
  amountApproved?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customReference?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  paymentMode?: InputMaybe<PaymentModeSortInput>;
  paymentModeId?: InputMaybe<SortEnumType>;
  paymentReference?: InputMaybe<SortEnumType>;
  paymentStatus?: InputMaybe<PaymentStatusSortInput>;
  paymentStatusId?: InputMaybe<SortEnumType>;
  reason?: InputMaybe<ReasonSortInput>;
  reasonId?: InputMaybe<SortEnumType>;
  rejectionComment?: InputMaybe<SortEnumType>;
  salesVoucher?: InputMaybe<SalesVoucherSortInput>;
  salesVoucherId?: InputMaybe<SortEnumType>;
  transactionId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type PaymentStatus = {
  __typename?: 'PaymentStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type PaymentStatusFilterInput = {
  and?: InputMaybe<Array<PaymentStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type PaymentStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type PaymentTranscription = {
  __typename?: 'PaymentTranscription';
  amount: Scalars['Decimal']['output'];
  bank?: Maybe<Bank>;
  bankBranch?: Maybe<BankBranch>;
  bankBranchId?: Maybe<Scalars['Int']['output']>;
  bankId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['Int']['output']>;
  depositorContact?: Maybe<Scalars['String']['output']>;
  depositorName?: Maybe<Scalars['String']['output']>;
  documentDate: Scalars['DateTime']['output'];
  documentReference?: Maybe<Scalars['String']['output']>;
  documentType: DocumentType;
  documentTypeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  payment: Payment;
  paymentId: Scalars['Int']['output'];
  paymentTranscriptionStatus: PaymentTranscriptionStatus;
  paymentTranscriptionStatusId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentTranscriptionFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<PaymentTranscriptionFilterInput>>;
  bank?: InputMaybe<BankFilterInput>;
  bankBranch?: InputMaybe<BankBranchFilterInput>;
  bankBranchId?: InputMaybe<IntOperationFilterInput>;
  bankId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  createdById?: InputMaybe<IntOperationFilterInput>;
  depositorContact?: InputMaybe<StringOperationFilterInput>;
  depositorName?: InputMaybe<StringOperationFilterInput>;
  documentDate?: InputMaybe<DateTimeOperationFilterInput>;
  documentReference?: InputMaybe<StringOperationFilterInput>;
  documentType?: InputMaybe<DocumentTypeFilterInput>;
  documentTypeId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  notes?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PaymentTranscriptionFilterInput>>;
  payment?: InputMaybe<PaymentFilterInput>;
  paymentId?: InputMaybe<IntOperationFilterInput>;
  paymentTranscriptionStatus?: InputMaybe<PaymentTranscriptionStatusFilterInput>;
  paymentTranscriptionStatusId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type PaymentTranscriptionSortInput = {
  amount?: InputMaybe<SortEnumType>;
  bank?: InputMaybe<BankSortInput>;
  bankBranch?: InputMaybe<BankBranchSortInput>;
  bankBranchId?: InputMaybe<SortEnumType>;
  bankId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  depositorContact?: InputMaybe<SortEnumType>;
  depositorName?: InputMaybe<SortEnumType>;
  documentDate?: InputMaybe<SortEnumType>;
  documentReference?: InputMaybe<SortEnumType>;
  documentType?: InputMaybe<DocumentTypeSortInput>;
  documentTypeId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  notes?: InputMaybe<SortEnumType>;
  payment?: InputMaybe<PaymentSortInput>;
  paymentId?: InputMaybe<SortEnumType>;
  paymentTranscriptionStatus?: InputMaybe<PaymentTranscriptionStatusSortInput>;
  paymentTranscriptionStatusId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type PaymentTranscriptionStatus = {
  __typename?: 'PaymentTranscriptionStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type PaymentTranscriptionStatusFilterInput = {
  and?: InputMaybe<Array<PaymentTranscriptionStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PaymentTranscriptionStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type PaymentTranscriptionStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type PaymentTranscriptionsConnection = {
  __typename?: 'PaymentTranscriptionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaymentTranscriptionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PaymentTranscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentTranscriptionsEdge = {
  __typename?: 'PaymentTranscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PaymentTranscription;
};

/** A connection to a list of items. */
export type PaymentsConnection = {
  __typename?: 'PaymentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaymentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Payment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentsEdge = {
  __typename?: 'PaymentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Payment;
};

export type PermissionState = {
  __typename?: 'PermissionState';
  permissions: Scalars['String']['output'];
};

export type PredefinedReportFilter = {
  __typename?: 'PredefinedReportFilter';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  location: Location;
  locationId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  reportType: ReportType;
  reportTypeId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PredefinedReportFilterFilterInput = {
  and?: InputMaybe<Array<PredefinedReportFilterFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  data?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PredefinedReportFilterFilterInput>>;
  reportType?: InputMaybe<ReportTypeFilterInput>;
  reportTypeId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type PredefinedReportFilterSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  reportType?: InputMaybe<ReportTypeSortInput>;
  reportTypeId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type Product = {
  __typename?: 'Product';
  base64Image?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  featuredImageUrl?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isPublished: Scalars['Boolean']['output'];
  itemCode: Scalars['String']['output'];
  lookup?: Maybe<Scalars['String']['output']>;
  productMeasurement: ProductMeasurement;
  productMeasurementId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  unitOfMeasure: UnitOfMeasure;
  unitOfMeasureId: Scalars['Int']['output'];
  uoS?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductConstraint = {
  __typename?: 'ProductConstraint';
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerSegment: CustomerSegment;
  customerSegmentId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  maxProductQuantity: Scalars['Long']['output'];
  maxTruckQuantity: Scalars['Long']['output'];
  minProductQuantity: Scalars['Long']['output'];
  minTruckQuantity: Scalars['Long']['output'];
  product: Product;
  productId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductConstraintFilterInput = {
  and?: InputMaybe<Array<ProductConstraintFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  customerSegment?: InputMaybe<CustomerSegmentFilterInput>;
  customerSegmentId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  maxProductQuantity?: InputMaybe<LongOperationFilterInput>;
  maxTruckQuantity?: InputMaybe<LongOperationFilterInput>;
  minProductQuantity?: InputMaybe<LongOperationFilterInput>;
  minTruckQuantity?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ProductConstraintFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ProductConstraintSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customerSegment?: InputMaybe<CustomerSegmentSortInput>;
  customerSegmentId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  maxProductQuantity?: InputMaybe<SortEnumType>;
  maxTruckQuantity?: InputMaybe<SortEnumType>;
  minProductQuantity?: InputMaybe<SortEnumType>;
  minTruckQuantity?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ProductConstraintsConnection = {
  __typename?: 'ProductConstraintsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductConstraintsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductConstraint>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductConstraintsEdge = {
  __typename?: 'ProductConstraintsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ProductConstraint;
};

export type ProductFilterInput = {
  and?: InputMaybe<Array<ProductFilterInput>>;
  base64Image?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  featuredImageUrl?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isPublished?: InputMaybe<BooleanOperationFilterInput>;
  itemCode?: InputMaybe<StringOperationFilterInput>;
  lookup?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductFilterInput>>;
  productMeasurement?: InputMaybe<ProductMeasurementFilterInput>;
  productMeasurementId?: InputMaybe<IntOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  unitOfMeasure?: InputMaybe<UnitOfMeasureFilterInput>;
  unitOfMeasureId?: InputMaybe<IntOperationFilterInput>;
  uoS?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ProductMeasurement = {
  __typename?: 'ProductMeasurement';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type ProductMeasurementFilterInput = {
  and?: InputMaybe<Array<ProductMeasurementFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProductMeasurementFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type ProductMeasurementSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type ProductSortInput = {
  base64Image?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  featuredImageUrl?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isPublished?: InputMaybe<SortEnumType>;
  itemCode?: InputMaybe<SortEnumType>;
  lookup?: InputMaybe<SortEnumType>;
  productMeasurement?: InputMaybe<ProductMeasurementSortInput>;
  productMeasurementId?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  unitOfMeasure?: InputMaybe<UnitOfMeasureSortInput>;
  unitOfMeasureId?: InputMaybe<SortEnumType>;
  uoS?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ProductsConnection = {
  __typename?: 'ProductsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductsEdge = {
  __typename?: 'ProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Product;
};

export type Query = {
  __typename?: 'Query';
  adminSummaryReport: Array<AdminSummaryReport>;
  availableBalance: Scalars['Float']['output'];
  bankBranches?: Maybe<BankBranchesConnection>;
  banks?: Maybe<BanksConnection>;
  companies?: Maybe<CompaniesConnection>;
  companyCurrency: Array<CompanyCurrency>;
  companySocials: Array<CompanySocialMedia>;
  contacts?: Maybe<ContactsConnection>;
  contents?: Maybe<ContentsConnection>;
  countries: Array<Country>;
  currency?: Maybe<CurrencyConnection>;
  customerAccountUsers?: Maybe<CustomerAccountUsersConnection>;
  customerById?: Maybe<Customer>;
  customerRoles: Array<Role>;
  customerSalesVoucherOrders?: Maybe<CustomerSalesVoucherOrdersConnection>;
  customerSegments?: Maybe<CustomerSegmentsConnection>;
  customers?: Maybe<CustomersConnection>;
  dashboard: DashboardReport;
  descriptionValues: Array<DescriptionValue>;
  destinations?: Maybe<DestinationsConnection>;
  documentStatus: Array<DocumentStatus>;
  documentTypes: Array<DocumentType>;
  drivers?: Maybe<DriversConnection>;
  feedbackMessages: Array<FeedbackMessage>;
  languages: Array<Language>;
  locations?: Maybe<LocationsConnection>;
  navigateState: NavigationState;
  notificationPreferenceTypes: Array<NotificationPreferenceType>;
  notificationTypes: Array<NotificationType>;
  notifications?: Maybe<NotificationsConnection>;
  paymentApprovalStatus: Array<PaymentApprovalStatus>;
  paymentApprovalType: Array<PaymentApprovalType>;
  paymentApprovals?: Maybe<PaymentApprovalsConnection>;
  paymentModes: Array<PaymentMode>;
  paymentTranscriptions?: Maybe<PaymentTranscriptionsConnection>;
  payments?: Maybe<PaymentsConnection>;
  permissionState: PermissionState;
  predefinedReportFilters: Array<PredefinedReportFilter>;
  productConstraints?: Maybe<ProductConstraintsConnection>;
  productMeasurements: Array<ProductMeasurement>;
  products?: Maybe<ProductsConnection>;
  reasonTypes: Array<ReasonType>;
  reasons: Array<Reason>;
  revenueGraph: Array<AdminDashboardGraph>;
  salesVoucherOrders?: Maybe<SalesVoucherOrdersConnection>;
  salesVouchers?: Maybe<SalesVouchersConnection>;
  salesVouchersByCompany?: Maybe<SalesVouchersByCompanyConnection>;
  settlementBanks?: Maybe<SettlementBanksConnection>;
  soldProducts: Array<VolumeBalanceReport>;
  suppliers?: Maybe<SuppliersConnection>;
  termsAndConditions: Array<TermsAndCondition>;
  testNotification: Scalars['Boolean']['output'];
  totalTrips: Scalars['Int']['output'];
  trackingInfo: TrackingResponse;
  unitsOfMeasure: Array<UnitOfMeasure>;
  userByEmail?: Maybe<User>;
  userById?: Maybe<User>;
  userNotifications?: Maybe<UserNotificationsConnection>;
  userPayments?: Maybe<UserPaymentsConnection>;
  userPermissions: Array<Scalars['String']['output']>;
  userProfile?: Maybe<User>;
  userRoless: Array<Scalars['String']['output']>;
  users?: Maybe<UsersConnection>;
  usersByCompany?: Maybe<UsersByCompanyConnection>;
  vehicles?: Maybe<VehiclesConnection>;
  volumeBalance: Array<VolumeBalanceReport>;
};


export type QueryAdminSummaryReportArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryBankBranchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BankBranchSortInput>>;
  where?: InputMaybe<BankBranchFilterInput>;
};


export type QueryBanksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BankSortInput>>;
  where?: InputMaybe<BankFilterInput>;
};


export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CompanySortInput>>;
  where?: InputMaybe<CompanyFilterInput>;
};


export type QueryCompanyCurrencyArgs = {
  order?: InputMaybe<Array<CompanyCurrencySortInput>>;
  where?: InputMaybe<CompanyCurrencyFilterInput>;
};


export type QueryCompanySocialsArgs = {
  companyId: Scalars['Int']['input'];
  order?: InputMaybe<Array<CompanySocialMediaSortInput>>;
  where?: InputMaybe<CompanySocialMediaFilterInput>;
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ContactSortInput>>;
  where?: InputMaybe<ContactFilterInput>;
};


export type QueryContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  contentType: Scalars['Int']['input'];
  countryId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ContentSortInput>>;
  where?: InputMaybe<ContentFilterInput>;
};


export type QueryCountriesArgs = {
  order?: InputMaybe<Array<CountrySortInput>>;
  where?: InputMaybe<CountryFilterInput>;
};


export type QueryCurrencyArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CurrencySortInput>>;
  where?: InputMaybe<CurrencyFilterInput>;
};


export type QueryCustomerAccountUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};


export type QueryCustomerByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCustomerRolesArgs = {
  order?: InputMaybe<Array<RoleSortInput>>;
  where?: InputMaybe<RoleFilterInput>;
};


export type QueryCustomerSalesVoucherOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SalesVoucherOrderSortInput>>;
  where?: InputMaybe<SalesVoucherOrderFilterInput>;
};


export type QueryCustomerSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CustomerSegmentSortInput>>;
  where?: InputMaybe<CustomerSegmentFilterInput>;
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<CustomerSortInput>>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type QueryDashboardArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  reportType: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryDescriptionValuesArgs = {
  order?: InputMaybe<Array<DescriptionValueSortInput>>;
  where?: InputMaybe<DescriptionValueFilterInput>;
};


export type QueryDestinationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<DestinationSortInput>>;
  where?: InputMaybe<DestinationFilterInput>;
};


export type QueryDocumentStatusArgs = {
  order?: InputMaybe<Array<DocumentStatusSortInput>>;
  where?: InputMaybe<DocumentStatusFilterInput>;
};


export type QueryDocumentTypesArgs = {
  order?: InputMaybe<Array<DocumentTypeSortInput>>;
  where?: InputMaybe<DocumentTypeFilterInput>;
};


export type QueryDriversArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<DriverSortInput>>;
  where?: InputMaybe<DriverFilterInput>;
};


export type QueryFeedbackMessagesArgs = {
  order?: InputMaybe<Array<FeedbackMessageSortInput>>;
  where?: InputMaybe<FeedbackMessageFilterInput>;
};


export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<LocationSortInput>>;
  where?: InputMaybe<LocationFilterInput>;
};


export type QueryNotificationTypesArgs = {
  order?: InputMaybe<Array<NotificationTypeSortInput>>;
  where?: InputMaybe<NotificationTypeFilterInput>;
};


export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<NotificationSortInput>>;
  where?: InputMaybe<NotificationFilterInput>;
};


export type QueryPaymentApprovalStatusArgs = {
  order?: InputMaybe<Array<PaymentApprovalStatusSortInput>>;
  where?: InputMaybe<PaymentApprovalStatusFilterInput>;
};


export type QueryPaymentApprovalTypeArgs = {
  order?: InputMaybe<Array<PaymentApprovalTypeSortInput>>;
  where?: InputMaybe<PaymentApprovalTypeFilterInput>;
};


export type QueryPaymentApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PaymentApprovalSortInput>>;
  where?: InputMaybe<PaymentApprovalFilterInput>;
};


export type QueryPaymentModesArgs = {
  order?: InputMaybe<Array<PaymentModeSortInput>>;
  where?: InputMaybe<PaymentModeFilterInput>;
};


export type QueryPaymentTranscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PaymentTranscriptionSortInput>>;
  where?: InputMaybe<PaymentTranscriptionFilterInput>;
};


export type QueryPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PaymentSortInput>>;
  where?: InputMaybe<PaymentFilterInput>;
};


export type QueryPredefinedReportFiltersArgs = {
  order?: InputMaybe<Array<PredefinedReportFilterSortInput>>;
  where?: InputMaybe<PredefinedReportFilterFilterInput>;
};


export type QueryProductConstraintsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ProductConstraintSortInput>>;
  where?: InputMaybe<ProductConstraintFilterInput>;
};


export type QueryProductMeasurementsArgs = {
  order?: InputMaybe<Array<ProductMeasurementSortInput>>;
  where?: InputMaybe<ProductMeasurementFilterInput>;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<ProductSortInput>>;
  where?: InputMaybe<ProductFilterInput>;
};


export type QueryReasonTypesArgs = {
  order?: InputMaybe<Array<ReasonTypeSortInput>>;
  where?: InputMaybe<ReasonTypeFilterInput>;
};


export type QueryReasonsArgs = {
  order?: InputMaybe<Array<ReasonSortInput>>;
  where?: InputMaybe<ReasonFilterInput>;
};


export type QueryRevenueGraphArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QuerySalesVoucherOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SalesVoucherOrderSortInput>>;
  where?: InputMaybe<SalesVoucherOrderFilterInput>;
};


export type QuerySalesVouchersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SalesVoucherSortInput>>;
  where?: InputMaybe<SalesVoucherFilterInput>;
};


export type QuerySalesVouchersByCompanyArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SalesVoucherSortInput>>;
  where?: InputMaybe<SalesVoucherFilterInput>;
};


export type QuerySettlementBanksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SettlementBankSortInput>>;
  where?: InputMaybe<SettlementBankFilterInput>;
};


export type QuerySoldProductsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QuerySuppliersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<SupplierSortInput>>;
  where?: InputMaybe<SupplierFilterInput>;
};


export type QueryTermsAndConditionsArgs = {
  order?: InputMaybe<Array<TermsAndConditionSortInput>>;
  where?: InputMaybe<TermsAndConditionFilterInput>;
};


export type QueryTestNotificationArgs = {
  data?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  deviceToken: Scalars['String']['input'];
  message: Scalars['String']['input'];
  title: Scalars['String']['input'];
  topic?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTrackingInfoArgs = {
  salesOrderNumber: Scalars['String']['input'];
};


export type QueryUnitsOfMeasureArgs = {
  order?: InputMaybe<Array<UnitOfMeasureSortInput>>;
  where?: InputMaybe<UnitOfMeasureFilterInput>;
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUserByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<NotificationUserSortInput>>;
  where?: InputMaybe<NotificationUserFilterInput>;
};


export type QueryUserPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<PaymentSortInput>>;
  where?: InputMaybe<PaymentFilterInput>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};


export type QueryUsersByCompanyArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};


export type QueryVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<VehicleSortInput>>;
  where?: InputMaybe<VehicleFilterInput>;
};

export type ReadAllUserNotificationPayload = {
  __typename?: 'ReadAllUserNotificationPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type ReadUserNotificationInput = {
  notificationId: Scalars['Int']['input'];
};

export type ReadUserNotificationPayload = {
  __typename?: 'ReadUserNotificationPayload';
  notificationUser?: Maybe<NotificationUser>;
};

export type Reason = {
  __typename?: 'Reason';
  createdAt: Scalars['DateTime']['output'];
  detail: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  location: Location;
  locationId: Scalars['Int']['output'];
  reasonType: ReasonType;
  reasonTypeId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ReasonFilterInput = {
  and?: InputMaybe<Array<ReasonFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  detail?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ReasonFilterInput>>;
  reasonType?: InputMaybe<ReasonTypeFilterInput>;
  reasonTypeId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type ReasonSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  detail?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  reasonType?: InputMaybe<ReasonTypeSortInput>;
  reasonTypeId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type ReasonType = {
  __typename?: 'ReasonType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type ReasonTypeFilterInput = {
  and?: InputMaybe<Array<ReasonTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ReasonTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type ReasonTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type RemoveUserDeviceInput = {
  token: Scalars['String']['input'];
};

export type RemoveUserDevicePayload = {
  __typename?: 'RemoveUserDevicePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type ReportType = {
  __typename?: 'ReportType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type ReportTypeFilterInput = {
  and?: InputMaybe<Array<ReportTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ReportTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type ReportTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isInternal: Scalars['Boolean']['output'];
  mhcIdentifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  translationKey: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  users: Array<User>;
};

export type RoleFilterInput = {
  and?: InputMaybe<Array<RoleFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isInternal?: InputMaybe<BooleanOperationFilterInput>;
  mhcIdentifier?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RoleFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type RoleSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isInternal?: InputMaybe<SortEnumType>;
  mhcIdentifier?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type SalesVoucher = {
  __typename?: 'SalesVoucher';
  comments?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['Int']['output'];
  customOrderNumber?: Maybe<Scalars['String']['output']>;
  customer: Customer;
  customerId: Scalars['Int']['output'];
  customerReference?: Maybe<Scalars['String']['output']>;
  deliveryContact?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Destination>;
  destinationId?: Maybe<Scalars['Int']['output']>;
  featuredImageUrl?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  localPurchaseOrder?: Maybe<Scalars['String']['output']>;
  orderQuote?: Maybe<Scalars['Decimal']['output']>;
  payments: Array<Payment>;
  salesVoucherHistories: Array<SalesVoucherHistory>;
  salesVoucherItems: Array<SalesVoucherItem>;
  salesVoucherOrders: Array<SalesVoucherOrder>;
  salesVoucherStatus: SalesVoucherStatus;
  salesVoucherStatusId: Scalars['Int']['output'];
  sourceLocation: Location;
  sourceLocationId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  voucherNumber: Scalars['String']['output'];
};

export type SalesVoucherFilterInput = {
  and?: InputMaybe<Array<SalesVoucherFilterInput>>;
  comments?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<UserFilterInput>;
  createdById?: InputMaybe<IntOperationFilterInput>;
  customOrderNumber?: InputMaybe<StringOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<IntOperationFilterInput>;
  customerReference?: InputMaybe<StringOperationFilterInput>;
  deliveryContact?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  destination?: InputMaybe<DestinationFilterInput>;
  destinationId?: InputMaybe<IntOperationFilterInput>;
  featuredImageUrl?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  localPurchaseOrder?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SalesVoucherFilterInput>>;
  orderQuote?: InputMaybe<DecimalOperationFilterInput>;
  payments?: InputMaybe<ListFilterInputTypeOfPaymentFilterInput>;
  salesVoucherHistories?: InputMaybe<ListFilterInputTypeOfSalesVoucherHistoryFilterInput>;
  salesVoucherItems?: InputMaybe<ListFilterInputTypeOfSalesVoucherItemFilterInput>;
  salesVoucherOrders?: InputMaybe<ListFilterInputTypeOfSalesVoucherOrderFilterInput>;
  salesVoucherStatus?: InputMaybe<SalesVoucherStatusFilterInput>;
  salesVoucherStatusId?: InputMaybe<IntOperationFilterInput>;
  sourceLocation?: InputMaybe<LocationFilterInput>;
  sourceLocationId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  voucherNumber?: InputMaybe<StringOperationFilterInput>;
};

export type SalesVoucherHistory = {
  __typename?: 'SalesVoucherHistory';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  salesVoucherId: Scalars['Int']['output'];
  salesVoucherStatus: SalesVoucherStatus;
  salesVoucherStatusId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export type SalesVoucherHistoryFilterInput = {
  and?: InputMaybe<Array<SalesVoucherHistoryFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SalesVoucherHistoryFilterInput>>;
  salesVoucherId?: InputMaybe<IntOperationFilterInput>;
  salesVoucherStatus?: InputMaybe<SalesVoucherStatusFilterInput>;
  salesVoucherStatusId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<IntOperationFilterInput>;
};

export type SalesVoucherItem = {
  __typename?: 'SalesVoucherItem';
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  customerRef?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Destination>;
  destinationId?: Maybe<Scalars['Int']['output']>;
  discount?: Maybe<Scalars['Decimal']['output']>;
  driver?: Maybe<Driver>;
  driverId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDeliveryInclusive: Scalars['Boolean']['output'];
  product: Product;
  productId: Scalars['Int']['output'];
  quantity: Scalars['Decimal']['output'];
  quotationId?: Maybe<Scalars['String']['output']>;
  salesVoucher: SalesVoucher;
  salesVoucherId: Scalars['Int']['output'];
  salesVoucherOrders: Array<SalesVoucherOrder>;
  salesVoucherStatus: SalesVoucherStatus;
  salesVoucherStatusId: Scalars['Int']['output'];
  tax?: Maybe<Scalars['Decimal']['output']>;
  totalPrice?: Maybe<Scalars['Decimal']['output']>;
  trucksCount: Scalars['Int']['output'];
  unitPrice?: Maybe<Scalars['Decimal']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vehicle?: Maybe<Vehicle>;
  vehicleId?: Maybe<Scalars['Int']['output']>;
};

export type SalesVoucherItemFilterInput = {
  and?: InputMaybe<Array<SalesVoucherItemFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  currency?: InputMaybe<StringOperationFilterInput>;
  customerRef?: InputMaybe<StringOperationFilterInput>;
  destination?: InputMaybe<DestinationFilterInput>;
  destinationId?: InputMaybe<IntOperationFilterInput>;
  discount?: InputMaybe<DecimalOperationFilterInput>;
  driver?: InputMaybe<DriverFilterInput>;
  driverId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeliveryInclusive?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<SalesVoucherItemFilterInput>>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  quotationId?: InputMaybe<StringOperationFilterInput>;
  salesVoucher?: InputMaybe<SalesVoucherFilterInput>;
  salesVoucherId?: InputMaybe<IntOperationFilterInput>;
  salesVoucherOrders?: InputMaybe<ListFilterInputTypeOfSalesVoucherOrderFilterInput>;
  salesVoucherStatus?: InputMaybe<SalesVoucherStatusFilterInput>;
  salesVoucherStatusId?: InputMaybe<IntOperationFilterInput>;
  tax?: InputMaybe<DecimalOperationFilterInput>;
  totalPrice?: InputMaybe<DecimalOperationFilterInput>;
  trucksCount?: InputMaybe<IntOperationFilterInput>;
  unitPrice?: InputMaybe<DecimalOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  vehicle?: InputMaybe<VehicleFilterInput>;
  vehicleId?: InputMaybe<IntOperationFilterInput>;
};

export type SalesVoucherItemInput = {
  destinationId?: InputMaybe<Scalars['Int']['input']>;
  driverId?: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['Int']['input'];
  quantity: Scalars['Decimal']['input'];
  trucksCount: Scalars['Int']['input'];
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type SalesVoucherItemSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  currency?: InputMaybe<SortEnumType>;
  customerRef?: InputMaybe<SortEnumType>;
  destination?: InputMaybe<DestinationSortInput>;
  destinationId?: InputMaybe<SortEnumType>;
  discount?: InputMaybe<SortEnumType>;
  driver?: InputMaybe<DriverSortInput>;
  driverId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeliveryInclusive?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  quotationId?: InputMaybe<SortEnumType>;
  salesVoucher?: InputMaybe<SalesVoucherSortInput>;
  salesVoucherId?: InputMaybe<SortEnumType>;
  salesVoucherStatus?: InputMaybe<SalesVoucherStatusSortInput>;
  salesVoucherStatusId?: InputMaybe<SortEnumType>;
  tax?: InputMaybe<SortEnumType>;
  totalPrice?: InputMaybe<SortEnumType>;
  trucksCount?: InputMaybe<SortEnumType>;
  unitPrice?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  vehicle?: InputMaybe<VehicleSortInput>;
  vehicleId?: InputMaybe<SortEnumType>;
};

export type SalesVoucherOrder = {
  __typename?: 'SalesVoucherOrder';
  businessUnitLine?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyId: Scalars['Int']['output'];
  costCentre?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['Int']['output'];
  customerRef?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Destination>;
  destinationId?: Maybe<Scalars['Int']['output']>;
  driverLicenseNo?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  location: Location;
  locationId: Scalars['Int']['output'];
  orderStatus: OrderStatus;
  orderStatusId: Scalars['Int']['output'];
  paymentRef?: Maybe<Scalars['String']['output']>;
  product: Product;
  productId: Scalars['Int']['output'];
  quantity: Scalars['Decimal']['output'];
  quotationId?: Maybe<Scalars['String']['output']>;
  salesInvoiceNumber?: Maybe<Scalars['String']['output']>;
  salesOrderNumber: Scalars['String']['output'];
  salesType?: Maybe<Scalars['String']['output']>;
  salesVoucher?: Maybe<SalesVoucher>;
  salesVoucherId?: Maybe<Scalars['Int']['output']>;
  salesVoucherItem?: Maybe<SalesVoucherItem>;
  salesVoucherItemId?: Maybe<Scalars['Int']['output']>;
  salesVoucherOrderStatus: SalesVoucherOrderStatus;
  salesVoucherOrderStatusId: Scalars['Int']['output'];
  transporterCode?: Maybe<Scalars['String']['output']>;
  uoS?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vehicleNumber?: Maybe<Scalars['String']['output']>;
};

export type SalesVoucherOrderFilterInput = {
  and?: InputMaybe<Array<SalesVoucherOrderFilterInput>>;
  businessUnitLine?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  costCentre?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<IntOperationFilterInput>;
  customerRef?: InputMaybe<StringOperationFilterInput>;
  deliveryType?: InputMaybe<StringOperationFilterInput>;
  destination?: InputMaybe<DestinationFilterInput>;
  destinationId?: InputMaybe<IntOperationFilterInput>;
  driverLicenseNo?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SalesVoucherOrderFilterInput>>;
  orderStatus?: InputMaybe<OrderStatusFilterInput>;
  orderStatusId?: InputMaybe<IntOperationFilterInput>;
  paymentRef?: InputMaybe<StringOperationFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<IntOperationFilterInput>;
  quantity?: InputMaybe<DecimalOperationFilterInput>;
  quotationId?: InputMaybe<StringOperationFilterInput>;
  salesInvoiceNumber?: InputMaybe<StringOperationFilterInput>;
  salesOrderNumber?: InputMaybe<StringOperationFilterInput>;
  salesType?: InputMaybe<StringOperationFilterInput>;
  salesVoucher?: InputMaybe<SalesVoucherFilterInput>;
  salesVoucherId?: InputMaybe<IntOperationFilterInput>;
  salesVoucherItem?: InputMaybe<SalesVoucherItemFilterInput>;
  salesVoucherItemId?: InputMaybe<IntOperationFilterInput>;
  salesVoucherOrderStatus?: InputMaybe<SalesVoucherOrderStatusFilterInput>;
  salesVoucherOrderStatusId?: InputMaybe<IntOperationFilterInput>;
  transporterCode?: InputMaybe<StringOperationFilterInput>;
  uoS?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  vehicleNumber?: InputMaybe<StringOperationFilterInput>;
};

export type SalesVoucherOrderSortInput = {
  businessUnitLine?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  costCentre?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  customerId?: InputMaybe<SortEnumType>;
  customerRef?: InputMaybe<SortEnumType>;
  deliveryType?: InputMaybe<SortEnumType>;
  destination?: InputMaybe<DestinationSortInput>;
  destinationId?: InputMaybe<SortEnumType>;
  driverLicenseNo?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  orderStatus?: InputMaybe<OrderStatusSortInput>;
  orderStatusId?: InputMaybe<SortEnumType>;
  paymentRef?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  quantity?: InputMaybe<SortEnumType>;
  quotationId?: InputMaybe<SortEnumType>;
  salesInvoiceNumber?: InputMaybe<SortEnumType>;
  salesOrderNumber?: InputMaybe<SortEnumType>;
  salesType?: InputMaybe<SortEnumType>;
  salesVoucher?: InputMaybe<SalesVoucherSortInput>;
  salesVoucherId?: InputMaybe<SortEnumType>;
  salesVoucherItem?: InputMaybe<SalesVoucherItemSortInput>;
  salesVoucherItemId?: InputMaybe<SortEnumType>;
  salesVoucherOrderStatus?: InputMaybe<SalesVoucherOrderStatusSortInput>;
  salesVoucherOrderStatusId?: InputMaybe<SortEnumType>;
  transporterCode?: InputMaybe<SortEnumType>;
  uoS?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  vehicleNumber?: InputMaybe<SortEnumType>;
};

export type SalesVoucherOrderStatus = {
  __typename?: 'SalesVoucherOrderStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type SalesVoucherOrderStatusFilterInput = {
  and?: InputMaybe<Array<SalesVoucherOrderStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SalesVoucherOrderStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type SalesVoucherOrderStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type SalesVoucherOrdersConnection = {
  __typename?: 'SalesVoucherOrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SalesVoucherOrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SalesVoucherOrder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesVoucherOrdersEdge = {
  __typename?: 'SalesVoucherOrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SalesVoucherOrder;
};

export type SalesVoucherSortInput = {
  comments?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<UserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  customOrderNumber?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  customerId?: InputMaybe<SortEnumType>;
  customerReference?: InputMaybe<SortEnumType>;
  deliveryContact?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  destination?: InputMaybe<DestinationSortInput>;
  destinationId?: InputMaybe<SortEnumType>;
  featuredImageUrl?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  localPurchaseOrder?: InputMaybe<SortEnumType>;
  orderQuote?: InputMaybe<SortEnumType>;
  salesVoucherStatus?: InputMaybe<SalesVoucherStatusSortInput>;
  salesVoucherStatusId?: InputMaybe<SortEnumType>;
  sourceLocation?: InputMaybe<LocationSortInput>;
  sourceLocationId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  voucherNumber?: InputMaybe<SortEnumType>;
};

export type SalesVoucherStatus = {
  __typename?: 'SalesVoucherStatus';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type SalesVoucherStatusFilterInput = {
  and?: InputMaybe<Array<SalesVoucherStatusFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SalesVoucherStatusFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type SalesVoucherStatusSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type SalesVouchersByCompanyConnection = {
  __typename?: 'SalesVouchersByCompanyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SalesVouchersByCompanyEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SalesVoucher>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesVouchersByCompanyEdge = {
  __typename?: 'SalesVouchersByCompanyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SalesVoucher;
};

/** A connection to a list of items. */
export type SalesVouchersConnection = {
  __typename?: 'SalesVouchersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SalesVouchersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SalesVoucher>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SalesVouchersEdge = {
  __typename?: 'SalesVouchersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SalesVoucher;
};

export type SettlementBank = {
  __typename?: 'SettlementBank';
  code: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isIncoming: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sunAccountCode: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SettlementBankFilterInput = {
  and?: InputMaybe<Array<SettlementBankFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isIncoming?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SettlementBankFilterInput>>;
  sunAccountCode?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type SettlementBankSortInput = {
  code?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isIncoming?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sunAccountCode?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type SettlementBanksConnection = {
  __typename?: 'SettlementBanksConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SettlementBanksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SettlementBank>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SettlementBanksEdge = {
  __typename?: 'SettlementBanksEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SettlementBank;
};

export type ShortOperationFilterInput = {
  eq?: InputMaybe<Scalars['Short']['input']>;
  gt?: InputMaybe<Scalars['Short']['input']>;
  gte?: InputMaybe<Scalars['Short']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Short']['input']>>>;
  lt?: InputMaybe<Scalars['Short']['input']>;
  lte?: InputMaybe<Scalars['Short']['input']>;
  neq?: InputMaybe<Scalars['Short']['input']>;
  ngt?: InputMaybe<Scalars['Short']['input']>;
  ngte?: InputMaybe<Scalars['Short']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Short']['input']>>>;
  nlt?: InputMaybe<Scalars['Short']['input']>;
  nlte?: InputMaybe<Scalars['Short']['input']>;
};

export type SocialMediaType = {
  __typename?: 'SocialMediaType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type SocialMediaTypeFilterInput = {
  and?: InputMaybe<Array<SocialMediaTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SocialMediaTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type SocialMediaTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StatementRequestInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type StatementRequestPayload = {
  __typename?: 'StatementRequestPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  active: Scalars['Boolean']['output'];
  altPhoneNumber?: Maybe<Scalars['Long']['output']>;
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  phoneNumber?: Maybe<Scalars['Long']['output']>;
  supplierCode: Scalars['String']['output'];
  supplierName: Scalars['String']['output'];
  transporter: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vehicles: Array<Vehicle>;
};

export type SupplierFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  altPhoneNumber?: InputMaybe<LongOperationFilterInput>;
  and?: InputMaybe<Array<SupplierFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SupplierFilterInput>>;
  phoneNumber?: InputMaybe<LongOperationFilterInput>;
  supplierCode?: InputMaybe<StringOperationFilterInput>;
  supplierName?: InputMaybe<StringOperationFilterInput>;
  transporter?: InputMaybe<BooleanOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  vehicles?: InputMaybe<ListFilterInputTypeOfVehicleFilterInput>;
};

export type SupplierSortInput = {
  active?: InputMaybe<SortEnumType>;
  altPhoneNumber?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  supplierCode?: InputMaybe<SortEnumType>;
  supplierName?: InputMaybe<SortEnumType>;
  transporter?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type SuppliersConnection = {
  __typename?: 'SuppliersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SuppliersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Supplier>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SuppliersEdge = {
  __typename?: 'SuppliersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Supplier;
};

export type TermsAndCondition = {
  __typename?: 'TermsAndCondition';
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  termsContent: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  versionId: Scalars['String']['output'];
};

export type TermsAndConditionFilterInput = {
  and?: InputMaybe<Array<TermsAndConditionFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TermsAndConditionFilterInput>>;
  termsContent?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  versionId?: InputMaybe<StringOperationFilterInput>;
};

export type TermsAndConditionSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  termsContent?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  versionId?: InputMaybe<SortEnumType>;
};

export type TrackingResponse = {
  __typename?: 'TrackingResponse';
  trackingProvider: Scalars['String']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type UnitOfMeasure = {
  __typename?: 'UnitOfMeasure';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type UnitOfMeasureFilterInput = {
  and?: InputMaybe<Array<UnitOfMeasureFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<UnitOfMeasureFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type UnitOfMeasureSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

export type UnlinkUserAccountInput = {
  customerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type UnlinkUserAccountPayload = {
  __typename?: 'UnlinkUserAccountPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateUserAccountInput = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
  roleId?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};

export type UpdateUserAccountPayload = {
  __typename?: 'UpdateUserAccountPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateUserActiveInput = {
  active: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
};

export type UpdateUserActivePayload = {
  __typename?: 'UpdateUserActivePayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateUserDefaultLanguageInput = {
  languageId: Scalars['Int']['input'];
};

export type UpdateUserDefaultLanguagePayload = {
  __typename?: 'UpdateUserDefaultLanguagePayload';
  user?: Maybe<User>;
};

export type UpdateUserInput = {
  companyId: Scalars['Int']['input'];
  customerId?: InputMaybe<Scalars['Int']['input']>;
  fullName: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  isActive: Scalars['Boolean']['input'];
  languageId: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<User>;
};

export type UpdateUserProfileInput = {
  languageId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  user?: Maybe<User>;
};

export type UpdateUserTermsAndConditionPayload = {
  __typename?: 'UpdateUserTermsAndConditionPayload';
  user?: Maybe<User>;
};

export type UpsertBankBranchInput = {
  bankId: Scalars['Int']['input'];
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type UpsertBankBranchPayload = {
  __typename?: 'UpsertBankBranchPayload';
  bankBranch?: Maybe<BankBranch>;
};

export type UpsertBankInput = {
  code: Scalars['String']['input'];
  countryId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type UpsertBankPayload = {
  __typename?: 'UpsertBankPayload';
  bank?: Maybe<Bank>;
};

export type UpsertCompanyCurrencyInput = {
  companyId: Scalars['Int']['input'];
  currencies: Array<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UpsertCompanyCurrencyPayload = {
  __typename?: 'UpsertCompanyCurrencyPayload';
  company?: Maybe<Company>;
};

export type UpsertCompanyInput = {
  code: Scalars['String']['input'];
  countryId: Scalars['Int']['input'];
  defaultCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  defaultCurrencySymbol?: InputMaybe<Scalars['String']['input']>;
  eamOrganization: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sunBusinessUnit: Scalars['String']['input'];
};

export type UpsertCompanyPayload = {
  __typename?: 'UpsertCompanyPayload';
  company?: Maybe<Company>;
};

export type UpsertCompanySocialMediaInput = {
  companyId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  socialMediaLink: Scalars['String']['input'];
  socialMediaTypeId: Scalars['Int']['input'];
};

export type UpsertCompanySocialMediaPayload = {
  __typename?: 'UpsertCompanySocialMediaPayload';
  companySocialMedia?: Maybe<CompanySocialMedia>;
};

export type UpsertContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['Int']['input'];
  physicalAddress: Scalars['String']['input'];
  postalAddress?: InputMaybe<Scalars['String']['input']>;
  telephone: Scalars['String']['input'];
};

export type UpsertContactPayload = {
  __typename?: 'UpsertContactPayload';
  contact?: Maybe<Contact>;
};

export type UpsertContentInput = {
  contentType: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  featuredImage?: InputMaybe<Scalars['Upload']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isPublished: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type UpsertContentPayload = {
  __typename?: 'UpsertContentPayload';
  content?: Maybe<Content>;
};

export type UpsertCurrencyInput = {
  currencyCode: Scalars['String']['input'];
  currencyName: Scalars['String']['input'];
  currencySymbol: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UpsertCurrencyPayload = {
  __typename?: 'UpsertCurrencyPayload';
  currency?: Maybe<Currency>;
};

export type UpsertCustomerInput = {
  active: Scalars['Boolean']['input'];
  altPhoneNumber?: InputMaybe<Scalars['Long']['input']>;
  code: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['Long']['input']>;
};

export type UpsertCustomerPayload = {
  __typename?: 'UpsertCustomerPayload';
  customer?: Maybe<Customer>;
};

export type UpsertCustomerVehicleInput = {
  customerId: Scalars['Int']['input'];
  vehicles?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpsertCustomerVehiclePayload = {
  __typename?: 'UpsertCustomerVehiclePayload';
  customer?: Maybe<Customer>;
};

export type UpsertLocationInput = {
  allowDeliveryTypeSelection?: InputMaybe<Scalars['Boolean']['input']>;
  allowDriverSelection?: InputMaybe<Scalars['Boolean']['input']>;
  allowTruckSelection?: InputMaybe<Scalars['Boolean']['input']>;
  autoConfirmNewSOs?: InputMaybe<Scalars['Boolean']['input']>;
  businessUnitLine?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  costCentre?: InputMaybe<Scalars['String']['input']>;
  defaultDeliveryType?: InputMaybe<Scalars['String']['input']>;
  defaultTransporter?: InputMaybe<Scalars['String']['input']>;
  descriptionValueId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  parentLocationId?: InputMaybe<Scalars['Int']['input']>;
  pmEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  quotationRentionDuration?: InputMaybe<Scalars['Int']['input']>;
  truckSelectionAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  type: Scalars['String']['input'];
  vatCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertLocationPayload = {
  __typename?: 'UpsertLocationPayload';
  location?: Maybe<Location>;
};

export type UpsertPaymentApprovalInput = {
  amountApproved: Scalars['Decimal']['input'];
  approvalComment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  orderDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentApprovalStatusId: Scalars['Int']['input'];
  paymentApprovalTypeId: Scalars['Int']['input'];
  paymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentId: Scalars['Int']['input'];
  reasonId?: InputMaybe<Scalars['Int']['input']>;
  rejectionComment?: InputMaybe<Scalars['String']['input']>;
  settlementBankId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpsertPaymentApprovalPayload = {
  __typename?: 'UpsertPaymentApprovalPayload';
  paymentApproval?: Maybe<PaymentApproval>;
};

export type UpsertPaymentInput = {
  amount: Scalars['Decimal']['input'];
  customReference?: InputMaybe<Scalars['String']['input']>;
  customerTranscriptionItemInput?: InputMaybe<CustomerTranscriptionItemInput>;
  documentStatusId?: InputMaybe<Scalars['Int']['input']>;
  documents?: InputMaybe<Array<Scalars['Upload']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  paymentModeId: Scalars['Int']['input'];
  paymentStatusId?: InputMaybe<Scalars['Int']['input']>;
  salesVoucherId: Scalars['Int']['input'];
};

export type UpsertPaymentPayload = {
  __typename?: 'UpsertPaymentPayload';
  payment?: Maybe<Payment>;
};

export type UpsertPaymentTranscriptionInput = {
  amount: Scalars['Decimal']['input'];
  bankBranchId: Scalars['Int']['input'];
  bankId: Scalars['Int']['input'];
  depositorContact?: InputMaybe<Scalars['String']['input']>;
  depositorName?: InputMaybe<Scalars['String']['input']>;
  documentDate: Scalars['DateTime']['input'];
  documentReference?: InputMaybe<Scalars['String']['input']>;
  documentTypeId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentId: Scalars['Int']['input'];
  paymentTranscriptionStatusId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpsertPaymentTranscriptionPayload = {
  __typename?: 'UpsertPaymentTranscriptionPayload';
  paymentTranscription?: Maybe<PaymentTranscription>;
};

export type UpsertPredefinedReportFilterInput = {
  data: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  reportTypeId: Scalars['Int']['input'];
};

export type UpsertPredefinedReportFilterPayload = {
  __typename?: 'UpsertPredefinedReportFilterPayload';
  predefinedReportFilter?: Maybe<PredefinedReportFilter>;
};

export type UpsertProductConstraintInput = {
  companyId: Scalars['Int']['input'];
  customerSegmentId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  maxProductQuantity: Scalars['Long']['input'];
  maxTruckQuantity: Scalars['Long']['input'];
  minProductQuantity: Scalars['Long']['input'];
  minTruckQuantity: Scalars['Long']['input'];
  productId: Scalars['Int']['input'];
};

export type UpsertProductConstraintPayload = {
  __typename?: 'UpsertProductConstraintPayload';
  productConstraint?: Maybe<ProductConstraint>;
};

export type UpsertProductInput = {
  companyId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  featuredImage?: InputMaybe<Scalars['Upload']['input']>;
  featuredImageUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isPublished: Scalars['Boolean']['input'];
  productMeasurementId: Scalars['Int']['input'];
  sunItemCode: Scalars['String']['input'];
  title: Scalars['String']['input'];
  unitOfMeasureId: Scalars['Int']['input'];
  uos?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertProductPayload = {
  __typename?: 'UpsertProductPayload';
  product?: Maybe<Product>;
};

export type UpsertSmsConfigurationInput = {
  companyId: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  login: Scalars['String']['input'];
  sender: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type UpsertSmsConfigurationPayload = {
  __typename?: 'UpsertSMSConfigurationPayload';
  companySMS?: Maybe<CompanySms>;
};

export type UpsertSalesVoucherInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  customerReference?: InputMaybe<Scalars['String']['input']>;
  deliveryContact?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationId?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['Upload']['input']>;
  featuredImageUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDeliveryInclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localPurchaseOrder?: InputMaybe<Scalars['String']['input']>;
  salesVoucherItemInputs: Array<SalesVoucherItemInput>;
  sourceLocationId: Scalars['Int']['input'];
};

export type UpsertSalesVoucherPayload = {
  __typename?: 'UpsertSalesVoucherPayload';
  salesVoucher?: Maybe<SalesVoucher>;
};

export type UpsertSettlementBankInput = {
  code: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive: Scalars['Boolean']['input'];
  isIncoming: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  sunAccountCode: Scalars['String']['input'];
};

export type UpsertSettlementBankPayload = {
  __typename?: 'UpsertSettlementBankPayload';
  settlementBank?: Maybe<SettlementBank>;
};

export type UpsertTermsAndConditionInput = {
  companyId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
};

export type UpsertTermsAndConditionPayload = {
  __typename?: 'UpsertTermsAndConditionPayload';
  termsAndCondition?: Maybe<TermsAndCondition>;
};

export type UpsertUserDeviceInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  token: Scalars['String']['input'];
  userDeviceId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertUserDevicePayload = {
  __typename?: 'UpsertUserDevicePayload';
  userDevice?: Maybe<UserDevice>;
};

export type UpsertUserNotificationPreferenceInput = {
  notificationPreferenceTypes: Array<Scalars['Int']['input']>;
};

export type UpsertUserNotificationPreferencePayload = {
  __typename?: 'UpsertUserNotificationPreferencePayload';
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  companies: Array<Company>;
  company: Company;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  language: Language;
  languageId: Scalars['Int']['output'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['Int']['output']>;
  locations: Array<Location>;
  notificationPreferenceTypes: Array<NotificationPreferenceType>;
  notificationUsers: Array<NotificationUser>;
  permissions: Array<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['Long']['output']>;
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['Int']['output']>;
  roles: Array<Role>;
  termsAndCondition?: Maybe<TermsAndCondition>;
  termsAndConditionId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userDevices: Array<UserDevice>;
  userName: Scalars['String']['output'];
};

export type UserDevice = {
  __typename?: 'UserDevice';
  createdAt: Scalars['DateTime']['output'];
  deviceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export type UserDeviceFilterInput = {
  and?: InputMaybe<Array<UserDeviceFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deviceId?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<UserDeviceFilterInput>>;
  token?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<IntOperationFilterInput>;
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  companies?: InputMaybe<ListFilterInputTypeOfCompanyFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<IntOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  language?: InputMaybe<LanguageFilterInput>;
  languageId?: InputMaybe<IntOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<IntOperationFilterInput>;
  locations?: InputMaybe<ListFilterInputTypeOfLocationFilterInput>;
  notificationPreferenceTypes?: InputMaybe<ListFilterInputTypeOfNotificationPreferenceTypeFilterInput>;
  notificationUsers?: InputMaybe<ListFilterInputTypeOfNotificationUserFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  permissions?: InputMaybe<ListStringOperationFilterInput>;
  phoneNumber?: InputMaybe<LongOperationFilterInput>;
  role?: InputMaybe<RoleFilterInput>;
  roleId?: InputMaybe<IntOperationFilterInput>;
  roles?: InputMaybe<ListFilterInputTypeOfRoleFilterInput>;
  termsAndCondition?: InputMaybe<TermsAndConditionFilterInput>;
  termsAndConditionId?: InputMaybe<IntOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  userDevices?: InputMaybe<ListFilterInputTypeOfUserDeviceFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type UserNotificationsConnection = {
  __typename?: 'UserNotificationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UserNotificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<NotificationUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserNotificationsEdge = {
  __typename?: 'UserNotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: NotificationUser;
};

/** A connection to a list of items. */
export type UserPaymentsConnection = {
  __typename?: 'UserPaymentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UserPaymentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Payment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserPaymentsEdge = {
  __typename?: 'UserPaymentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Payment;
};

export type UserSortInput = {
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  customerId?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  language?: InputMaybe<LanguageSortInput>;
  languageId?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  role?: InputMaybe<RoleSortInput>;
  roleId?: InputMaybe<SortEnumType>;
  termsAndCondition?: InputMaybe<TermsAndConditionSortInput>;
  termsAndConditionId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type UsersByCompanyConnection = {
  __typename?: 'UsersByCompanyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersByCompanyEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UsersByCompanyEdge = {
  __typename?: 'UsersByCompanyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: User;
};

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: User;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  capacity?: Maybe<Scalars['Decimal']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  country: Country;
  countryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customers: Array<Customer>;
  dispatch: Scalars['Boolean']['output'];
  eamId?: Maybe<Scalars['String']['output']>;
  forHire?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  insuranceCompany?: Maybe<Scalars['String']['output']>;
  insuranceExpiryDate?: Maybe<Scalars['Date']['output']>;
  insurancePolicyNo?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isBanned: Scalars['Boolean']['output'];
  maxWeight?: Maybe<Scalars['Decimal']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  receiving: Scalars['Boolean']['output'];
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['Int']['output']>;
  suppliers: Array<Supplier>;
  tare?: Maybe<Scalars['Decimal']['output']>;
  trackingPhoneNo?: Maybe<Scalars['String']['output']>;
  trackingRefNo?: Maybe<Scalars['String']['output']>;
  transporterCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vehicleNo: Scalars['String']['output'];
  vehicleType: VehicleType;
  vehicleTypeId: Scalars['Int']['output'];
};

export type VehicleFilterInput = {
  and?: InputMaybe<Array<VehicleFilterInput>>;
  capacity?: InputMaybe<DecimalOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<CountryFilterInput>;
  countryId?: InputMaybe<IntOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  customers?: InputMaybe<ListFilterInputTypeOfCustomerFilterInput>;
  dispatch?: InputMaybe<BooleanOperationFilterInput>;
  eamId?: InputMaybe<StringOperationFilterInput>;
  forHire?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  insuranceCompany?: InputMaybe<StringOperationFilterInput>;
  insuranceExpiryDate?: InputMaybe<DateOperationFilterInput>;
  insurancePolicyNo?: InputMaybe<StringOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isBanned?: InputMaybe<BooleanOperationFilterInput>;
  maxWeight?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<VehicleFilterInput>>;
  owner?: InputMaybe<StringOperationFilterInput>;
  receiving?: InputMaybe<BooleanOperationFilterInput>;
  supplier?: InputMaybe<SupplierFilterInput>;
  supplierId?: InputMaybe<IntOperationFilterInput>;
  suppliers?: InputMaybe<ListFilterInputTypeOfSupplierFilterInput>;
  tare?: InputMaybe<DecimalOperationFilterInput>;
  trackingPhoneNo?: InputMaybe<StringOperationFilterInput>;
  trackingRefNo?: InputMaybe<StringOperationFilterInput>;
  transporterCode?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  vehicleNo?: InputMaybe<StringOperationFilterInput>;
  vehicleType?: InputMaybe<VehicleTypeFilterInput>;
  vehicleTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type VehicleSortInput = {
  capacity?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  country?: InputMaybe<CountrySortInput>;
  countryId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  dispatch?: InputMaybe<SortEnumType>;
  eamId?: InputMaybe<SortEnumType>;
  forHire?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  insuranceCompany?: InputMaybe<SortEnumType>;
  insuranceExpiryDate?: InputMaybe<SortEnumType>;
  insurancePolicyNo?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isBanned?: InputMaybe<SortEnumType>;
  maxWeight?: InputMaybe<SortEnumType>;
  owner?: InputMaybe<SortEnumType>;
  receiving?: InputMaybe<SortEnumType>;
  supplier?: InputMaybe<SupplierSortInput>;
  supplierId?: InputMaybe<SortEnumType>;
  tare?: InputMaybe<SortEnumType>;
  trackingPhoneNo?: InputMaybe<SortEnumType>;
  trackingRefNo?: InputMaybe<SortEnumType>;
  transporterCode?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  vehicleNo?: InputMaybe<SortEnumType>;
  vehicleType?: InputMaybe<VehicleTypeSortInput>;
  vehicleTypeId?: InputMaybe<SortEnumType>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  translationKey: Scalars['String']['output'];
};

export type VehicleTypeFilterInput = {
  and?: InputMaybe<Array<VehicleTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<VehicleTypeFilterInput>>;
  translationKey?: InputMaybe<StringOperationFilterInput>;
};

export type VehicleTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  translationKey?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type VehiclesConnection = {
  __typename?: 'VehiclesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<VehiclesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Vehicle>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VehiclesEdge = {
  __typename?: 'VehiclesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Vehicle;
};

export type VoidSalesVoucherInput = {
  id: Scalars['Int']['input'];
};

export type VoidSalesVoucherPayload = {
  __typename?: 'VoidSalesVoucherPayload';
  salesVoucher?: Maybe<SalesVoucher>;
};

export type VolumeBalanceReport = {
  __typename?: 'VolumeBalanceReport';
  productId?: Maybe<Scalars['Int']['output']>;
  productTitle?: Maybe<Scalars['String']['output']>;
  totalQuantity: Scalars['Decimal']['output'];
  unitOfMeasureId?: Maybe<Scalars['Int']['output']>;
};

export type PermissionStateQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionStateQuery = { __typename?: 'Query', permissionState: { __typename?: 'PermissionState', permissions: string } };

export type NavigationStateQueryVariables = Exact<{ [key: string]: never; }>;


export type NavigationStateQuery = { __typename?: 'Query', navigateState: { __typename?: 'NavigationState', hasGoBackRequest: boolean, apparentDataLoss: boolean } };

export type GetDashboardApprovedPaymentsGraphDataQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;


export type GetDashboardApprovedPaymentsGraphDataQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardReport', dashboardReportTypeEnumId: number, difference: any, total: any, percentage: any, dataPoints: Array<{ __typename?: 'KeyValuePairOfDateTimeAndDecimal', key: any, value: any }> } };

export type GetDashboardManualPaymentsGraphDataQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;


export type GetDashboardManualPaymentsGraphDataQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardReport', dashboardReportTypeEnumId: number, difference: any, total: any, percentage: any, dataPoints: Array<{ __typename?: 'KeyValuePairOfDateTimeAndDecimal', key: any, value: any }> } };

export type GetDashboardTotalOrderRequestGraphDataQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;


export type GetDashboardTotalOrderRequestGraphDataQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardReport', dashboardReportTypeEnumId: number, difference: any, total: any, percentage: any, dataPoints: Array<{ __typename?: 'KeyValuePairOfDateTimeAndDecimal', key: any, value: any }> } };

export type GetCompanyRevenueQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;


export type GetCompanyRevenueQuery = { __typename?: 'Query', revenueGraph: Array<{ __typename?: 'AdminDashboardGraph', date: any, totalAmountApproved: any }> };

export type GetCompanySummaryReportsQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;


export type GetCompanySummaryReportsQuery = { __typename?: 'Query', adminSummaryReport: Array<{ __typename?: 'AdminSummaryReport', count: number, reportType: string, total: any }> };

export type GetCompanySoldProductsQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;


export type GetCompanySoldProductsQuery = { __typename?: 'Query', soldProducts: Array<{ __typename?: 'VolumeBalanceReport', productId?: number | null, productTitle?: string | null, totalQuantity: any, unitOfMeasureId?: number | null }> };

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetUserByEmailQuery = { __typename?: 'Query', userByEmail?: { __typename?: 'User', id: number, fullName: string, userName: string, email: string, companyId: number, locationId?: number | null, roleId?: number | null, permissions: Array<string>, company: { __typename?: 'Company', countryId: number, code: string, customerTranscription: boolean }, location?: { __typename?: 'Location', id: number, name: string, code: string, type: string, companyId: number, company: { __typename?: 'Company', id: number, code: string, countryId: number, name: string, customerTranscription: boolean, defaultCurrencyCode: string } } | null, language: { __typename?: 'Language', lang: string, code: string, id: number }, role?: { __typename?: 'Role', id: number, description?: string | null } | null, companies: Array<{ __typename?: 'Company', id: number, code: string, name: string }>, locations: Array<{ __typename?: 'Location', id: number, name: string, code: string }> } | null };

export type GetDocumentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDocumentTypesQuery = { __typename?: 'Query', documentTypes: Array<{ __typename?: 'DocumentType', id: number, description: string }> };

export type GetPaymentApprovalStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentApprovalStatusesQuery = { __typename?: 'Query', paymentApprovalStatus: Array<{ __typename?: 'PaymentApprovalStatus', id: number, description: string }> };

export type GetPaymentApprovalTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentApprovalTypesQuery = { __typename?: 'Query', paymentApprovalType: Array<{ __typename?: 'PaymentApprovalType', id: number, description: string }> };

export type GetCustomerRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerRolesQuery = { __typename?: 'Query', customerRoles: Array<{ __typename?: 'Role', id: number, description?: string | null, isInternal: boolean }> };

export type GetActiveBanksQueryVariables = Exact<{
  countryId: Scalars['Int']['input'];
}>;


export type GetActiveBanksQuery = { __typename?: 'Query', banks?: { __typename?: 'BanksConnection', nodes?: Array<{ __typename?: 'Bank', id: number, name: string, description?: string | null, createdAt: any, updatedAt: any, code: string, isActive: boolean, country: { __typename?: 'Country', id: number, name: string } }> | null } | null };

export type GetActiveBankBranchesQueryVariables = Exact<{
  bankId: Scalars['Int']['input'];
}>;


export type GetActiveBankBranchesQuery = { __typename?: 'Query', bankBranches?: { __typename?: 'BankBranchesConnection', nodes?: Array<{ __typename?: 'BankBranch', id: number, name: string, description?: string | null, createdAt: any, updatedAt: any, code: string, isActive: boolean }> | null } | null };

export type UpsertUserDefaultLanguagemutationMutationVariables = Exact<{
  langId: UpdateUserDefaultLanguageInput;
}>;


export type UpsertUserDefaultLanguagemutationMutation = { __typename?: 'Mutation', updateUserDefaultLanguage: { __typename: 'UpdateUserDefaultLanguagePayload' } };

export type GetLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLanguagesQuery = { __typename?: 'Query', languages: Array<{ __typename?: 'Language', lang: string, code: string, id: number }> };

export type GetVehiclesLookupByVehicleNumberQueryVariables = Exact<{
  searchString: Scalars['String']['input'];
  countryIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  vehcileTypeId: Scalars['Int']['input'];
}>;


export type GetVehiclesLookupByVehicleNumberQuery = { __typename?: 'Query', vehicles?: { __typename?: 'VehiclesConnection', nodes?: Array<{ __typename?: 'Vehicle', id: number, vehicleNo: string, insuranceExpiryDate?: any | null, insurancePolicyNo?: string | null, trackingRefNo?: string | null, trackingPhoneNo?: string | null, transporterCode?: string | null, owner?: string | null, isBanned: boolean, isActive: boolean, tare?: any | null, maxWeight?: any | null, createdAt: any, supplier?: { __typename?: 'Supplier', supplierName: string, supplierCode: string } | null }> | null } | null };

export type UpsertUserDeviceTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type UpsertUserDeviceTokenMutation = { __typename?: 'Mutation', upsertUserDevice: { __typename?: 'UpsertUserDevicePayload', userDevice?: { __typename?: 'UserDevice', user: { __typename?: 'User', userName: string } } | null } };

export type GetUserNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserNotificationsQuery = { __typename?: 'Query', userNotifications?: { __typename?: 'UserNotificationsConnection', nodes?: Array<{ __typename?: 'NotificationUser', id: number, notificationStatusId: number, createdAt: any, updatedAt: any, notificationStatus: { __typename?: 'NotificationStatus', id: number, description: string, translationKey: string }, notification: { __typename?: 'Notification', id: number, title: string, body: string, componentId?: string | null, componentName?: string | null, notificationTypeId: number, createdAt: any, notificationType: { __typename?: 'NotificationType', id: number, description: string, translationKey: string } } }> | null } | null };

export type GetPaymentIdByPaymentNumberQueryVariables = Exact<{
  paymentNumber: Scalars['String']['input'];
}>;


export type GetPaymentIdByPaymentNumberQuery = { __typename?: 'Query', payments?: { __typename?: 'PaymentsConnection', nodes?: Array<{ __typename?: 'Payment', id: number }> | null } | null };

export type MarkNotificationAsReadMutationVariables = Exact<{
  notificationId: Scalars['Int']['input'];
}>;


export type MarkNotificationAsReadMutation = { __typename?: 'Mutation', readUserNotification: { __typename?: 'ReadUserNotificationPayload', notificationUser?: { __typename?: 'NotificationUser', userId: number } | null } };

export type MarkAllNotificationsAsReadMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkAllNotificationsAsReadMutation = { __typename?: 'Mutation', readAllUserNotification: { __typename?: 'ReadAllUserNotificationPayload', boolean?: boolean | null } };

export type GetDescriptionValuesQueryVariables = Exact<{
  searchString: Scalars['String']['input'];
}>;


export type GetDescriptionValuesQuery = { __typename?: 'Query', descriptionValues: Array<{ __typename?: 'DescriptionValue', id: number, description: string }> };

export type GetPaginatedCompaniesQueryVariables = Exact<{
  name: Scalars['String']['input'];
  code: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  countryId: Scalars['Int']['input'];
}>;


export type GetPaginatedCompaniesQuery = { __typename?: 'Query', companies?: { __typename?: 'CompaniesConnection', totalCount: number, edges?: Array<{ __typename?: 'CompaniesEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'Company', id: number, name: string, code: string, eamOrganization: string, sunBusinessUnit: string, countryId: number, updatedAt: any, createdAt: any, country: { __typename?: 'Country', id: number, name: string } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type GetUserAccountsByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUserAccountsByCompanyIdQuery = { __typename?: 'Query', usersByCompany?: { __typename?: 'UsersByCompanyConnection', totalCount: number, nodes?: Array<{ __typename?: 'User', createdAt: any, customerId?: number | null, email: string, fullName: string, id: number, isActive: boolean, languageId: number, locationId?: number | null, permissions: Array<string>, phoneNumber?: any | null, termsAndConditionId?: number | null, updatedAt: any, userName: string, company: { __typename?: 'Company', id: number, name: string, country: { __typename?: 'Country', id: number, name: string } }, location?: { __typename?: 'Location', id: number, name: string } | null, customer?: { __typename?: 'Customer', id: number, customerName: string, active: boolean, customerCode: string } | null, role?: { __typename?: 'Role', id: number, description?: string | null } | null }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };

export type CompaniesCountQueryVariables = Exact<{
  countryId: Scalars['Int']['input'];
}>;


export type CompaniesCountQuery = { __typename?: 'Query', companies?: { __typename?: 'CompaniesConnection', totalCount: number } | null };

export type GetCompaniesQueryVariables = Exact<{
  countryId: Scalars['Int']['input'];
}>;


export type GetCompaniesQuery = { __typename?: 'Query', companies?: { __typename?: 'CompaniesConnection', nodes?: Array<{ __typename?: 'Company', id: number, name: string }> | null } | null };

export type UpsertCompanyMutationVariables = Exact<{
  input: UpsertCompanyInput;
}>;


export type UpsertCompanyMutation = { __typename?: 'Mutation', upsertCompany: { __typename?: 'UpsertCompanyPayload', company?: { __typename?: 'Company', id: number } | null } };

export type UpdateUserActiveMutationVariables = Exact<{
  input: UpdateUserActiveInput;
}>;


export type UpdateUserActiveMutation = { __typename?: 'Mutation', updateUserActive: { __typename?: 'UpdateUserActivePayload', boolean?: boolean | null } };

export type UpsertBankDataMutationVariables = Exact<{
  input: UpsertBankInput;
}>;


export type UpsertBankDataMutation = { __typename?: 'Mutation', upsertBank: { __typename?: 'UpsertBankPayload', bank?: { __typename?: 'Bank', id: number } | null } };

export type GetPaginatedBanksByCountryIdQueryVariables = Exact<{
  countryId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  code: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPaginatedBanksByCountryIdQuery = { __typename?: 'Query', banks?: { __typename?: 'BanksConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges?: Array<{ __typename?: 'BanksEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'Bank', id: number, code: string, name: string, isActive: boolean, countryId: number, description?: string | null, bankBranches: Array<{ __typename?: 'BankBranch', id: number, description?: string | null, name: string, code: string }>, country: { __typename?: 'Country', id: number, name: string } }> | null } | null };

export type UpsertBankBranchMutationVariables = Exact<{
  input: UpsertBankBranchInput;
}>;


export type UpsertBankBranchMutation = { __typename?: 'Mutation', upsertBankBranch: { __typename?: 'UpsertBankBranchPayload', bankBranch?: { __typename?: 'BankBranch', id: number, code: string, description?: string | null, name: string } | null } };

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  countryId: Scalars['Int']['input'];
}>;


export type GetCompanyQuery = { __typename?: 'Query', companies?: { __typename?: 'CompaniesConnection', nodes?: Array<{ __typename?: 'Company', id: number, name: string, code: string, eamOrganization: string, sunBusinessUnit: string, countryId: number, customerTranscription: boolean, updatedAt: any, createdAt: any, country: { __typename?: 'Country', id: number, name: string } }> | null } | null };

export type GetFilteredCountriesQueryVariables = Exact<{
  countryName?: Scalars['String']['input'];
  adGroupName?: Scalars['String']['input'];
}>;


export type GetFilteredCountriesQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'Country', name: string, cluster?: number | null, id: number, callingCode: string, alpha2?: string | null, alpha3?: string | null, utcOffset: number, adGroupName: string }> };

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'Country', name: string, cluster?: number | null, id: number, callingCode: string, alpha2?: string | null, alpha3?: string | null, utcOffset: number, adGroupName: string }> };

export type GetCustomersByCompanyQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  customerName?: Scalars['String']['input'];
  customerCode?: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCustomersByCompanyQuery = { __typename?: 'Query', customers?: { __typename?: 'CustomersConnection', totalCount: number, nodes?: Array<{ __typename?: 'Customer', id: number, customerCode: string, customerName: string, phoneNumber?: any | null, active: boolean, altPhoneNumber?: any | null, company: { __typename?: 'Company', id: number, name: string, code: string } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type GetCustomersByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCustomersByCompanyIdQuery = { __typename?: 'Query', customers?: { __typename?: 'CustomersConnection', totalCount: number, nodes?: Array<{ __typename?: 'Customer', id: number, customerCode: string, customerName: string, phoneNumber?: any | null, active: boolean, email?: string | null, altPhoneNumber?: any | null, company: { __typename?: 'Company', id: number, name: string, code: string } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type GetCustomerByIdQueryVariables = Exact<{
  customerId: Scalars['Int']['input'];
}>;


export type GetCustomerByIdQuery = { __typename?: 'Query', customerById?: { __typename?: 'Customer', active: boolean, altPhoneNumber?: any | null, companyId: number, createdAt: any, customerCode: string, customerName: string, customerSegmentId?: number | null, email?: string | null, id: number, phoneNumber?: any | null, updatedAt: any, customerSegment?: { __typename?: 'CustomerSegment', name: string, id: number } | null, company: { __typename?: 'Company', name: string }, vehicles: Array<{ __typename?: 'Vehicle', id: number, vehicleNo: string, isActive: boolean, isBanned: boolean, owner?: string | null, tare?: any | null, capacity?: any | null, maxWeight?: any | null, eamId?: string | null, trackingRefNo?: string | null, supplierId?: number | null }> } | null };

export type GetCustomerAccountUsersQueryVariables = Exact<{
  customerId: Scalars['Int']['input'];
}>;


export type GetCustomerAccountUsersQuery = { __typename?: 'Query', customerAccountUsers?: { __typename?: 'CustomerAccountUsersConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, nodes?: Array<{ __typename?: 'User', id: number, userName: string, fullName: string, email: string, companyId: number, phoneNumber?: any | null, roleId?: number | null, createdAt: any, isActive: boolean, role?: { __typename?: 'Role', id: number, description?: string | null } | null, termsAndCondition?: { __typename?: 'TermsAndCondition', versionId: string } | null }> | null } | null };

export type GetUsersByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUsersByCompanyIdQuery = { __typename?: 'Query', usersByCompany?: { __typename?: 'UsersByCompanyConnection', totalCount: number, nodes?: Array<{ __typename?: 'User', companyId: number, createdAt: any, customerId?: number | null, email: string, fullName: string, id: number, isActive: boolean, languageId: number, locationId?: number | null, permissions: Array<string>, phoneNumber?: any | null, termsAndConditionId?: number | null, updatedAt: any, userName: string }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };

export type UpdateUserAccountMutationVariables = Exact<{
  customerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
  roleId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateUserAccountMutation = { __typename?: 'Mutation', updateUserAccount: { __typename?: 'UpdateUserAccountPayload', boolean?: boolean | null } };

export type UnlinkUserAccountMutationVariables = Exact<{
  customerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;


export type UnlinkUserAccountMutation = { __typename?: 'Mutation', unlinkUserAccount: { __typename?: 'UnlinkUserAccountPayload', boolean?: boolean | null } };

export type GetTrucksByCustomerQueryVariables = Exact<{
  customerId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTrucksByCustomerQuery = { __typename?: 'Query', vehicles?: { __typename?: 'VehiclesConnection', totalCount: number, nodes?: Array<{ __typename?: 'Vehicle', id: number, vehicleNo: string, isActive: boolean, isBanned: boolean, owner?: string | null, tare?: any | null, capacity?: any | null, maxWeight?: any | null, eamId?: string | null, trackingRefNo?: string | null, supplierId?: number | null, vehicleTypeId: number, transporterCode?: string | null, vehicleType: { __typename?: 'VehicleType', id: number, description: string }, supplier?: { __typename?: 'Supplier', supplierName: string, supplierCode: string } | null }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type UpdateCustomerTrucksMutationVariables = Exact<{
  customerId: Scalars['Int']['input'];
  vehicles?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type UpdateCustomerTrucksMutation = { __typename?: 'Mutation', upsertCustomerVehicle: { __typename?: 'UpsertCustomerVehiclePayload', customer?: { __typename?: 'Customer', id: number } | null } };

export type GetPaginatedLocationsQueryVariables = Exact<{
  name: Scalars['String']['input'];
  code: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  countryId: Scalars['Int']['input'];
}>;


export type GetPaginatedLocationsQuery = { __typename?: 'Query', locations?: { __typename?: 'LocationsConnection', totalCount: number, nodes?: Array<{ __typename?: 'Location', id: number, code: string, name: string, type: string, costCentre?: string | null, pmEmployee: boolean, businessUnitLine?: string | null, vatCode?: string | null, companyId: number, updatedAt: any, company: { __typename?: 'Company', name: string } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type GetPaginatedLocationsByCompanyQueryVariables = Exact<{
  name: Scalars['String']['input'];
  code: Scalars['String']['input'];
  companyId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  countryId: Scalars['Int']['input'];
}>;


export type GetPaginatedLocationsByCompanyQuery = { __typename?: 'Query', locations?: { __typename?: 'LocationsConnection', totalCount: number, nodes?: Array<{ __typename?: 'Location', id: number, code: string, type: string, name: string, companyId: number, updatedAt: any, costCentre?: string | null, pmEmployee: boolean, businessUnitLine?: string | null, vatCode?: string | null, company: { __typename?: 'Company', name: string } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type UpsertLocationMutationVariables = Exact<{
  input: UpsertLocationInput;
}>;


export type UpsertLocationMutation = { __typename?: 'Mutation', upsertLocation: { __typename?: 'UpsertLocationPayload', location?: { __typename?: 'Location', id: number } | null } };

export type UpsertProductConstraintMutationVariables = Exact<{
  input: UpsertProductConstraintInput;
}>;


export type UpsertProductConstraintMutation = { __typename?: 'Mutation', upsertProductConstraint: { __typename?: 'UpsertProductConstraintPayload', productConstraint?: { __typename?: 'ProductConstraint', id: number } | null } };

export type GetPaginatedProductConstraintsQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPaginatedProductConstraintsQuery = { __typename?: 'Query', productConstraints?: { __typename?: 'ProductConstraintsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null }, nodes?: Array<{ __typename?: 'ProductConstraint', id: number, customerSegmentId: number, productId: number, minProductQuantity: any, maxProductQuantity: any, maxTruckQuantity: any, minTruckQuantity: any, companyId: number, customerSegment: { __typename?: 'CustomerSegment', name: string, companyId: number, company: { __typename?: 'Company', countryId: number, id: number } }, product: { __typename?: 'Product', title: string, uoS?: string | null, productMeasurementId: number, unitOfMeasure: { __typename?: 'UnitOfMeasure', description: string, id: number } } }> | null } | null };

export type GetCustmerSegmentQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type GetCustmerSegmentQuery = { __typename?: 'Query', customerSegments?: { __typename?: 'CustomerSegmentsConnection', nodes?: Array<{ __typename?: 'CustomerSegment', name: string, companyId: number, id: number }> | null } | null };

export type GetProductsByCompanyQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  countryId: Scalars['Int']['input'];
}>;


export type GetProductsByCompanyQuery = { __typename?: 'Query', products?: { __typename?: 'ProductsConnection', nodes?: Array<{ __typename?: 'Product', companyId: number, id: number, title: string, lookup?: string | null }> | null } | null };

export type DeleteProductConstraintMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteProductConstraintMutation = { __typename?: 'Mutation', deleteProductConstraint: { __typename?: 'DeleteProductConstraintPayload', boolean?: boolean | null } };

export type UpsertSettlementBankMutationVariables = Exact<{
  input: UpsertSettlementBankInput;
}>;


export type UpsertSettlementBankMutation = { __typename?: 'Mutation', upsertSettlementBank: { __typename?: 'UpsertSettlementBankPayload', settlementBank?: { __typename?: 'SettlementBank', id: number } | null } };

export type GetSettlementBanksByCompanyQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetSettlementBanksByCompanyQuery = { __typename?: 'Query', settlementBanks?: { __typename?: 'SettlementBanksConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges?: Array<{ __typename?: 'SettlementBanksEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'SettlementBank', name: string, isIncoming: boolean, isActive: boolean, sunAccountCode: string, id: number, description?: string | null, code: string, companyId: number }> | null } | null };

export type GetPaginatedSettlementBanksByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  code: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPaginatedSettlementBanksByCompanyIdQuery = { __typename?: 'Query', settlementBanks?: { __typename?: 'SettlementBanksConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges?: Array<{ __typename?: 'SettlementBanksEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'SettlementBank', name: string, isIncoming: boolean, isActive: boolean, sunAccountCode: string, id: number, description?: string | null, code: string, companyId: number, company: { __typename?: 'Company', id: number, name: string, code: string, countryId: number } }> | null } | null };

export type UpsertContentManagementRecordMutationVariables = Exact<{
  input: UpsertContentInput;
}>;


export type UpsertContentManagementRecordMutation = { __typename?: 'Mutation', upsertContent: { __typename?: 'UpsertContentPayload', content?: { __typename?: 'Content', id: number } | null } };

export type GetAllContentManagementByTypeQueryVariables = Exact<{
  countryId: Scalars['Int']['input'];
  contentType: Scalars['Int']['input'];
}>;


export type GetAllContentManagementByTypeQuery = { __typename?: 'Query', contents?: { __typename?: 'ContentsConnection', nodes?: Array<{ __typename?: 'Content', id: number, title: string, description: string, featuredImageUrl?: string | null, fileName?: string | null, createdAt: any }> | null } | null };

export type GePaginatedContentRecordsByTypeQueryVariables = Exact<{
  contentType: Scalars['Int']['input'];
  countryId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GePaginatedContentRecordsByTypeQuery = { __typename?: 'Query', contents?: { __typename?: 'ContentsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges?: Array<{ __typename?: 'ContentsEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'Content', id: number, title: string, description: string, featuredImageUrl?: string | null, fileName?: string | null, createdAt: any }> | null } | null };

export type DeleteContentManagementRecordMutationVariables = Exact<{
  input: DeleteContentInput;
}>;


export type DeleteContentManagementRecordMutation = { __typename?: 'Mutation', deleteContent: { __typename?: 'DeleteContentPayload', boolean?: boolean | null } };

export type GePaginatedProductsQueryVariables = Exact<{
  countryId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GePaginatedProductsQuery = { __typename?: 'Query', products?: { __typename?: 'ProductsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges?: Array<{ __typename?: 'ProductsEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'Product', id: number, title: string, description: string, featuredImageUrl?: string | null, fileName?: string | null, companyId: number, productMeasurementId: number, unitOfMeasureId: number, itemCode: string, createdAt: any, isPublished: boolean }> | null } | null };

export type UpsertProductMutationVariables = Exact<{
  input: UpsertProductInput;
}>;


export type UpsertProductMutation = { __typename?: 'Mutation', upsertProduct: { __typename?: 'UpsertProductPayload', product?: { __typename?: 'Product', id: number } | null } };

export type DeleteProductMutationVariables = Exact<{
  input: DeleteProductInput;
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', deleteProduct: { __typename?: 'DeleteProductPayload', boolean?: boolean | null } };

export type GetUnitOfMeasureQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitOfMeasureQuery = { __typename?: 'Query', unitsOfMeasure: Array<{ __typename?: 'UnitOfMeasure', id: number, description: string }> };

export type GetProductMeasurementsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductMeasurementsQuery = { __typename?: 'Query', productMeasurements: Array<{ __typename?: 'ProductMeasurement', id: number, description: string }> };

export type GetPaginatedContactsQueryVariables = Exact<{
  countryId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPaginatedContactsQuery = { __typename?: 'Query', contacts?: { __typename?: 'ContactsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges?: Array<{ __typename?: 'ContactsEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'Contact', id: number, locationId: number, telephoneNumber: string, physicalAddress: string, postalAddress?: string | null, email?: string | null, createdAt: any, location: { __typename?: 'Location', name: string, latitude: any, longitude: any } }> | null } | null };

export type UpsertContactMutationVariables = Exact<{
  input: UpsertContactInput;
}>;


export type UpsertContactMutation = { __typename?: 'Mutation', upsertContact: { __typename?: 'UpsertContactPayload', contact?: { __typename?: 'Contact', id: number } | null } };

export type DeleteContactMutationVariables = Exact<{
  input: DeleteContactInput;
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact: { __typename?: 'DeleteContactPayload', boolean?: boolean | null } };

export type UpsertPaymentApprovalMutationVariables = Exact<{
  input: UpsertPaymentApprovalInput;
}>;


export type UpsertPaymentApprovalMutation = { __typename?: 'Mutation', upsertPaymentApproval: { __typename?: 'UpsertPaymentApprovalPayload', paymentApproval?: { __typename?: 'PaymentApproval', id: number } | null } };

export type ApprovePaymentMutationVariables = Exact<{
  input: ApprovePaymentInput;
}>;


export type ApprovePaymentMutation = { __typename?: 'Mutation', approvePayment: { __typename?: 'ApprovePaymentPayload', payment?: { __typename?: 'Payment', id: number, paymentStatusId: number, createdAt: any } | null } };

export type UpsertPaymentTranscriptionMutationVariables = Exact<{
  input: UpsertPaymentTranscriptionInput;
}>;


export type UpsertPaymentTranscriptionMutation = { __typename?: 'Mutation', upsertPaymentTranscription: { __typename?: 'UpsertPaymentTranscriptionPayload', paymentTranscription?: { __typename?: 'PaymentTranscription', id: number } | null } };

export type GetManualPaymentQueryVariables = Exact<{
  paymentId: Scalars['Int']['input'];
}>;


export type GetManualPaymentQuery = { __typename?: 'Query', payments?: { __typename?: 'PaymentsConnection', nodes?: Array<{ __typename?: 'Payment', id: number, amount: any, paymentModeId: number, paymentReference: string, transactionId?: string | null, updatedAt: any, paymentMode: { __typename?: 'PaymentMode', name: string, id: number, documentType: { __typename?: 'DocumentType', id: number, description: string } }, paymentApprovals: Array<{ __typename?: 'PaymentApproval', id: number, amountApproved: any, settlementBankId?: number | null, reasonId?: number | null, paymentApprovalStatusId: number }>, salesVoucher: { __typename?: 'SalesVoucher', id: number, voucherNumber: string, orderQuote?: any | null, createdAt: any, salesVoucherItems: Array<{ __typename?: 'SalesVoucherItem', id: number, trucksCount: number, unitPrice?: any | null, quantity: any, product: { __typename?: 'Product', description: string, title: string, itemCode: string, featuredImageUrl?: string | null, uoS?: string | null }, salesVoucherOrders: Array<{ __typename?: 'SalesVoucherOrder', salesOrderNumber: string }> }>, customer: { __typename?: 'Customer', customerName: string, customerCode: string, email?: string | null, phoneNumber?: any | null, createdAt: any } }, paymentStatus: { __typename?: 'PaymentStatus', id: number, description: string }, paymentTranscriptions: Array<{ __typename?: 'PaymentTranscription', amount: any, depositorContact?: string | null, depositorName?: string | null, documentDate: any, documentReference?: string | null, notes?: string | null, bank?: { __typename?: 'Bank', id: number, name: string } | null, bankBranch?: { __typename?: 'BankBranch', id: number, name: string } | null, documentType: { __typename?: 'DocumentType', id: number, description: string } }>, paymentDocuments: Array<{ __typename?: 'PaymentDocument', createdAt: any, documentName?: string | null, documentUrl: string, id: number, paymentId: number, updatedAt: any }> }> | null } | null };

export type GetPaymentTranscriptionsQueryVariables = Exact<{
  paymentId: Scalars['Int']['input'];
}>;


export type GetPaymentTranscriptionsQuery = { __typename?: 'Query', paymentTranscriptions?: { __typename?: 'PaymentTranscriptionsConnection', nodes?: Array<{ __typename?: 'PaymentTranscription', id: number, amount: any, bankId?: number | null, bankBranchId?: number | null, createdAt: any, depositorContact?: string | null, depositorName?: string | null, documentDate: any, documentReference?: string | null, documentTypeId: number, notes?: string | null, paymentId: number, paymentTranscriptionStatusId: number, updatedAt: any, bank?: { __typename?: 'Bank', id: number, name: string } | null, bankBranch?: { __typename?: 'BankBranch', id: number, name: string } | null, documentType: { __typename?: 'DocumentType', id: number, description: string }, payment: { __typename?: 'Payment', id: number }, paymentTranscriptionStatus: { __typename?: 'PaymentTranscriptionStatus', id: number, description: string }, createdBy?: { __typename?: 'User', id: number, email: string, fullName: string, userName: string } | null }> | null } | null };

export type GetPaymentApprovalsQueryVariables = Exact<{
  paymentId: Scalars['Int']['input'];
}>;


export type GetPaymentApprovalsQuery = { __typename?: 'Query', paymentApprovals?: { __typename?: 'PaymentApprovalsConnection', nodes?: Array<{ __typename?: 'PaymentApproval', id: number, createdAt: any, approvalComment?: string | null, amountApproved: any, settlementBankId?: number | null, paymentApprovalStatusId: number, rejectionComment?: string | null, settlementBank?: { __typename?: 'SettlementBank', id: number, name: string } | null, paymentApprovalStatus: { __typename?: 'PaymentApprovalStatus', id: number, description: string }, createdBy?: { __typename?: 'User', id: number, userName: string } | null }> | null } | null };

export type GetPaymentsDataQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPaymentsDataQuery = { __typename?: 'Query', payments?: { __typename?: 'PaymentsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges?: Array<{ __typename?: 'PaymentsEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'Payment', id: number, amount: any, createdAt: any, paymentModeId: number, paymentReference: string, amountApproved?: any | null, transactionId?: string | null, updatedAt: any, paymentMode: { __typename?: 'PaymentMode', name: string, id: number }, salesVoucher: { __typename?: 'SalesVoucher', id: number, orderQuote?: any | null, voucherNumber: string, customer: { __typename?: 'Customer', customerName: string, customerCode: string } }, paymentStatus: { __typename?: 'PaymentStatus', id: number, description: string }, paymentDocuments: Array<{ __typename?: 'PaymentDocument', createdAt: any, documentName?: string | null, documentUrl: string, id: number, paymentId: number, updatedAt: any }> }> | null } | null };

export type GetSalesVoucherPaymentsQueryVariables = Exact<{
  salesVoucherId: Scalars['Int']['input'];
}>;


export type GetSalesVoucherPaymentsQuery = { __typename?: 'Query', payments?: { __typename?: 'PaymentsConnection', nodes?: Array<{ __typename?: 'Payment', id: number, amount: any, paymentModeId: number, paymentReference: string, transactionId?: string | null, updatedAt: any, paymentMode: { __typename?: 'PaymentMode', name: string, id: number }, salesVoucher: { __typename?: 'SalesVoucher', id: number }, paymentStatus: { __typename?: 'PaymentStatus', id: number, description: string }, paymentDocuments: Array<{ __typename?: 'PaymentDocument', createdAt: any, documentName?: string | null, documentUrl: string, id: number, paymentId: number, updatedAt: any }> }> | null } | null };

export type GetPaymentByIdQueryVariables = Exact<{
  paymentId: Scalars['Int']['input'];
}>;


export type GetPaymentByIdQuery = { __typename?: 'Query', payments?: { __typename?: 'PaymentsConnection', nodes?: Array<{ __typename?: 'Payment', id: number, amount: any, paymentModeId: number, paymentReference: string, transactionId?: string | null, updatedAt: any, paymentMode: { __typename?: 'PaymentMode', name: string, id: number }, salesVoucher: { __typename?: 'SalesVoucher', id: number }, paymentStatus: { __typename?: 'PaymentStatus', id: number, description: string }, paymentDocuments: Array<{ __typename?: 'PaymentDocument', createdAt: any, documentName?: string | null, documentUrl: string, id: number, paymentId: number, updatedAt: any }> }> | null } | null };

export type UpsertQuickFilterMutationVariables = Exact<{
  input: UpsertPredefinedReportFilterInput;
}>;


export type UpsertQuickFilterMutation = { __typename?: 'Mutation', upsertPredefinedReportFilter: { __typename?: 'UpsertPredefinedReportFilterPayload', predefinedReportFilter?: { __typename?: 'PredefinedReportFilter', id: number, name: string } | null } };

export type GetQuickFiltersQueryVariables = Exact<{
  locationId: Scalars['Int']['input'];
  reportTypeId: Scalars['Int']['input'];
}>;


export type GetQuickFiltersQuery = { __typename?: 'Query', predefinedReportFilters: Array<{ __typename?: 'PredefinedReportFilter', id: number, name: string, data: string, locationId: number, reportTypeId: number, reportType: { __typename?: 'ReportType', description: string } }> };

export type DeleteQuickFilterMutationVariables = Exact<{
  input: DeletePredefinedReportFilterInput;
}>;


export type DeleteQuickFilterMutation = { __typename?: 'Mutation', deletePredefinedReportFilter: { __typename?: 'DeletePredefinedReportFilterPayload', boolean?: boolean | null } };

export type GetSalesVouchersByCompanyQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  order?: InputMaybe<SortEnumType>;
  take?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSalesVouchersByCompanyQuery = { __typename?: 'Query', salesVouchers?: { __typename?: 'SalesVouchersConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, nodes?: Array<{ __typename?: 'SalesVoucher', id: number, createdAt: any, voucherNumber: string, orderQuote?: any | null, salesVoucherStatusId: number, destinationId?: number | null, companyId: number, destination?: { __typename?: 'Destination', id: number, name?: string | null } | null, createdBy: { __typename?: 'User', id: number, userName: string, fullName: string }, salesVoucherStatus: { __typename?: 'SalesVoucherStatus', id: number, description: string }, sourceLocation: { __typename?: 'Location', id: number, name: string }, customer: { __typename?: 'Customer', id: number, customerName: string }, salesVoucherItems: Array<{ __typename?: 'SalesVoucherItem', id: number, quantity: any, trucksCount: number, destination?: { __typename?: 'Destination', id: number, name?: string | null } | null, salesVoucherOrders: Array<{ __typename?: 'SalesVoucherOrder', salesOrderNumber: string }>, product: { __typename?: 'Product', id: number, title: string, description: string } }>, payments: Array<{ __typename?: 'Payment', id: number, paymentReference: string, paymentStatusId: number, paymentStatus: { __typename?: 'PaymentStatus', id: number } }> }> | null } | null };

export type GetEPaymentsByCompanyQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  order?: InputMaybe<SortEnumType>;
  take?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetEPaymentsByCompanyQuery = { __typename?: 'Query', payments?: { __typename?: 'PaymentsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, nodes?: Array<{ __typename?: 'Payment', amount: any, amountApproved?: any | null, createdAt: any, customReference?: string | null, id: number, paymentReference: string, paymentStatusId: number, rejectionComment?: string | null, paymentApprovals: Array<{ __typename?: 'PaymentApproval', approvalComment?: string | null, createdBy?: { __typename?: 'User', id: number, userName: string, fullName: string } | null }>, paymentMode: { __typename?: 'PaymentMode', id: number, name: string, documentType: { __typename?: 'DocumentType', id: number, description: string } }, paymentStatus: { __typename?: 'PaymentStatus', id: number, description: string }, reason?: { __typename?: 'Reason', detail: string } | null }> | null } | null };

export type GetManualPaymentsByCompanyQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  order?: InputMaybe<SortEnumType>;
  take?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetManualPaymentsByCompanyQuery = { __typename?: 'Query', payments?: { __typename?: 'PaymentsConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, nodes?: Array<{ __typename?: 'Payment', amount: any, amountApproved?: any | null, createdAt: any, customReference?: string | null, id: number, paymentReference: string, paymentStatusId: number, rejectionComment?: string | null, paymentApprovals: Array<{ __typename?: 'PaymentApproval', approvalComment?: string | null, createdBy?: { __typename?: 'User', id: number, userName: string, fullName: string } | null }>, paymentMode: { __typename?: 'PaymentMode', id: number, name: string, documentType: { __typename?: 'DocumentType', id: number, description: string } }, paymentStatus: { __typename?: 'PaymentStatus', id: number, description: string }, reason?: { __typename?: 'Reason', detail: string } | null }> | null } | null };

export type GetUserReportQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['Int']['input'];
}>;


export type GetUserReportQuery = { __typename?: 'Query', users?: { __typename?: 'UsersConnection', totalCount: number, nodes?: Array<{ __typename?: 'User', id: number, userName: string, fullName: string, email: string, locationId?: number | null, companyId: number, companies: Array<{ __typename?: 'Company', id: number, name: string, code: string }>, location?: { __typename?: 'Location', id: number, name: string, code: string, type: string } | null }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type GetPaginatedSalesVouchersQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
  customerName?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetPaginatedSalesVouchersQuery = { __typename?: 'Query', salesVouchers?: { __typename?: 'SalesVouchersConnection', totalCount: number, edges?: Array<{ __typename?: 'SalesVouchersEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'SalesVoucher', id: number, voucherNumber: string, customerReference?: string | null, customOrderNumber?: string | null, customerId: number, orderQuote?: any | null, updatedAt: any, createdAt: any, customer: { __typename?: 'Customer', customerName: string, customerCode: string }, salesVoucherStatus: { __typename?: 'SalesVoucherStatus', description: string, id: number }, sourceLocation: { __typename?: 'Location', name: string }, destination?: { __typename?: 'Destination', name?: string | null } | null, payments: Array<{ __typename?: 'Payment', amount: any, id: number, paymentMode: { __typename?: 'PaymentMode', name: string } }> }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type SalesVouchersCountQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type SalesVouchersCountQuery = { __typename?: 'Query', salesVouchers?: { __typename?: 'SalesVouchersConnection', totalCount: number } | null };

export type GetSalesVouchersQueryVariables = Exact<{
  companyId: Scalars['Int']['input'];
}>;


export type GetSalesVouchersQuery = { __typename?: 'Query', salesVouchers?: { __typename?: 'SalesVouchersConnection', nodes?: Array<{ __typename?: 'SalesVoucher', id: number, voucherNumber: string }> | null } | null };

export type GetSalesVoucherQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  companyId: Scalars['Int']['input'];
}>;


export type GetSalesVoucherQuery = { __typename?: 'Query', salesVouchers?: { __typename?: 'SalesVouchersConnection', nodes?: Array<{ __typename?: 'SalesVoucher', id: number, voucherNumber: string, customerReference?: string | null, customOrderNumber?: string | null, customerId: number, orderQuote?: any | null, updatedAt: any, createdAt: any, customer: { __typename?: 'Customer', customerName: string, customerCode: string } }> | null } | null };

export type UpsertSalesVoucherMutationVariables = Exact<{
  input: UpsertSalesVoucherInput;
}>;


export type UpsertSalesVoucherMutation = { __typename?: 'Mutation', upsertSalesVoucher: { __typename?: 'UpsertSalesVoucherPayload', salesVoucher?: { __typename?: 'SalesVoucher', id: number } | null } };

export type GetSalesVoucherDatailsByOrderIdQueryVariables = Exact<{
  orderNumber: Scalars['String']['input'];
}>;


export type GetSalesVoucherDatailsByOrderIdQuery = { __typename?: 'Query', salesVouchers?: { __typename?: 'SalesVouchersConnection', nodes?: Array<{ __typename?: 'SalesVoucher', orderQuote?: any | null, salesVoucherItems: Array<{ __typename?: 'SalesVoucherItem', id: number, trucksCount: number, quantity: any, salesVoucherOrders: Array<{ __typename?: 'SalesVoucherOrder', salesOrderNumber: string, salesVoucherOrderStatus: { __typename?: 'SalesVoucherOrderStatus', description: string } }>, destination?: { __typename?: 'Destination', name?: string | null } | null, product: { __typename?: 'Product', itemCode: string, title: string, uoS?: string | null } }>, customer: { __typename?: 'Customer', customerName: string, customerCode: string, phoneNumber?: any | null, active: boolean }, salesVoucherStatus: { __typename?: 'SalesVoucherStatus', description: string }, payments: Array<{ __typename?: 'Payment', id: number, amount: any, salesVoucherId: number, transactionId?: string | null, createdAt: any, paymentReference: string, paymentMode: { __typename?: 'PaymentMode', name: string } }>, sourceLocation: { __typename?: 'Location', code: string, pmEmployee: boolean, contact?: { __typename?: 'Contact', telephoneNumber: string, email?: string | null } | null } }> | null } | null };

export type GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables = Exact<{
  salesVoucherItemId: Scalars['Int']['input'];
}>;


export type GetSalesVoucherOrdersBySalesVoucherItemIdQuery = { __typename?: 'Query', salesVoucherOrders?: { __typename?: 'SalesVoucherOrdersConnection', nodes?: Array<{ __typename?: 'SalesVoucherOrder', id: number, salesOrderNumber: string, paymentRef?: string | null, quantity: any, uoS?: string | null, salesVoucherOrderStatus: { __typename?: 'SalesVoucherOrderStatus', description: string }, destination?: { __typename?: 'Destination', name?: string | null } | null, salesVoucherItem?: { __typename?: 'SalesVoucherItem', id: number, trucksCount: number, quantity: any, createdAt: any, destination?: { __typename?: 'Destination', name?: string | null } | null, product: { __typename?: 'Product', itemCode: string, title: string, uoS?: string | null } } | null }> | null } | null };

export type GetPaginatedSalesOrdersQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  salesVoucher?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPaginatedSalesOrdersQuery = { __typename?: 'Query', salesVoucherOrders?: { __typename: 'SalesVoucherOrdersConnection', totalCount: number, edges?: Array<{ __typename?: 'SalesVoucherOrdersEdge', cursor: string }> | null, nodes?: Array<{ __typename?: 'SalesVoucherOrder', quotationId?: string | null, salesOrderNumber: string, createdAt: any, updatedAt: any, salesVoucherItem?: { __typename?: 'SalesVoucherItem', salesVoucher: { __typename?: 'SalesVoucher', voucherNumber: string, salesVoucherStatus: { __typename?: 'SalesVoucherStatus', description: string }, customer: { __typename?: 'Customer', customerCode: string, customerName: string } }, product: { __typename?: 'Product', itemCode: string } } | null, salesVoucherOrderStatus: { __typename?: 'SalesVoucherOrderStatus', description: string } }> | null, pageInfo: { __typename: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };


export const PermissionStateDocument = gql`
    query PermissionState {
  permissionState @client {
    permissions
  }
}
    `;

/**
 * __usePermissionStateQuery__
 *
 * To run a query within a React component, call `usePermissionStateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionStateQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionStateQuery(baseOptions?: Apollo.QueryHookOptions<PermissionStateQuery, PermissionStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionStateQuery, PermissionStateQueryVariables>(PermissionStateDocument, options);
      }
export function usePermissionStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionStateQuery, PermissionStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionStateQuery, PermissionStateQueryVariables>(PermissionStateDocument, options);
        }
export function usePermissionStateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PermissionStateQuery, PermissionStateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PermissionStateQuery, PermissionStateQueryVariables>(PermissionStateDocument, options);
        }
export type PermissionStateQueryHookResult = ReturnType<typeof usePermissionStateQuery>;
export type PermissionStateLazyQueryHookResult = ReturnType<typeof usePermissionStateLazyQuery>;
export type PermissionStateSuspenseQueryHookResult = ReturnType<typeof usePermissionStateSuspenseQuery>;
export type PermissionStateQueryResult = Apollo.QueryResult<PermissionStateQuery, PermissionStateQueryVariables>;
export const NavigationStateDocument = gql`
    query NavigationState {
  navigateState @client {
    hasGoBackRequest
    apparentDataLoss
  }
}
    `;

/**
 * __useNavigationStateQuery__
 *
 * To run a query within a React component, call `useNavigationStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationStateQuery(baseOptions?: Apollo.QueryHookOptions<NavigationStateQuery, NavigationStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationStateQuery, NavigationStateQueryVariables>(NavigationStateDocument, options);
      }
export function useNavigationStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationStateQuery, NavigationStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationStateQuery, NavigationStateQueryVariables>(NavigationStateDocument, options);
        }
export function useNavigationStateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NavigationStateQuery, NavigationStateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NavigationStateQuery, NavigationStateQueryVariables>(NavigationStateDocument, options);
        }
export type NavigationStateQueryHookResult = ReturnType<typeof useNavigationStateQuery>;
export type NavigationStateLazyQueryHookResult = ReturnType<typeof useNavigationStateLazyQuery>;
export type NavigationStateSuspenseQueryHookResult = ReturnType<typeof useNavigationStateSuspenseQuery>;
export type NavigationStateQueryResult = Apollo.QueryResult<NavigationStateQuery, NavigationStateQueryVariables>;
export const GetDashboardApprovedPaymentsGraphDataDocument = gql`
    query getDashboardApprovedPaymentsGraphData($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  dashboard(
    companyId: $companyId
    startDate: $startDate
    endDate: $endDate
    reportType: 1
  ) {
    dashboardReportTypeEnumId
    dataPoints {
      key
      value
    }
    difference
    total
    percentage
  }
}
    `;

/**
 * __useGetDashboardApprovedPaymentsGraphDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardApprovedPaymentsGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardApprovedPaymentsGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardApprovedPaymentsGraphDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardApprovedPaymentsGraphDataQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardApprovedPaymentsGraphDataQuery, GetDashboardApprovedPaymentsGraphDataQueryVariables> & ({ variables: GetDashboardApprovedPaymentsGraphDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardApprovedPaymentsGraphDataQuery, GetDashboardApprovedPaymentsGraphDataQueryVariables>(GetDashboardApprovedPaymentsGraphDataDocument, options);
      }
export function useGetDashboardApprovedPaymentsGraphDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardApprovedPaymentsGraphDataQuery, GetDashboardApprovedPaymentsGraphDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardApprovedPaymentsGraphDataQuery, GetDashboardApprovedPaymentsGraphDataQueryVariables>(GetDashboardApprovedPaymentsGraphDataDocument, options);
        }
export function useGetDashboardApprovedPaymentsGraphDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDashboardApprovedPaymentsGraphDataQuery, GetDashboardApprovedPaymentsGraphDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardApprovedPaymentsGraphDataQuery, GetDashboardApprovedPaymentsGraphDataQueryVariables>(GetDashboardApprovedPaymentsGraphDataDocument, options);
        }
export type GetDashboardApprovedPaymentsGraphDataQueryHookResult = ReturnType<typeof useGetDashboardApprovedPaymentsGraphDataQuery>;
export type GetDashboardApprovedPaymentsGraphDataLazyQueryHookResult = ReturnType<typeof useGetDashboardApprovedPaymentsGraphDataLazyQuery>;
export type GetDashboardApprovedPaymentsGraphDataSuspenseQueryHookResult = ReturnType<typeof useGetDashboardApprovedPaymentsGraphDataSuspenseQuery>;
export type GetDashboardApprovedPaymentsGraphDataQueryResult = Apollo.QueryResult<GetDashboardApprovedPaymentsGraphDataQuery, GetDashboardApprovedPaymentsGraphDataQueryVariables>;
export const GetDashboardManualPaymentsGraphDataDocument = gql`
    query getDashboardManualPaymentsGraphData($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  dashboard(
    companyId: $companyId
    startDate: $startDate
    endDate: $endDate
    reportType: 3
  ) {
    dashboardReportTypeEnumId
    dataPoints {
      key
      value
    }
    difference
    total
    percentage
  }
}
    `;

/**
 * __useGetDashboardManualPaymentsGraphDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardManualPaymentsGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardManualPaymentsGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardManualPaymentsGraphDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardManualPaymentsGraphDataQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardManualPaymentsGraphDataQuery, GetDashboardManualPaymentsGraphDataQueryVariables> & ({ variables: GetDashboardManualPaymentsGraphDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardManualPaymentsGraphDataQuery, GetDashboardManualPaymentsGraphDataQueryVariables>(GetDashboardManualPaymentsGraphDataDocument, options);
      }
export function useGetDashboardManualPaymentsGraphDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardManualPaymentsGraphDataQuery, GetDashboardManualPaymentsGraphDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardManualPaymentsGraphDataQuery, GetDashboardManualPaymentsGraphDataQueryVariables>(GetDashboardManualPaymentsGraphDataDocument, options);
        }
export function useGetDashboardManualPaymentsGraphDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDashboardManualPaymentsGraphDataQuery, GetDashboardManualPaymentsGraphDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardManualPaymentsGraphDataQuery, GetDashboardManualPaymentsGraphDataQueryVariables>(GetDashboardManualPaymentsGraphDataDocument, options);
        }
export type GetDashboardManualPaymentsGraphDataQueryHookResult = ReturnType<typeof useGetDashboardManualPaymentsGraphDataQuery>;
export type GetDashboardManualPaymentsGraphDataLazyQueryHookResult = ReturnType<typeof useGetDashboardManualPaymentsGraphDataLazyQuery>;
export type GetDashboardManualPaymentsGraphDataSuspenseQueryHookResult = ReturnType<typeof useGetDashboardManualPaymentsGraphDataSuspenseQuery>;
export type GetDashboardManualPaymentsGraphDataQueryResult = Apollo.QueryResult<GetDashboardManualPaymentsGraphDataQuery, GetDashboardManualPaymentsGraphDataQueryVariables>;
export const GetDashboardTotalOrderRequestGraphDataDocument = gql`
    query getDashboardTotalOrderRequestGraphData($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  dashboard(
    companyId: $companyId
    startDate: $startDate
    endDate: $endDate
    reportType: 4
  ) {
    dashboardReportTypeEnumId
    dataPoints {
      key
      value
    }
    difference
    total
    percentage
  }
}
    `;

/**
 * __useGetDashboardTotalOrderRequestGraphDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardTotalOrderRequestGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardTotalOrderRequestGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardTotalOrderRequestGraphDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDashboardTotalOrderRequestGraphDataQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardTotalOrderRequestGraphDataQuery, GetDashboardTotalOrderRequestGraphDataQueryVariables> & ({ variables: GetDashboardTotalOrderRequestGraphDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardTotalOrderRequestGraphDataQuery, GetDashboardTotalOrderRequestGraphDataQueryVariables>(GetDashboardTotalOrderRequestGraphDataDocument, options);
      }
export function useGetDashboardTotalOrderRequestGraphDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardTotalOrderRequestGraphDataQuery, GetDashboardTotalOrderRequestGraphDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardTotalOrderRequestGraphDataQuery, GetDashboardTotalOrderRequestGraphDataQueryVariables>(GetDashboardTotalOrderRequestGraphDataDocument, options);
        }
export function useGetDashboardTotalOrderRequestGraphDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDashboardTotalOrderRequestGraphDataQuery, GetDashboardTotalOrderRequestGraphDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardTotalOrderRequestGraphDataQuery, GetDashboardTotalOrderRequestGraphDataQueryVariables>(GetDashboardTotalOrderRequestGraphDataDocument, options);
        }
export type GetDashboardTotalOrderRequestGraphDataQueryHookResult = ReturnType<typeof useGetDashboardTotalOrderRequestGraphDataQuery>;
export type GetDashboardTotalOrderRequestGraphDataLazyQueryHookResult = ReturnType<typeof useGetDashboardTotalOrderRequestGraphDataLazyQuery>;
export type GetDashboardTotalOrderRequestGraphDataSuspenseQueryHookResult = ReturnType<typeof useGetDashboardTotalOrderRequestGraphDataSuspenseQuery>;
export type GetDashboardTotalOrderRequestGraphDataQueryResult = Apollo.QueryResult<GetDashboardTotalOrderRequestGraphDataQuery, GetDashboardTotalOrderRequestGraphDataQueryVariables>;
export const GetCompanyRevenueDocument = gql`
    query getCompanyRevenue($startDate: DateTime!, $endDate: DateTime!) {
  revenueGraph(endDate: $endDate, startDate: $startDate) {
    date
    totalAmountApproved
  }
}
    `;

/**
 * __useGetCompanyRevenueQuery__
 *
 * To run a query within a React component, call `useGetCompanyRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRevenueQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCompanyRevenueQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables> & ({ variables: GetCompanyRevenueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>(GetCompanyRevenueDocument, options);
      }
export function useGetCompanyRevenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>(GetCompanyRevenueDocument, options);
        }
export function useGetCompanyRevenueSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>(GetCompanyRevenueDocument, options);
        }
export type GetCompanyRevenueQueryHookResult = ReturnType<typeof useGetCompanyRevenueQuery>;
export type GetCompanyRevenueLazyQueryHookResult = ReturnType<typeof useGetCompanyRevenueLazyQuery>;
export type GetCompanyRevenueSuspenseQueryHookResult = ReturnType<typeof useGetCompanyRevenueSuspenseQuery>;
export type GetCompanyRevenueQueryResult = Apollo.QueryResult<GetCompanyRevenueQuery, GetCompanyRevenueQueryVariables>;
export const GetCompanySummaryReportsDocument = gql`
    query getCompanySummaryReports($startDate: DateTime!, $endDate: DateTime!) {
  adminSummaryReport(endDate: $endDate, startDate: $startDate) {
    count
    reportType
    total
  }
}
    `;

/**
 * __useGetCompanySummaryReportsQuery__
 *
 * To run a query within a React component, call `useGetCompanySummaryReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySummaryReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySummaryReportsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCompanySummaryReportsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanySummaryReportsQuery, GetCompanySummaryReportsQueryVariables> & ({ variables: GetCompanySummaryReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanySummaryReportsQuery, GetCompanySummaryReportsQueryVariables>(GetCompanySummaryReportsDocument, options);
      }
export function useGetCompanySummaryReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySummaryReportsQuery, GetCompanySummaryReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanySummaryReportsQuery, GetCompanySummaryReportsQueryVariables>(GetCompanySummaryReportsDocument, options);
        }
export function useGetCompanySummaryReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanySummaryReportsQuery, GetCompanySummaryReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanySummaryReportsQuery, GetCompanySummaryReportsQueryVariables>(GetCompanySummaryReportsDocument, options);
        }
export type GetCompanySummaryReportsQueryHookResult = ReturnType<typeof useGetCompanySummaryReportsQuery>;
export type GetCompanySummaryReportsLazyQueryHookResult = ReturnType<typeof useGetCompanySummaryReportsLazyQuery>;
export type GetCompanySummaryReportsSuspenseQueryHookResult = ReturnType<typeof useGetCompanySummaryReportsSuspenseQuery>;
export type GetCompanySummaryReportsQueryResult = Apollo.QueryResult<GetCompanySummaryReportsQuery, GetCompanySummaryReportsQueryVariables>;
export const GetCompanySoldProductsDocument = gql`
    query getCompanySoldProducts($startDate: DateTime!, $endDate: DateTime!) {
  soldProducts(endDate: $endDate, startDate: $startDate) {
    productId
    productTitle
    totalQuantity
    unitOfMeasureId
  }
}
    `;

/**
 * __useGetCompanySoldProductsQuery__
 *
 * To run a query within a React component, call `useGetCompanySoldProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySoldProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySoldProductsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetCompanySoldProductsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanySoldProductsQuery, GetCompanySoldProductsQueryVariables> & ({ variables: GetCompanySoldProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanySoldProductsQuery, GetCompanySoldProductsQueryVariables>(GetCompanySoldProductsDocument, options);
      }
export function useGetCompanySoldProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySoldProductsQuery, GetCompanySoldProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanySoldProductsQuery, GetCompanySoldProductsQueryVariables>(GetCompanySoldProductsDocument, options);
        }
export function useGetCompanySoldProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanySoldProductsQuery, GetCompanySoldProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanySoldProductsQuery, GetCompanySoldProductsQueryVariables>(GetCompanySoldProductsDocument, options);
        }
export type GetCompanySoldProductsQueryHookResult = ReturnType<typeof useGetCompanySoldProductsQuery>;
export type GetCompanySoldProductsLazyQueryHookResult = ReturnType<typeof useGetCompanySoldProductsLazyQuery>;
export type GetCompanySoldProductsSuspenseQueryHookResult = ReturnType<typeof useGetCompanySoldProductsSuspenseQuery>;
export type GetCompanySoldProductsQueryResult = Apollo.QueryResult<GetCompanySoldProductsQuery, GetCompanySoldProductsQueryVariables>;
export const GetUserByEmailDocument = gql`
    query getUserByEmail($email: String!) {
  userByEmail(email: $email) {
    id
    fullName
    userName
    email
    companyId
    locationId
    company {
      countryId
      code
      customerTranscription
    }
    locationId
    location {
      id
      name
      code
      type
      companyId
      company {
        id
        code
        countryId
        name
        customerTranscription
        defaultCurrencyCode
      }
    }
    language {
      lang
      code
      id
    }
    roleId
    role {
      id
      description
    }
    companies {
      id
      code
      name
    }
    locations {
      id
      name
      code
    }
    permissions
  }
}
    `;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables> & ({ variables: GetUserByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
      }
export function useGetUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export function useGetUserByEmailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<typeof useGetUserByEmailLazyQuery>;
export type GetUserByEmailSuspenseQueryHookResult = ReturnType<typeof useGetUserByEmailSuspenseQuery>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetDocumentTypesDocument = gql`
    query getDocumentTypes {
  documentTypes(where: {description: {nin: ["EPayment"]}}) {
    id
    description
  }
}
    `;

/**
 * __useGetDocumentTypesQuery__
 *
 * To run a query within a React component, call `useGetDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
      }
export function useGetDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
        }
export function useGetDocumentTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
        }
export type GetDocumentTypesQueryHookResult = ReturnType<typeof useGetDocumentTypesQuery>;
export type GetDocumentTypesLazyQueryHookResult = ReturnType<typeof useGetDocumentTypesLazyQuery>;
export type GetDocumentTypesSuspenseQueryHookResult = ReturnType<typeof useGetDocumentTypesSuspenseQuery>;
export type GetDocumentTypesQueryResult = Apollo.QueryResult<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>;
export const GetPaymentApprovalStatusesDocument = gql`
    query getPaymentApprovalStatuses {
  paymentApprovalStatus {
    id
    description
  }
}
    `;

/**
 * __useGetPaymentApprovalStatusesQuery__
 *
 * To run a query within a React component, call `useGetPaymentApprovalStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentApprovalStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentApprovalStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentApprovalStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentApprovalStatusesQuery, GetPaymentApprovalStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentApprovalStatusesQuery, GetPaymentApprovalStatusesQueryVariables>(GetPaymentApprovalStatusesDocument, options);
      }
export function useGetPaymentApprovalStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentApprovalStatusesQuery, GetPaymentApprovalStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentApprovalStatusesQuery, GetPaymentApprovalStatusesQueryVariables>(GetPaymentApprovalStatusesDocument, options);
        }
export function useGetPaymentApprovalStatusesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentApprovalStatusesQuery, GetPaymentApprovalStatusesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentApprovalStatusesQuery, GetPaymentApprovalStatusesQueryVariables>(GetPaymentApprovalStatusesDocument, options);
        }
export type GetPaymentApprovalStatusesQueryHookResult = ReturnType<typeof useGetPaymentApprovalStatusesQuery>;
export type GetPaymentApprovalStatusesLazyQueryHookResult = ReturnType<typeof useGetPaymentApprovalStatusesLazyQuery>;
export type GetPaymentApprovalStatusesSuspenseQueryHookResult = ReturnType<typeof useGetPaymentApprovalStatusesSuspenseQuery>;
export type GetPaymentApprovalStatusesQueryResult = Apollo.QueryResult<GetPaymentApprovalStatusesQuery, GetPaymentApprovalStatusesQueryVariables>;
export const GetPaymentApprovalTypesDocument = gql`
    query getPaymentApprovalTypes {
  paymentApprovalType {
    id
    description
  }
}
    `;

/**
 * __useGetPaymentApprovalTypesQuery__
 *
 * To run a query within a React component, call `useGetPaymentApprovalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentApprovalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentApprovalTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentApprovalTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentApprovalTypesQuery, GetPaymentApprovalTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentApprovalTypesQuery, GetPaymentApprovalTypesQueryVariables>(GetPaymentApprovalTypesDocument, options);
      }
export function useGetPaymentApprovalTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentApprovalTypesQuery, GetPaymentApprovalTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentApprovalTypesQuery, GetPaymentApprovalTypesQueryVariables>(GetPaymentApprovalTypesDocument, options);
        }
export function useGetPaymentApprovalTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentApprovalTypesQuery, GetPaymentApprovalTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentApprovalTypesQuery, GetPaymentApprovalTypesQueryVariables>(GetPaymentApprovalTypesDocument, options);
        }
export type GetPaymentApprovalTypesQueryHookResult = ReturnType<typeof useGetPaymentApprovalTypesQuery>;
export type GetPaymentApprovalTypesLazyQueryHookResult = ReturnType<typeof useGetPaymentApprovalTypesLazyQuery>;
export type GetPaymentApprovalTypesSuspenseQueryHookResult = ReturnType<typeof useGetPaymentApprovalTypesSuspenseQuery>;
export type GetPaymentApprovalTypesQueryResult = Apollo.QueryResult<GetPaymentApprovalTypesQuery, GetPaymentApprovalTypesQueryVariables>;
export const GetCustomerRolesDocument = gql`
    query getCustomerRoles {
  customerRoles {
    id
    description
    isInternal
  }
}
    `;

/**
 * __useGetCustomerRolesQuery__
 *
 * To run a query within a React component, call `useGetCustomerRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerRolesQuery, GetCustomerRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerRolesQuery, GetCustomerRolesQueryVariables>(GetCustomerRolesDocument, options);
      }
export function useGetCustomerRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerRolesQuery, GetCustomerRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerRolesQuery, GetCustomerRolesQueryVariables>(GetCustomerRolesDocument, options);
        }
export function useGetCustomerRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCustomerRolesQuery, GetCustomerRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomerRolesQuery, GetCustomerRolesQueryVariables>(GetCustomerRolesDocument, options);
        }
export type GetCustomerRolesQueryHookResult = ReturnType<typeof useGetCustomerRolesQuery>;
export type GetCustomerRolesLazyQueryHookResult = ReturnType<typeof useGetCustomerRolesLazyQuery>;
export type GetCustomerRolesSuspenseQueryHookResult = ReturnType<typeof useGetCustomerRolesSuspenseQuery>;
export type GetCustomerRolesQueryResult = Apollo.QueryResult<GetCustomerRolesQuery, GetCustomerRolesQueryVariables>;
export const GetActiveBanksDocument = gql`
    query getActiveBanks($countryId: Int!) {
  banks(where: {and: [{countryId: {eq: $countryId}}, {isActive: {eq: true}}]}) {
    nodes {
      id
      name
      description
      country {
        id
        name
      }
      createdAt
      updatedAt
      code
      isActive
    }
  }
}
    `;

/**
 * __useGetActiveBanksQuery__
 *
 * To run a query within a React component, call `useGetActiveBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveBanksQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetActiveBanksQuery(baseOptions: Apollo.QueryHookOptions<GetActiveBanksQuery, GetActiveBanksQueryVariables> & ({ variables: GetActiveBanksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveBanksQuery, GetActiveBanksQueryVariables>(GetActiveBanksDocument, options);
      }
export function useGetActiveBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveBanksQuery, GetActiveBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveBanksQuery, GetActiveBanksQueryVariables>(GetActiveBanksDocument, options);
        }
export function useGetActiveBanksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetActiveBanksQuery, GetActiveBanksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActiveBanksQuery, GetActiveBanksQueryVariables>(GetActiveBanksDocument, options);
        }
export type GetActiveBanksQueryHookResult = ReturnType<typeof useGetActiveBanksQuery>;
export type GetActiveBanksLazyQueryHookResult = ReturnType<typeof useGetActiveBanksLazyQuery>;
export type GetActiveBanksSuspenseQueryHookResult = ReturnType<typeof useGetActiveBanksSuspenseQuery>;
export type GetActiveBanksQueryResult = Apollo.QueryResult<GetActiveBanksQuery, GetActiveBanksQueryVariables>;
export const GetActiveBankBranchesDocument = gql`
    query getActiveBankBranches($bankId: Int!) {
  bankBranches(where: {and: [{bankId: {eq: $bankId}}, {isActive: {eq: true}}]}) {
    nodes {
      id
      name
      description
      createdAt
      updatedAt
      code
      isActive
    }
  }
}
    `;

/**
 * __useGetActiveBankBranchesQuery__
 *
 * To run a query within a React component, call `useGetActiveBankBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveBankBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveBankBranchesQuery({
 *   variables: {
 *      bankId: // value for 'bankId'
 *   },
 * });
 */
export function useGetActiveBankBranchesQuery(baseOptions: Apollo.QueryHookOptions<GetActiveBankBranchesQuery, GetActiveBankBranchesQueryVariables> & ({ variables: GetActiveBankBranchesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveBankBranchesQuery, GetActiveBankBranchesQueryVariables>(GetActiveBankBranchesDocument, options);
      }
export function useGetActiveBankBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveBankBranchesQuery, GetActiveBankBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveBankBranchesQuery, GetActiveBankBranchesQueryVariables>(GetActiveBankBranchesDocument, options);
        }
export function useGetActiveBankBranchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetActiveBankBranchesQuery, GetActiveBankBranchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActiveBankBranchesQuery, GetActiveBankBranchesQueryVariables>(GetActiveBankBranchesDocument, options);
        }
export type GetActiveBankBranchesQueryHookResult = ReturnType<typeof useGetActiveBankBranchesQuery>;
export type GetActiveBankBranchesLazyQueryHookResult = ReturnType<typeof useGetActiveBankBranchesLazyQuery>;
export type GetActiveBankBranchesSuspenseQueryHookResult = ReturnType<typeof useGetActiveBankBranchesSuspenseQuery>;
export type GetActiveBankBranchesQueryResult = Apollo.QueryResult<GetActiveBankBranchesQuery, GetActiveBankBranchesQueryVariables>;
export const UpsertUserDefaultLanguagemutationDocument = gql`
    mutation upsertUserDefaultLanguagemutation($langId: UpdateUserDefaultLanguageInput!) {
  updateUserDefaultLanguage(input: $langId) {
    __typename
  }
}
    `;
export type UpsertUserDefaultLanguagemutationMutationFn = Apollo.MutationFunction<UpsertUserDefaultLanguagemutationMutation, UpsertUserDefaultLanguagemutationMutationVariables>;

/**
 * __useUpsertUserDefaultLanguagemutationMutation__
 *
 * To run a mutation, you first call `useUpsertUserDefaultLanguagemutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserDefaultLanguagemutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserDefaultLanguagemutationMutation, { data, loading, error }] = useUpsertUserDefaultLanguagemutationMutation({
 *   variables: {
 *      langId: // value for 'langId'
 *   },
 * });
 */
export function useUpsertUserDefaultLanguagemutationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUserDefaultLanguagemutationMutation, UpsertUserDefaultLanguagemutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUserDefaultLanguagemutationMutation, UpsertUserDefaultLanguagemutationMutationVariables>(UpsertUserDefaultLanguagemutationDocument, options);
      }
export type UpsertUserDefaultLanguagemutationMutationHookResult = ReturnType<typeof useUpsertUserDefaultLanguagemutationMutation>;
export type UpsertUserDefaultLanguagemutationMutationResult = Apollo.MutationResult<UpsertUserDefaultLanguagemutationMutation>;
export type UpsertUserDefaultLanguagemutationMutationOptions = Apollo.BaseMutationOptions<UpsertUserDefaultLanguagemutationMutation, UpsertUserDefaultLanguagemutationMutationVariables>;
export const GetLanguagesDocument = gql`
    query getLanguages {
  languages {
    lang
    code
    id
  }
}
    `;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
      }
export function useGetLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export function useGetLanguagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesSuspenseQueryHookResult = ReturnType<typeof useGetLanguagesSuspenseQuery>;
export type GetLanguagesQueryResult = Apollo.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;
export const GetVehiclesLookupByVehicleNumberDocument = gql`
    query getVehiclesLookupByVehicleNumber($searchString: String!, $countryIds: [Int!]!, $vehcileTypeId: Int!) {
  vehicles(
    where: {countryId: {in: $countryIds}, vehicleTypeId: {eq: $vehcileTypeId}, or: [{vehicleNo: {contains: $searchString}}, {supplier: {or: [{supplierName: {contains: $searchString}}, {supplierCode: {contains: $searchString}}]}}], isActive: {eq: true}}
    first: 20
  ) {
    nodes {
      id
      vehicleNo
      insuranceExpiryDate
      insurancePolicyNo
      trackingRefNo
      trackingPhoneNo
      transporterCode
      owner
      isBanned
      isActive
      tare
      maxWeight
      createdAt
      supplier {
        supplierName
        supplierCode
      }
    }
  }
}
    `;

/**
 * __useGetVehiclesLookupByVehicleNumberQuery__
 *
 * To run a query within a React component, call `useGetVehiclesLookupByVehicleNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesLookupByVehicleNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesLookupByVehicleNumberQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      countryIds: // value for 'countryIds'
 *      vehcileTypeId: // value for 'vehcileTypeId'
 *   },
 * });
 */
export function useGetVehiclesLookupByVehicleNumberQuery(baseOptions: Apollo.QueryHookOptions<GetVehiclesLookupByVehicleNumberQuery, GetVehiclesLookupByVehicleNumberQueryVariables> & ({ variables: GetVehiclesLookupByVehicleNumberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehiclesLookupByVehicleNumberQuery, GetVehiclesLookupByVehicleNumberQueryVariables>(GetVehiclesLookupByVehicleNumberDocument, options);
      }
export function useGetVehiclesLookupByVehicleNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehiclesLookupByVehicleNumberQuery, GetVehiclesLookupByVehicleNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehiclesLookupByVehicleNumberQuery, GetVehiclesLookupByVehicleNumberQueryVariables>(GetVehiclesLookupByVehicleNumberDocument, options);
        }
export function useGetVehiclesLookupByVehicleNumberSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVehiclesLookupByVehicleNumberQuery, GetVehiclesLookupByVehicleNumberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVehiclesLookupByVehicleNumberQuery, GetVehiclesLookupByVehicleNumberQueryVariables>(GetVehiclesLookupByVehicleNumberDocument, options);
        }
export type GetVehiclesLookupByVehicleNumberQueryHookResult = ReturnType<typeof useGetVehiclesLookupByVehicleNumberQuery>;
export type GetVehiclesLookupByVehicleNumberLazyQueryHookResult = ReturnType<typeof useGetVehiclesLookupByVehicleNumberLazyQuery>;
export type GetVehiclesLookupByVehicleNumberSuspenseQueryHookResult = ReturnType<typeof useGetVehiclesLookupByVehicleNumberSuspenseQuery>;
export type GetVehiclesLookupByVehicleNumberQueryResult = Apollo.QueryResult<GetVehiclesLookupByVehicleNumberQuery, GetVehiclesLookupByVehicleNumberQueryVariables>;
export const UpsertUserDeviceTokenDocument = gql`
    mutation upsertUserDeviceToken($token: String!) {
  upsertUserDevice(input: {token: $token, userDeviceId: $token}) {
    userDevice {
      user {
        userName
      }
    }
  }
}
    `;
export type UpsertUserDeviceTokenMutationFn = Apollo.MutationFunction<UpsertUserDeviceTokenMutation, UpsertUserDeviceTokenMutationVariables>;

/**
 * __useUpsertUserDeviceTokenMutation__
 *
 * To run a mutation, you first call `useUpsertUserDeviceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserDeviceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserDeviceTokenMutation, { data, loading, error }] = useUpsertUserDeviceTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpsertUserDeviceTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUserDeviceTokenMutation, UpsertUserDeviceTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUserDeviceTokenMutation, UpsertUserDeviceTokenMutationVariables>(UpsertUserDeviceTokenDocument, options);
      }
export type UpsertUserDeviceTokenMutationHookResult = ReturnType<typeof useUpsertUserDeviceTokenMutation>;
export type UpsertUserDeviceTokenMutationResult = Apollo.MutationResult<UpsertUserDeviceTokenMutation>;
export type UpsertUserDeviceTokenMutationOptions = Apollo.BaseMutationOptions<UpsertUserDeviceTokenMutation, UpsertUserDeviceTokenMutationVariables>;
export const GetUserNotificationsDocument = gql`
    query getUserNotifications {
  userNotifications(where: {notificationStatusId: {lte: 2}}) {
    nodes {
      id
      notificationStatusId
      notificationStatus {
        id
        description
        translationKey
      }
      notification {
        id
        title
        body
        componentId
        componentName
        notificationTypeId
        notificationType {
          id
          description
          translationKey
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetUserNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
      }
export function useGetUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
        }
export function useGetUserNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
        }
export type GetUserNotificationsQueryHookResult = ReturnType<typeof useGetUserNotificationsQuery>;
export type GetUserNotificationsLazyQueryHookResult = ReturnType<typeof useGetUserNotificationsLazyQuery>;
export type GetUserNotificationsSuspenseQueryHookResult = ReturnType<typeof useGetUserNotificationsSuspenseQuery>;
export type GetUserNotificationsQueryResult = Apollo.QueryResult<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>;
export const GetPaymentIdByPaymentNumberDocument = gql`
    query getPaymentIdByPaymentNumber($paymentNumber: String!) {
  payments(where: {paymentReference: {eq: $paymentNumber}}) {
    nodes {
      id
    }
  }
}
    `;

/**
 * __useGetPaymentIdByPaymentNumberQuery__
 *
 * To run a query within a React component, call `useGetPaymentIdByPaymentNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentIdByPaymentNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentIdByPaymentNumberQuery({
 *   variables: {
 *      paymentNumber: // value for 'paymentNumber'
 *   },
 * });
 */
export function useGetPaymentIdByPaymentNumberQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentIdByPaymentNumberQuery, GetPaymentIdByPaymentNumberQueryVariables> & ({ variables: GetPaymentIdByPaymentNumberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentIdByPaymentNumberQuery, GetPaymentIdByPaymentNumberQueryVariables>(GetPaymentIdByPaymentNumberDocument, options);
      }
export function useGetPaymentIdByPaymentNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentIdByPaymentNumberQuery, GetPaymentIdByPaymentNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentIdByPaymentNumberQuery, GetPaymentIdByPaymentNumberQueryVariables>(GetPaymentIdByPaymentNumberDocument, options);
        }
export function useGetPaymentIdByPaymentNumberSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentIdByPaymentNumberQuery, GetPaymentIdByPaymentNumberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentIdByPaymentNumberQuery, GetPaymentIdByPaymentNumberQueryVariables>(GetPaymentIdByPaymentNumberDocument, options);
        }
export type GetPaymentIdByPaymentNumberQueryHookResult = ReturnType<typeof useGetPaymentIdByPaymentNumberQuery>;
export type GetPaymentIdByPaymentNumberLazyQueryHookResult = ReturnType<typeof useGetPaymentIdByPaymentNumberLazyQuery>;
export type GetPaymentIdByPaymentNumberSuspenseQueryHookResult = ReturnType<typeof useGetPaymentIdByPaymentNumberSuspenseQuery>;
export type GetPaymentIdByPaymentNumberQueryResult = Apollo.QueryResult<GetPaymentIdByPaymentNumberQuery, GetPaymentIdByPaymentNumberQueryVariables>;
export const MarkNotificationAsReadDocument = gql`
    mutation markNotificationAsRead($notificationId: Int!) {
  readUserNotification(input: {notificationId: $notificationId}) {
    notificationUser {
      userId
    }
  }
}
    `;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument, options);
      }
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const MarkAllNotificationsAsReadDocument = gql`
    mutation markAllNotificationsAsRead {
  readAllUserNotification {
    boolean
  }
}
    `;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>(MarkAllNotificationsAsReadDocument, options);
      }
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationResult = Apollo.MutationResult<MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>;
export const GetDescriptionValuesDocument = gql`
    query GetDescriptionValues($searchString: String!) {
  descriptionValues(where: {description: {contains: $searchString}}) {
    id
    description
  }
}
    `;

/**
 * __useGetDescriptionValuesQuery__
 *
 * To run a query within a React component, call `useGetDescriptionValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDescriptionValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDescriptionValuesQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useGetDescriptionValuesQuery(baseOptions: Apollo.QueryHookOptions<GetDescriptionValuesQuery, GetDescriptionValuesQueryVariables> & ({ variables: GetDescriptionValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDescriptionValuesQuery, GetDescriptionValuesQueryVariables>(GetDescriptionValuesDocument, options);
      }
export function useGetDescriptionValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDescriptionValuesQuery, GetDescriptionValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDescriptionValuesQuery, GetDescriptionValuesQueryVariables>(GetDescriptionValuesDocument, options);
        }
export function useGetDescriptionValuesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDescriptionValuesQuery, GetDescriptionValuesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDescriptionValuesQuery, GetDescriptionValuesQueryVariables>(GetDescriptionValuesDocument, options);
        }
export type GetDescriptionValuesQueryHookResult = ReturnType<typeof useGetDescriptionValuesQuery>;
export type GetDescriptionValuesLazyQueryHookResult = ReturnType<typeof useGetDescriptionValuesLazyQuery>;
export type GetDescriptionValuesSuspenseQueryHookResult = ReturnType<typeof useGetDescriptionValuesSuspenseQuery>;
export type GetDescriptionValuesQueryResult = Apollo.QueryResult<GetDescriptionValuesQuery, GetDescriptionValuesQueryVariables>;
export const GetPaginatedCompaniesDocument = gql`
    query getPaginatedCompanies($name: String!, $code: String!, $cursor: String, $take: Int = 10, $countryId: Int!) {
  companies(
    countryId: $countryId
    where: {and: [{name: {startsWith: $name}}, {code: {startsWith: $code}}]}
    after: $cursor
    first: $take
    order: {id: ASC}
  ) {
    edges {
      cursor
    }
    totalCount
    nodes {
      id
      name
      code
      eamOrganization
      sunBusinessUnit
      countryId
      country {
        id
        name
      }
      updatedAt
      createdAt
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPaginatedCompaniesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedCompaniesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetPaginatedCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables> & ({ variables: GetPaginatedCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>(GetPaginatedCompaniesDocument, options);
      }
export function useGetPaginatedCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>(GetPaginatedCompaniesDocument, options);
        }
export function useGetPaginatedCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>(GetPaginatedCompaniesDocument, options);
        }
export type GetPaginatedCompaniesQueryHookResult = ReturnType<typeof useGetPaginatedCompaniesQuery>;
export type GetPaginatedCompaniesLazyQueryHookResult = ReturnType<typeof useGetPaginatedCompaniesLazyQuery>;
export type GetPaginatedCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedCompaniesSuspenseQuery>;
export type GetPaginatedCompaniesQueryResult = Apollo.QueryResult<GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>;
export const GetUserAccountsByCompanyIdDocument = gql`
    query GetUserAccountsByCompanyId($companyId: Int!, $fullName: String, $cursor: String, $take: Int = 10) {
  usersByCompany(
    companyId: $companyId
    where: {fullName: {contains: $fullName}}
    after: $cursor
    first: $take
    order: {id: ASC}
  ) {
    totalCount
    nodes {
      company {
        id
        name
        country {
          id
          name
        }
      }
      location {
        id
        name
      }
      createdAt
      customerId
      customer {
        id
        customerName
        active
        customerCode
      }
      role {
        id
        description
      }
      email
      fullName
      id
      isActive
      languageId
      locationId
      permissions
      phoneNumber
      termsAndConditionId
      updatedAt
      userName
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useGetUserAccountsByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetUserAccountsByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountsByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountsByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      fullName: // value for 'fullName'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetUserAccountsByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserAccountsByCompanyIdQuery, GetUserAccountsByCompanyIdQueryVariables> & ({ variables: GetUserAccountsByCompanyIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAccountsByCompanyIdQuery, GetUserAccountsByCompanyIdQueryVariables>(GetUserAccountsByCompanyIdDocument, options);
      }
export function useGetUserAccountsByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAccountsByCompanyIdQuery, GetUserAccountsByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAccountsByCompanyIdQuery, GetUserAccountsByCompanyIdQueryVariables>(GetUserAccountsByCompanyIdDocument, options);
        }
export function useGetUserAccountsByCompanyIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserAccountsByCompanyIdQuery, GetUserAccountsByCompanyIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserAccountsByCompanyIdQuery, GetUserAccountsByCompanyIdQueryVariables>(GetUserAccountsByCompanyIdDocument, options);
        }
export type GetUserAccountsByCompanyIdQueryHookResult = ReturnType<typeof useGetUserAccountsByCompanyIdQuery>;
export type GetUserAccountsByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetUserAccountsByCompanyIdLazyQuery>;
export type GetUserAccountsByCompanyIdSuspenseQueryHookResult = ReturnType<typeof useGetUserAccountsByCompanyIdSuspenseQuery>;
export type GetUserAccountsByCompanyIdQueryResult = Apollo.QueryResult<GetUserAccountsByCompanyIdQuery, GetUserAccountsByCompanyIdQueryVariables>;
export const CompaniesCountDocument = gql`
    query companiesCount($countryId: Int!) {
  companies(countryId: $countryId) {
    totalCount
  }
}
    `;

/**
 * __useCompaniesCountQuery__
 *
 * To run a query within a React component, call `useCompaniesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesCountQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useCompaniesCountQuery(baseOptions: Apollo.QueryHookOptions<CompaniesCountQuery, CompaniesCountQueryVariables> & ({ variables: CompaniesCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesCountQuery, CompaniesCountQueryVariables>(CompaniesCountDocument, options);
      }
export function useCompaniesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesCountQuery, CompaniesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesCountQuery, CompaniesCountQueryVariables>(CompaniesCountDocument, options);
        }
export function useCompaniesCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompaniesCountQuery, CompaniesCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesCountQuery, CompaniesCountQueryVariables>(CompaniesCountDocument, options);
        }
export type CompaniesCountQueryHookResult = ReturnType<typeof useCompaniesCountQuery>;
export type CompaniesCountLazyQueryHookResult = ReturnType<typeof useCompaniesCountLazyQuery>;
export type CompaniesCountSuspenseQueryHookResult = ReturnType<typeof useCompaniesCountSuspenseQuery>;
export type CompaniesCountQueryResult = Apollo.QueryResult<CompaniesCountQuery, CompaniesCountQueryVariables>;
export const GetCompaniesDocument = gql`
    query getCompanies($countryId: Int!) {
  companies(first: 20, countryId: $countryId) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables> & ({ variables: GetCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export function useGetCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetCompaniesSuspenseQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const UpsertCompanyDocument = gql`
    mutation UpsertCompany($input: UpsertCompanyInput!) {
  upsertCompany(input: $input) {
    company {
      id
    }
  }
}
    `;
export type UpsertCompanyMutationFn = Apollo.MutationFunction<UpsertCompanyMutation, UpsertCompanyMutationVariables>;

/**
 * __useUpsertCompanyMutation__
 *
 * To run a mutation, you first call `useUpsertCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCompanyMutation, { data, loading, error }] = useUpsertCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCompanyMutation, UpsertCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCompanyMutation, UpsertCompanyMutationVariables>(UpsertCompanyDocument, options);
      }
export type UpsertCompanyMutationHookResult = ReturnType<typeof useUpsertCompanyMutation>;
export type UpsertCompanyMutationResult = Apollo.MutationResult<UpsertCompanyMutation>;
export type UpsertCompanyMutationOptions = Apollo.BaseMutationOptions<UpsertCompanyMutation, UpsertCompanyMutationVariables>;
export const UpdateUserActiveDocument = gql`
    mutation updateUserActive($input: UpdateUserActiveInput!) {
  updateUserActive(input: $input) {
    boolean
  }
}
    `;
export type UpdateUserActiveMutationFn = Apollo.MutationFunction<UpdateUserActiveMutation, UpdateUserActiveMutationVariables>;

/**
 * __useUpdateUserActiveMutation__
 *
 * To run a mutation, you first call `useUpdateUserActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserActiveMutation, { data, loading, error }] = useUpdateUserActiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserActiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserActiveMutation, UpdateUserActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserActiveMutation, UpdateUserActiveMutationVariables>(UpdateUserActiveDocument, options);
      }
export type UpdateUserActiveMutationHookResult = ReturnType<typeof useUpdateUserActiveMutation>;
export type UpdateUserActiveMutationResult = Apollo.MutationResult<UpdateUserActiveMutation>;
export type UpdateUserActiveMutationOptions = Apollo.BaseMutationOptions<UpdateUserActiveMutation, UpdateUserActiveMutationVariables>;
export const UpsertBankDataDocument = gql`
    mutation upsertBankData($input: UpsertBankInput!) {
  upsertBank(input: $input) {
    bank {
      id
    }
  }
}
    `;
export type UpsertBankDataMutationFn = Apollo.MutationFunction<UpsertBankDataMutation, UpsertBankDataMutationVariables>;

/**
 * __useUpsertBankDataMutation__
 *
 * To run a mutation, you first call `useUpsertBankDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBankDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBankDataMutation, { data, loading, error }] = useUpsertBankDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertBankDataMutation(baseOptions?: Apollo.MutationHookOptions<UpsertBankDataMutation, UpsertBankDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertBankDataMutation, UpsertBankDataMutationVariables>(UpsertBankDataDocument, options);
      }
export type UpsertBankDataMutationHookResult = ReturnType<typeof useUpsertBankDataMutation>;
export type UpsertBankDataMutationResult = Apollo.MutationResult<UpsertBankDataMutation>;
export type UpsertBankDataMutationOptions = Apollo.BaseMutationOptions<UpsertBankDataMutation, UpsertBankDataMutationVariables>;
export const GetPaginatedBanksByCountryIdDocument = gql`
    query getPaginatedBanksByCountryId($countryId: Int!, $name: String!, $code: String!, $cursor: Int, $take: Int = 50) {
  banks(
    where: {countryId: {eq: $countryId}, or: [{code: {startsWith: $code}}, {name: {startsWith: $name}}]}
    first: $take
    last: $cursor
    order: {name: ASC}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
    }
    totalCount
    nodes {
      id
      code
      name
      isActive
      countryId
      description
      bankBranches {
        id
        description
        name
        code
      }
      country {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetPaginatedBanksByCountryIdQuery__
 *
 * To run a query within a React component, call `useGetPaginatedBanksByCountryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedBanksByCountryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedBanksByCountryIdQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPaginatedBanksByCountryIdQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedBanksByCountryIdQuery, GetPaginatedBanksByCountryIdQueryVariables> & ({ variables: GetPaginatedBanksByCountryIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedBanksByCountryIdQuery, GetPaginatedBanksByCountryIdQueryVariables>(GetPaginatedBanksByCountryIdDocument, options);
      }
export function useGetPaginatedBanksByCountryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedBanksByCountryIdQuery, GetPaginatedBanksByCountryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedBanksByCountryIdQuery, GetPaginatedBanksByCountryIdQueryVariables>(GetPaginatedBanksByCountryIdDocument, options);
        }
export function useGetPaginatedBanksByCountryIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedBanksByCountryIdQuery, GetPaginatedBanksByCountryIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedBanksByCountryIdQuery, GetPaginatedBanksByCountryIdQueryVariables>(GetPaginatedBanksByCountryIdDocument, options);
        }
export type GetPaginatedBanksByCountryIdQueryHookResult = ReturnType<typeof useGetPaginatedBanksByCountryIdQuery>;
export type GetPaginatedBanksByCountryIdLazyQueryHookResult = ReturnType<typeof useGetPaginatedBanksByCountryIdLazyQuery>;
export type GetPaginatedBanksByCountryIdSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedBanksByCountryIdSuspenseQuery>;
export type GetPaginatedBanksByCountryIdQueryResult = Apollo.QueryResult<GetPaginatedBanksByCountryIdQuery, GetPaginatedBanksByCountryIdQueryVariables>;
export const UpsertBankBranchDocument = gql`
    mutation upsertBankBranch($input: UpsertBankBranchInput!) {
  upsertBankBranch(input: $input) {
    bankBranch {
      id
      code
      description
      name
    }
  }
}
    `;
export type UpsertBankBranchMutationFn = Apollo.MutationFunction<UpsertBankBranchMutation, UpsertBankBranchMutationVariables>;

/**
 * __useUpsertBankBranchMutation__
 *
 * To run a mutation, you first call `useUpsertBankBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBankBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBankBranchMutation, { data, loading, error }] = useUpsertBankBranchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertBankBranchMutation(baseOptions?: Apollo.MutationHookOptions<UpsertBankBranchMutation, UpsertBankBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertBankBranchMutation, UpsertBankBranchMutationVariables>(UpsertBankBranchDocument, options);
      }
export type UpsertBankBranchMutationHookResult = ReturnType<typeof useUpsertBankBranchMutation>;
export type UpsertBankBranchMutationResult = Apollo.MutationResult<UpsertBankBranchMutation>;
export type UpsertBankBranchMutationOptions = Apollo.BaseMutationOptions<UpsertBankBranchMutation, UpsertBankBranchMutationVariables>;
export const GetCompanyDocument = gql`
    query getCompany($id: Int!, $countryId: Int!) {
  companies(countryId: $countryId, where: {id: {eq: $id}}) {
    nodes {
      id
      name
      code
      eamOrganization
      sunBusinessUnit
      countryId
      country {
        id
        name
      }
      customerTranscription
      updatedAt
      createdAt
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables> & ({ variables: GetCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export function useGetCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanySuspenseQueryHookResult = ReturnType<typeof useGetCompanySuspenseQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetFilteredCountriesDocument = gql`
    query getFilteredCountries($countryName: String! = "", $adGroupName: String! = "") {
  countries(
    where: {and: [{name: {startsWith: $countryName}}, {adGroupName: {startsWith: $adGroupName}}]}
  ) {
    name
    cluster
    id
    callingCode
    alpha2
    alpha3
    utcOffset
    adGroupName
  }
}
    `;

/**
 * __useGetFilteredCountriesQuery__
 *
 * To run a query within a React component, call `useGetFilteredCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredCountriesQuery({
 *   variables: {
 *      countryName: // value for 'countryName'
 *      adGroupName: // value for 'adGroupName'
 *   },
 * });
 */
export function useGetFilteredCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetFilteredCountriesQuery, GetFilteredCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilteredCountriesQuery, GetFilteredCountriesQueryVariables>(GetFilteredCountriesDocument, options);
      }
export function useGetFilteredCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredCountriesQuery, GetFilteredCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilteredCountriesQuery, GetFilteredCountriesQueryVariables>(GetFilteredCountriesDocument, options);
        }
export function useGetFilteredCountriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFilteredCountriesQuery, GetFilteredCountriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFilteredCountriesQuery, GetFilteredCountriesQueryVariables>(GetFilteredCountriesDocument, options);
        }
export type GetFilteredCountriesQueryHookResult = ReturnType<typeof useGetFilteredCountriesQuery>;
export type GetFilteredCountriesLazyQueryHookResult = ReturnType<typeof useGetFilteredCountriesLazyQuery>;
export type GetFilteredCountriesSuspenseQueryHookResult = ReturnType<typeof useGetFilteredCountriesSuspenseQuery>;
export type GetFilteredCountriesQueryResult = Apollo.QueryResult<GetFilteredCountriesQuery, GetFilteredCountriesQueryVariables>;
export const GetCountriesDocument = gql`
    query getCountries {
  countries {
    name
    cluster
    id
    callingCode
    alpha2
    alpha3
    utcOffset
    adGroupName
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export function useGetCountriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesSuspenseQueryHookResult = ReturnType<typeof useGetCountriesSuspenseQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetCustomersByCompanyDocument = gql`
    query getCustomersByCompany($companyId: Int!, $customerName: String! = "", $customerCode: String! = "", $cursor: String, $take: Int = 10) {
  customers(
    where: {and: [{companyId: {eq: $companyId}}, {customerName: {startsWith: $customerName}}, {customerCode: {startsWith: $customerCode}}]}
    after: $cursor
    first: $take
    order: {id: ASC}
  ) {
    nodes {
      id
      customerCode
      customerName
      phoneNumber
      active
      altPhoneNumber
      company {
        id
        name
        code
      }
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetCustomersByCompanyQuery__
 *
 * To run a query within a React component, call `useGetCustomersByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customerName: // value for 'customerName'
 *      customerCode: // value for 'customerCode'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetCustomersByCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersByCompanyQuery, GetCustomersByCompanyQueryVariables> & ({ variables: GetCustomersByCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersByCompanyQuery, GetCustomersByCompanyQueryVariables>(GetCustomersByCompanyDocument, options);
      }
export function useGetCustomersByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersByCompanyQuery, GetCustomersByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersByCompanyQuery, GetCustomersByCompanyQueryVariables>(GetCustomersByCompanyDocument, options);
        }
export function useGetCustomersByCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCustomersByCompanyQuery, GetCustomersByCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomersByCompanyQuery, GetCustomersByCompanyQueryVariables>(GetCustomersByCompanyDocument, options);
        }
export type GetCustomersByCompanyQueryHookResult = ReturnType<typeof useGetCustomersByCompanyQuery>;
export type GetCustomersByCompanyLazyQueryHookResult = ReturnType<typeof useGetCustomersByCompanyLazyQuery>;
export type GetCustomersByCompanySuspenseQueryHookResult = ReturnType<typeof useGetCustomersByCompanySuspenseQuery>;
export type GetCustomersByCompanyQueryResult = Apollo.QueryResult<GetCustomersByCompanyQuery, GetCustomersByCompanyQueryVariables>;
export const GetCustomersByCompanyIdDocument = gql`
    query getCustomersByCompanyId($companyId: Int!, $search: String, $cursor: String, $take: Int = 10) {
  customers(
    where: {companyId: {eq: $companyId}, or: [{customerName: {startsWith: $search}}, {customerCode: {startsWith: $search}}]}
    after: $cursor
    first: $take
    order: {id: ASC}
  ) {
    nodes {
      id
      customerCode
      customerName
      phoneNumber
      active
      email
      altPhoneNumber
      company {
        id
        name
        code
      }
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetCustomersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCustomersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      search: // value for 'search'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetCustomersByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersByCompanyIdQuery, GetCustomersByCompanyIdQueryVariables> & ({ variables: GetCustomersByCompanyIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersByCompanyIdQuery, GetCustomersByCompanyIdQueryVariables>(GetCustomersByCompanyIdDocument, options);
      }
export function useGetCustomersByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersByCompanyIdQuery, GetCustomersByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersByCompanyIdQuery, GetCustomersByCompanyIdQueryVariables>(GetCustomersByCompanyIdDocument, options);
        }
export function useGetCustomersByCompanyIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCustomersByCompanyIdQuery, GetCustomersByCompanyIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomersByCompanyIdQuery, GetCustomersByCompanyIdQueryVariables>(GetCustomersByCompanyIdDocument, options);
        }
export type GetCustomersByCompanyIdQueryHookResult = ReturnType<typeof useGetCustomersByCompanyIdQuery>;
export type GetCustomersByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetCustomersByCompanyIdLazyQuery>;
export type GetCustomersByCompanyIdSuspenseQueryHookResult = ReturnType<typeof useGetCustomersByCompanyIdSuspenseQuery>;
export type GetCustomersByCompanyIdQueryResult = Apollo.QueryResult<GetCustomersByCompanyIdQuery, GetCustomersByCompanyIdQueryVariables>;
export const GetCustomerByIdDocument = gql`
    query GetCustomerById($customerId: Int!) {
  customerById(id: $customerId) {
    active
    altPhoneNumber
    companyId
    createdAt
    customerCode
    customerName
    customerSegmentId
    customerSegment {
      name
      id
      name
    }
    email
    id
    phoneNumber
    updatedAt
    company {
      name
    }
    vehicles {
      id
      vehicleNo
      isActive
      isBanned
      owner
      tare
      capacity
      maxWeight
      eamId
      trackingRefNo
      supplierId
    }
  }
}
    `;

/**
 * __useGetCustomerByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByIdQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables> & ({ variables: GetCustomerByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
      }
export function useGetCustomerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
        }
export function useGetCustomerByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
        }
export type GetCustomerByIdQueryHookResult = ReturnType<typeof useGetCustomerByIdQuery>;
export type GetCustomerByIdLazyQueryHookResult = ReturnType<typeof useGetCustomerByIdLazyQuery>;
export type GetCustomerByIdSuspenseQueryHookResult = ReturnType<typeof useGetCustomerByIdSuspenseQuery>;
export type GetCustomerByIdQueryResult = Apollo.QueryResult<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>;
export const GetCustomerAccountUsersDocument = gql`
    query GetCustomerAccountUsers($customerId: Int!) {
  customerAccountUsers(customerId: $customerId) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    nodes {
      id
      userName
      fullName
      email
      companyId
      phoneNumber
      roleId
      role {
        id
        description
      }
      termsAndCondition {
        versionId
      }
      createdAt
      isActive
    }
  }
}
    `;

/**
 * __useGetCustomerAccountUsersQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountUsersQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerAccountUsersQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAccountUsersQuery, GetCustomerAccountUsersQueryVariables> & ({ variables: GetCustomerAccountUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAccountUsersQuery, GetCustomerAccountUsersQueryVariables>(GetCustomerAccountUsersDocument, options);
      }
export function useGetCustomerAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAccountUsersQuery, GetCustomerAccountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAccountUsersQuery, GetCustomerAccountUsersQueryVariables>(GetCustomerAccountUsersDocument, options);
        }
export function useGetCustomerAccountUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCustomerAccountUsersQuery, GetCustomerAccountUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomerAccountUsersQuery, GetCustomerAccountUsersQueryVariables>(GetCustomerAccountUsersDocument, options);
        }
export type GetCustomerAccountUsersQueryHookResult = ReturnType<typeof useGetCustomerAccountUsersQuery>;
export type GetCustomerAccountUsersLazyQueryHookResult = ReturnType<typeof useGetCustomerAccountUsersLazyQuery>;
export type GetCustomerAccountUsersSuspenseQueryHookResult = ReturnType<typeof useGetCustomerAccountUsersSuspenseQuery>;
export type GetCustomerAccountUsersQueryResult = Apollo.QueryResult<GetCustomerAccountUsersQuery, GetCustomerAccountUsersQueryVariables>;
export const GetUsersByCompanyIdDocument = gql`
    query GetUsersByCompanyId($companyId: Int!, $search: String, $cursor: String, $take: Int = 10) {
  usersByCompany(
    companyId: $companyId
    where: {fullName: {contains: $search}}
    after: $cursor
    first: $take
    order: {id: ASC}
  ) {
    totalCount
    nodes {
      companyId
      createdAt
      customerId
      email
      fullName
      id
      isActive
      languageId
      locationId
      permissions
      phoneNumber
      termsAndConditionId
      updatedAt
      userName
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useGetUsersByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      search: // value for 'search'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetUsersByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByCompanyIdQuery, GetUsersByCompanyIdQueryVariables> & ({ variables: GetUsersByCompanyIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByCompanyIdQuery, GetUsersByCompanyIdQueryVariables>(GetUsersByCompanyIdDocument, options);
      }
export function useGetUsersByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByCompanyIdQuery, GetUsersByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByCompanyIdQuery, GetUsersByCompanyIdQueryVariables>(GetUsersByCompanyIdDocument, options);
        }
export function useGetUsersByCompanyIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersByCompanyIdQuery, GetUsersByCompanyIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersByCompanyIdQuery, GetUsersByCompanyIdQueryVariables>(GetUsersByCompanyIdDocument, options);
        }
export type GetUsersByCompanyIdQueryHookResult = ReturnType<typeof useGetUsersByCompanyIdQuery>;
export type GetUsersByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetUsersByCompanyIdLazyQuery>;
export type GetUsersByCompanyIdSuspenseQueryHookResult = ReturnType<typeof useGetUsersByCompanyIdSuspenseQuery>;
export type GetUsersByCompanyIdQueryResult = Apollo.QueryResult<GetUsersByCompanyIdQuery, GetUsersByCompanyIdQueryVariables>;
export const UpdateUserAccountDocument = gql`
    mutation UpdateUserAccount($customerId: Int!, $userId: Int!, $roleId: Int) {
  updateUserAccount(
    input: {customerId: $customerId, userId: $userId, roleId: $roleId}
  ) {
    boolean
  }
}
    `;
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      userId: // value for 'userId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(UpdateUserAccountDocument, options);
      }
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = Apollo.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;
export const UnlinkUserAccountDocument = gql`
    mutation UnlinkUserAccount($customerId: Int!, $userId: Int!) {
  unlinkUserAccount(input: {customerId: $customerId, userId: $userId}) {
    boolean
  }
}
    `;
export type UnlinkUserAccountMutationFn = Apollo.MutationFunction<UnlinkUserAccountMutation, UnlinkUserAccountMutationVariables>;

/**
 * __useUnlinkUserAccountMutation__
 *
 * To run a mutation, you first call `useUnlinkUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkUserAccountMutation, { data, loading, error }] = useUnlinkUserAccountMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnlinkUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkUserAccountMutation, UnlinkUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkUserAccountMutation, UnlinkUserAccountMutationVariables>(UnlinkUserAccountDocument, options);
      }
export type UnlinkUserAccountMutationHookResult = ReturnType<typeof useUnlinkUserAccountMutation>;
export type UnlinkUserAccountMutationResult = Apollo.MutationResult<UnlinkUserAccountMutation>;
export type UnlinkUserAccountMutationOptions = Apollo.BaseMutationOptions<UnlinkUserAccountMutation, UnlinkUserAccountMutationVariables>;
export const GetTrucksByCustomerDocument = gql`
    query GetTrucksByCustomer($customerId: Int!, $cursor: String, $take: Int = 10) {
  vehicles(
    where: {customers: {some: {id: {eq: $customerId}}}}
    after: $cursor
    first: $take
    order: {id: ASC}
  ) {
    nodes {
      id
      vehicleNo
      isActive
      isBanned
      owner
      tare
      capacity
      maxWeight
      eamId
      trackingRefNo
      supplierId
      vehicleType {
        id
        description
      }
      supplier {
        supplierName
        supplierCode
      }
      vehicleTypeId
      transporterCode
    }
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetTrucksByCustomerQuery__
 *
 * To run a query within a React component, call `useGetTrucksByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrucksByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrucksByCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetTrucksByCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetTrucksByCustomerQuery, GetTrucksByCustomerQueryVariables> & ({ variables: GetTrucksByCustomerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrucksByCustomerQuery, GetTrucksByCustomerQueryVariables>(GetTrucksByCustomerDocument, options);
      }
export function useGetTrucksByCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrucksByCustomerQuery, GetTrucksByCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrucksByCustomerQuery, GetTrucksByCustomerQueryVariables>(GetTrucksByCustomerDocument, options);
        }
export function useGetTrucksByCustomerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTrucksByCustomerQuery, GetTrucksByCustomerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTrucksByCustomerQuery, GetTrucksByCustomerQueryVariables>(GetTrucksByCustomerDocument, options);
        }
export type GetTrucksByCustomerQueryHookResult = ReturnType<typeof useGetTrucksByCustomerQuery>;
export type GetTrucksByCustomerLazyQueryHookResult = ReturnType<typeof useGetTrucksByCustomerLazyQuery>;
export type GetTrucksByCustomerSuspenseQueryHookResult = ReturnType<typeof useGetTrucksByCustomerSuspenseQuery>;
export type GetTrucksByCustomerQueryResult = Apollo.QueryResult<GetTrucksByCustomerQuery, GetTrucksByCustomerQueryVariables>;
export const UpdateCustomerTrucksDocument = gql`
    mutation UpdateCustomerTrucks($customerId: Int!, $vehicles: [Int!]) {
  upsertCustomerVehicle(input: {customerId: $customerId, vehicles: $vehicles}) {
    customer {
      id
    }
  }
}
    `;
export type UpdateCustomerTrucksMutationFn = Apollo.MutationFunction<UpdateCustomerTrucksMutation, UpdateCustomerTrucksMutationVariables>;

/**
 * __useUpdateCustomerTrucksMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerTrucksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerTrucksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerTrucksMutation, { data, loading, error }] = useUpdateCustomerTrucksMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      vehicles: // value for 'vehicles'
 *   },
 * });
 */
export function useUpdateCustomerTrucksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerTrucksMutation, UpdateCustomerTrucksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerTrucksMutation, UpdateCustomerTrucksMutationVariables>(UpdateCustomerTrucksDocument, options);
      }
export type UpdateCustomerTrucksMutationHookResult = ReturnType<typeof useUpdateCustomerTrucksMutation>;
export type UpdateCustomerTrucksMutationResult = Apollo.MutationResult<UpdateCustomerTrucksMutation>;
export type UpdateCustomerTrucksMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerTrucksMutation, UpdateCustomerTrucksMutationVariables>;
export const GetPaginatedLocationsDocument = gql`
    query getPaginatedLocations($name: String!, $code: String!, $cursor: String, $take: Int = 10, $countryId: Int!) {
  locations(
    where: {or: [{name: {startsWith: $name}}, {code: {startsWith: $code}}]}
    after: $cursor
    first: $take
    order: {id: ASC}
    countryId: $countryId
  ) {
    totalCount
    nodes {
      id
      code
      company {
        name
      }
      name
      type
      costCentre
      pmEmployee
      businessUnitLine
      vatCode
      companyId
      updatedAt
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPaginatedLocationsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedLocationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetPaginatedLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedLocationsQuery, GetPaginatedLocationsQueryVariables> & ({ variables: GetPaginatedLocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedLocationsQuery, GetPaginatedLocationsQueryVariables>(GetPaginatedLocationsDocument, options);
      }
export function useGetPaginatedLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedLocationsQuery, GetPaginatedLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedLocationsQuery, GetPaginatedLocationsQueryVariables>(GetPaginatedLocationsDocument, options);
        }
export function useGetPaginatedLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedLocationsQuery, GetPaginatedLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedLocationsQuery, GetPaginatedLocationsQueryVariables>(GetPaginatedLocationsDocument, options);
        }
export type GetPaginatedLocationsQueryHookResult = ReturnType<typeof useGetPaginatedLocationsQuery>;
export type GetPaginatedLocationsLazyQueryHookResult = ReturnType<typeof useGetPaginatedLocationsLazyQuery>;
export type GetPaginatedLocationsSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedLocationsSuspenseQuery>;
export type GetPaginatedLocationsQueryResult = Apollo.QueryResult<GetPaginatedLocationsQuery, GetPaginatedLocationsQueryVariables>;
export const GetPaginatedLocationsByCompanyDocument = gql`
    query getPaginatedLocationsByCompany($name: String!, $code: String!, $companyId: Int!, $cursor: String, $take: Int = 10, $countryId: Int!) {
  locations(
    where: {and: [{name: {startsWith: $name}}, {code: {startsWith: $code}}, {companyId: {eq: $companyId}}]}
    countryId: $countryId
    after: $cursor
    first: $take
    order: {id: ASC}
  ) {
    totalCount
    nodes {
      id
      code
      company {
        name
      }
      type
      name
      companyId
      updatedAt
      costCentre
      pmEmployee
      businessUnitLine
      vatCode
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPaginatedLocationsByCompanyQuery__
 *
 * To run a query within a React component, call `useGetPaginatedLocationsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedLocationsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedLocationsByCompanyQuery({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      companyId: // value for 'companyId'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetPaginatedLocationsByCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedLocationsByCompanyQuery, GetPaginatedLocationsByCompanyQueryVariables> & ({ variables: GetPaginatedLocationsByCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedLocationsByCompanyQuery, GetPaginatedLocationsByCompanyQueryVariables>(GetPaginatedLocationsByCompanyDocument, options);
      }
export function useGetPaginatedLocationsByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedLocationsByCompanyQuery, GetPaginatedLocationsByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedLocationsByCompanyQuery, GetPaginatedLocationsByCompanyQueryVariables>(GetPaginatedLocationsByCompanyDocument, options);
        }
export function useGetPaginatedLocationsByCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedLocationsByCompanyQuery, GetPaginatedLocationsByCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedLocationsByCompanyQuery, GetPaginatedLocationsByCompanyQueryVariables>(GetPaginatedLocationsByCompanyDocument, options);
        }
export type GetPaginatedLocationsByCompanyQueryHookResult = ReturnType<typeof useGetPaginatedLocationsByCompanyQuery>;
export type GetPaginatedLocationsByCompanyLazyQueryHookResult = ReturnType<typeof useGetPaginatedLocationsByCompanyLazyQuery>;
export type GetPaginatedLocationsByCompanySuspenseQueryHookResult = ReturnType<typeof useGetPaginatedLocationsByCompanySuspenseQuery>;
export type GetPaginatedLocationsByCompanyQueryResult = Apollo.QueryResult<GetPaginatedLocationsByCompanyQuery, GetPaginatedLocationsByCompanyQueryVariables>;
export const UpsertLocationDocument = gql`
    mutation UpsertLocation($input: UpsertLocationInput!) {
  upsertLocation(input: $input) {
    location {
      id
    }
  }
}
    `;
export type UpsertLocationMutationFn = Apollo.MutationFunction<UpsertLocationMutation, UpsertLocationMutationVariables>;

/**
 * __useUpsertLocationMutation__
 *
 * To run a mutation, you first call `useUpsertLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLocationMutation, { data, loading, error }] = useUpsertLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLocationMutation, UpsertLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLocationMutation, UpsertLocationMutationVariables>(UpsertLocationDocument, options);
      }
export type UpsertLocationMutationHookResult = ReturnType<typeof useUpsertLocationMutation>;
export type UpsertLocationMutationResult = Apollo.MutationResult<UpsertLocationMutation>;
export type UpsertLocationMutationOptions = Apollo.BaseMutationOptions<UpsertLocationMutation, UpsertLocationMutationVariables>;
export const UpsertProductConstraintDocument = gql`
    mutation upsertProductConstraint($input: UpsertProductConstraintInput!) {
  upsertProductConstraint(input: $input) {
    productConstraint {
      id
    }
  }
}
    `;
export type UpsertProductConstraintMutationFn = Apollo.MutationFunction<UpsertProductConstraintMutation, UpsertProductConstraintMutationVariables>;

/**
 * __useUpsertProductConstraintMutation__
 *
 * To run a mutation, you first call `useUpsertProductConstraintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductConstraintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductConstraintMutation, { data, loading, error }] = useUpsertProductConstraintMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertProductConstraintMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProductConstraintMutation, UpsertProductConstraintMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertProductConstraintMutation, UpsertProductConstraintMutationVariables>(UpsertProductConstraintDocument, options);
      }
export type UpsertProductConstraintMutationHookResult = ReturnType<typeof useUpsertProductConstraintMutation>;
export type UpsertProductConstraintMutationResult = Apollo.MutationResult<UpsertProductConstraintMutation>;
export type UpsertProductConstraintMutationOptions = Apollo.BaseMutationOptions<UpsertProductConstraintMutation, UpsertProductConstraintMutationVariables>;
export const GetPaginatedProductConstraintsDocument = gql`
    query getPaginatedProductConstraints($companyId: Int!, $take: Int = 50, $cursor: String) {
  productConstraints(
    where: {companyId: {eq: $companyId}}
    first: $take
    after: $cursor
  ) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
      startCursor
    }
    nodes {
      id
      customerSegmentId
      customerSegment {
        name
        companyId
        company {
          countryId
          id
        }
      }
      productId
      product {
        title
        uoS
        unitOfMeasure {
          description
          id
        }
        productMeasurementId
      }
      minProductQuantity
      maxProductQuantity
      maxTruckQuantity
      minTruckQuantity
      companyId
    }
  }
}
    `;

/**
 * __useGetPaginatedProductConstraintsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedProductConstraintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedProductConstraintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedProductConstraintsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetPaginatedProductConstraintsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedProductConstraintsQuery, GetPaginatedProductConstraintsQueryVariables> & ({ variables: GetPaginatedProductConstraintsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedProductConstraintsQuery, GetPaginatedProductConstraintsQueryVariables>(GetPaginatedProductConstraintsDocument, options);
      }
export function useGetPaginatedProductConstraintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedProductConstraintsQuery, GetPaginatedProductConstraintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedProductConstraintsQuery, GetPaginatedProductConstraintsQueryVariables>(GetPaginatedProductConstraintsDocument, options);
        }
export function useGetPaginatedProductConstraintsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedProductConstraintsQuery, GetPaginatedProductConstraintsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedProductConstraintsQuery, GetPaginatedProductConstraintsQueryVariables>(GetPaginatedProductConstraintsDocument, options);
        }
export type GetPaginatedProductConstraintsQueryHookResult = ReturnType<typeof useGetPaginatedProductConstraintsQuery>;
export type GetPaginatedProductConstraintsLazyQueryHookResult = ReturnType<typeof useGetPaginatedProductConstraintsLazyQuery>;
export type GetPaginatedProductConstraintsSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedProductConstraintsSuspenseQuery>;
export type GetPaginatedProductConstraintsQueryResult = Apollo.QueryResult<GetPaginatedProductConstraintsQuery, GetPaginatedProductConstraintsQueryVariables>;
export const GetCustmerSegmentDocument = gql`
    query getCustmerSegment($companyId: Int!) {
  customerSegments(where: {companyId: {eq: $companyId}}) {
    nodes {
      name
      companyId
      id
    }
  }
}
    `;

/**
 * __useGetCustmerSegmentQuery__
 *
 * To run a query within a React component, call `useGetCustmerSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustmerSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustmerSegmentQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCustmerSegmentQuery(baseOptions: Apollo.QueryHookOptions<GetCustmerSegmentQuery, GetCustmerSegmentQueryVariables> & ({ variables: GetCustmerSegmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustmerSegmentQuery, GetCustmerSegmentQueryVariables>(GetCustmerSegmentDocument, options);
      }
export function useGetCustmerSegmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustmerSegmentQuery, GetCustmerSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustmerSegmentQuery, GetCustmerSegmentQueryVariables>(GetCustmerSegmentDocument, options);
        }
export function useGetCustmerSegmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCustmerSegmentQuery, GetCustmerSegmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustmerSegmentQuery, GetCustmerSegmentQueryVariables>(GetCustmerSegmentDocument, options);
        }
export type GetCustmerSegmentQueryHookResult = ReturnType<typeof useGetCustmerSegmentQuery>;
export type GetCustmerSegmentLazyQueryHookResult = ReturnType<typeof useGetCustmerSegmentLazyQuery>;
export type GetCustmerSegmentSuspenseQueryHookResult = ReturnType<typeof useGetCustmerSegmentSuspenseQuery>;
export type GetCustmerSegmentQueryResult = Apollo.QueryResult<GetCustmerSegmentQuery, GetCustmerSegmentQueryVariables>;
export const GetProductsByCompanyDocument = gql`
    query getProductsByCompany($companyId: Int!, $countryId: Int!) {
  products(countryId: $countryId, where: {companyId: {eq: $companyId}}) {
    nodes {
      companyId
      id
      title
      lookup
    }
  }
}
    `;

/**
 * __useGetProductsByCompanyQuery__
 *
 * To run a query within a React component, call `useGetProductsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useGetProductsByCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetProductsByCompanyQuery, GetProductsByCompanyQueryVariables> & ({ variables: GetProductsByCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsByCompanyQuery, GetProductsByCompanyQueryVariables>(GetProductsByCompanyDocument, options);
      }
export function useGetProductsByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsByCompanyQuery, GetProductsByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsByCompanyQuery, GetProductsByCompanyQueryVariables>(GetProductsByCompanyDocument, options);
        }
export function useGetProductsByCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductsByCompanyQuery, GetProductsByCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductsByCompanyQuery, GetProductsByCompanyQueryVariables>(GetProductsByCompanyDocument, options);
        }
export type GetProductsByCompanyQueryHookResult = ReturnType<typeof useGetProductsByCompanyQuery>;
export type GetProductsByCompanyLazyQueryHookResult = ReturnType<typeof useGetProductsByCompanyLazyQuery>;
export type GetProductsByCompanySuspenseQueryHookResult = ReturnType<typeof useGetProductsByCompanySuspenseQuery>;
export type GetProductsByCompanyQueryResult = Apollo.QueryResult<GetProductsByCompanyQuery, GetProductsByCompanyQueryVariables>;
export const DeleteProductConstraintDocument = gql`
    mutation DeleteProductConstraint($id: Int!) {
  deleteProductConstraint(input: {id: $id}) {
    boolean
  }
}
    `;
export type DeleteProductConstraintMutationFn = Apollo.MutationFunction<DeleteProductConstraintMutation, DeleteProductConstraintMutationVariables>;

/**
 * __useDeleteProductConstraintMutation__
 *
 * To run a mutation, you first call `useDeleteProductConstraintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductConstraintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductConstraintMutation, { data, loading, error }] = useDeleteProductConstraintMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductConstraintMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductConstraintMutation, DeleteProductConstraintMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductConstraintMutation, DeleteProductConstraintMutationVariables>(DeleteProductConstraintDocument, options);
      }
export type DeleteProductConstraintMutationHookResult = ReturnType<typeof useDeleteProductConstraintMutation>;
export type DeleteProductConstraintMutationResult = Apollo.MutationResult<DeleteProductConstraintMutation>;
export type DeleteProductConstraintMutationOptions = Apollo.BaseMutationOptions<DeleteProductConstraintMutation, DeleteProductConstraintMutationVariables>;
export const UpsertSettlementBankDocument = gql`
    mutation upsertSettlementBank($input: UpsertSettlementBankInput!) {
  upsertSettlementBank(input: $input) {
    settlementBank {
      id
    }
  }
}
    `;
export type UpsertSettlementBankMutationFn = Apollo.MutationFunction<UpsertSettlementBankMutation, UpsertSettlementBankMutationVariables>;

/**
 * __useUpsertSettlementBankMutation__
 *
 * To run a mutation, you first call `useUpsertSettlementBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSettlementBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSettlementBankMutation, { data, loading, error }] = useUpsertSettlementBankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSettlementBankMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSettlementBankMutation, UpsertSettlementBankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSettlementBankMutation, UpsertSettlementBankMutationVariables>(UpsertSettlementBankDocument, options);
      }
export type UpsertSettlementBankMutationHookResult = ReturnType<typeof useUpsertSettlementBankMutation>;
export type UpsertSettlementBankMutationResult = Apollo.MutationResult<UpsertSettlementBankMutation>;
export type UpsertSettlementBankMutationOptions = Apollo.BaseMutationOptions<UpsertSettlementBankMutation, UpsertSettlementBankMutationVariables>;
export const GetSettlementBanksByCompanyDocument = gql`
    query getSettlementBanksByCompany($companyId: Int!, $cursor: Int, $take: Int = 50) {
  settlementBanks(
    where: {companyId: {eq: $companyId}}
    first: $take
    last: $cursor
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
    }
    totalCount
    nodes {
      name
      isIncoming
      isActive
      sunAccountCode
      id
      description
      code
      companyId
    }
  }
}
    `;

/**
 * __useGetSettlementBanksByCompanyQuery__
 *
 * To run a query within a React component, call `useGetSettlementBanksByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettlementBanksByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettlementBanksByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetSettlementBanksByCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetSettlementBanksByCompanyQuery, GetSettlementBanksByCompanyQueryVariables> & ({ variables: GetSettlementBanksByCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettlementBanksByCompanyQuery, GetSettlementBanksByCompanyQueryVariables>(GetSettlementBanksByCompanyDocument, options);
      }
export function useGetSettlementBanksByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettlementBanksByCompanyQuery, GetSettlementBanksByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettlementBanksByCompanyQuery, GetSettlementBanksByCompanyQueryVariables>(GetSettlementBanksByCompanyDocument, options);
        }
export function useGetSettlementBanksByCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSettlementBanksByCompanyQuery, GetSettlementBanksByCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSettlementBanksByCompanyQuery, GetSettlementBanksByCompanyQueryVariables>(GetSettlementBanksByCompanyDocument, options);
        }
export type GetSettlementBanksByCompanyQueryHookResult = ReturnType<typeof useGetSettlementBanksByCompanyQuery>;
export type GetSettlementBanksByCompanyLazyQueryHookResult = ReturnType<typeof useGetSettlementBanksByCompanyLazyQuery>;
export type GetSettlementBanksByCompanySuspenseQueryHookResult = ReturnType<typeof useGetSettlementBanksByCompanySuspenseQuery>;
export type GetSettlementBanksByCompanyQueryResult = Apollo.QueryResult<GetSettlementBanksByCompanyQuery, GetSettlementBanksByCompanyQueryVariables>;
export const GetPaginatedSettlementBanksByCompanyIdDocument = gql`
    query getPaginatedSettlementBanksByCompanyId($companyId: Int!, $name: String!, $code: String!, $cursor: Int, $take: Int = 50) {
  settlementBanks(
    where: {or: [{name: {startsWith: $name}}, {code: {startsWith: $code}}], companyId: {eq: $companyId}}
    first: $take
    last: $cursor
    order: {name: ASC}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
    }
    totalCount
    nodes {
      name
      isIncoming
      isActive
      sunAccountCode
      id
      description
      code
      companyId
      company {
        id
        name
        code
        countryId
      }
    }
  }
}
    `;

/**
 * __useGetPaginatedSettlementBanksByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetPaginatedSettlementBanksByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedSettlementBanksByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedSettlementBanksByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPaginatedSettlementBanksByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedSettlementBanksByCompanyIdQuery, GetPaginatedSettlementBanksByCompanyIdQueryVariables> & ({ variables: GetPaginatedSettlementBanksByCompanyIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedSettlementBanksByCompanyIdQuery, GetPaginatedSettlementBanksByCompanyIdQueryVariables>(GetPaginatedSettlementBanksByCompanyIdDocument, options);
      }
export function useGetPaginatedSettlementBanksByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedSettlementBanksByCompanyIdQuery, GetPaginatedSettlementBanksByCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedSettlementBanksByCompanyIdQuery, GetPaginatedSettlementBanksByCompanyIdQueryVariables>(GetPaginatedSettlementBanksByCompanyIdDocument, options);
        }
export function useGetPaginatedSettlementBanksByCompanyIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedSettlementBanksByCompanyIdQuery, GetPaginatedSettlementBanksByCompanyIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedSettlementBanksByCompanyIdQuery, GetPaginatedSettlementBanksByCompanyIdQueryVariables>(GetPaginatedSettlementBanksByCompanyIdDocument, options);
        }
export type GetPaginatedSettlementBanksByCompanyIdQueryHookResult = ReturnType<typeof useGetPaginatedSettlementBanksByCompanyIdQuery>;
export type GetPaginatedSettlementBanksByCompanyIdLazyQueryHookResult = ReturnType<typeof useGetPaginatedSettlementBanksByCompanyIdLazyQuery>;
export type GetPaginatedSettlementBanksByCompanyIdSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedSettlementBanksByCompanyIdSuspenseQuery>;
export type GetPaginatedSettlementBanksByCompanyIdQueryResult = Apollo.QueryResult<GetPaginatedSettlementBanksByCompanyIdQuery, GetPaginatedSettlementBanksByCompanyIdQueryVariables>;
export const UpsertContentManagementRecordDocument = gql`
    mutation upsertContentManagementRecord($input: UpsertContentInput!) {
  upsertContent(input: $input) {
    content {
      id
    }
  }
}
    `;
export type UpsertContentManagementRecordMutationFn = Apollo.MutationFunction<UpsertContentManagementRecordMutation, UpsertContentManagementRecordMutationVariables>;

/**
 * __useUpsertContentManagementRecordMutation__
 *
 * To run a mutation, you first call `useUpsertContentManagementRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContentManagementRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContentManagementRecordMutation, { data, loading, error }] = useUpsertContentManagementRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertContentManagementRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContentManagementRecordMutation, UpsertContentManagementRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContentManagementRecordMutation, UpsertContentManagementRecordMutationVariables>(UpsertContentManagementRecordDocument, options);
      }
export type UpsertContentManagementRecordMutationHookResult = ReturnType<typeof useUpsertContentManagementRecordMutation>;
export type UpsertContentManagementRecordMutationResult = Apollo.MutationResult<UpsertContentManagementRecordMutation>;
export type UpsertContentManagementRecordMutationOptions = Apollo.BaseMutationOptions<UpsertContentManagementRecordMutation, UpsertContentManagementRecordMutationVariables>;
export const GetAllContentManagementByTypeDocument = gql`
    query getAllContentManagementByType($countryId: Int!, $contentType: Int!) {
  contents(
    countryId: $countryId
    contentType: $contentType
    order: {createdAt: DESC}
  ) {
    nodes {
      id
      title
      description
      featuredImageUrl
      fileName
      createdAt
    }
  }
}
    `;

/**
 * __useGetAllContentManagementByTypeQuery__
 *
 * To run a query within a React component, call `useGetAllContentManagementByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContentManagementByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContentManagementByTypeQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGetAllContentManagementByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetAllContentManagementByTypeQuery, GetAllContentManagementByTypeQueryVariables> & ({ variables: GetAllContentManagementByTypeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContentManagementByTypeQuery, GetAllContentManagementByTypeQueryVariables>(GetAllContentManagementByTypeDocument, options);
      }
export function useGetAllContentManagementByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContentManagementByTypeQuery, GetAllContentManagementByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContentManagementByTypeQuery, GetAllContentManagementByTypeQueryVariables>(GetAllContentManagementByTypeDocument, options);
        }
export function useGetAllContentManagementByTypeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllContentManagementByTypeQuery, GetAllContentManagementByTypeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllContentManagementByTypeQuery, GetAllContentManagementByTypeQueryVariables>(GetAllContentManagementByTypeDocument, options);
        }
export type GetAllContentManagementByTypeQueryHookResult = ReturnType<typeof useGetAllContentManagementByTypeQuery>;
export type GetAllContentManagementByTypeLazyQueryHookResult = ReturnType<typeof useGetAllContentManagementByTypeLazyQuery>;
export type GetAllContentManagementByTypeSuspenseQueryHookResult = ReturnType<typeof useGetAllContentManagementByTypeSuspenseQuery>;
export type GetAllContentManagementByTypeQueryResult = Apollo.QueryResult<GetAllContentManagementByTypeQuery, GetAllContentManagementByTypeQueryVariables>;
export const GePaginatedContentRecordsByTypeDocument = gql`
    query gePaginatedContentRecordsByType($contentType: Int!, $countryId: Int!, $cursor: Int, $take: Int = 50) {
  contents(
    contentType: $contentType
    countryId: $countryId
    first: $take
    last: $cursor
    order: {createdAt: DESC}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
    }
    totalCount
    nodes {
      id
      title
      description
      featuredImageUrl
      fileName
      createdAt
    }
  }
}
    `;

/**
 * __useGePaginatedContentRecordsByTypeQuery__
 *
 * To run a query within a React component, call `useGePaginatedContentRecordsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGePaginatedContentRecordsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGePaginatedContentRecordsByTypeQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      countryId: // value for 'countryId'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGePaginatedContentRecordsByTypeQuery(baseOptions: Apollo.QueryHookOptions<GePaginatedContentRecordsByTypeQuery, GePaginatedContentRecordsByTypeQueryVariables> & ({ variables: GePaginatedContentRecordsByTypeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GePaginatedContentRecordsByTypeQuery, GePaginatedContentRecordsByTypeQueryVariables>(GePaginatedContentRecordsByTypeDocument, options);
      }
export function useGePaginatedContentRecordsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GePaginatedContentRecordsByTypeQuery, GePaginatedContentRecordsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GePaginatedContentRecordsByTypeQuery, GePaginatedContentRecordsByTypeQueryVariables>(GePaginatedContentRecordsByTypeDocument, options);
        }
export function useGePaginatedContentRecordsByTypeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GePaginatedContentRecordsByTypeQuery, GePaginatedContentRecordsByTypeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GePaginatedContentRecordsByTypeQuery, GePaginatedContentRecordsByTypeQueryVariables>(GePaginatedContentRecordsByTypeDocument, options);
        }
export type GePaginatedContentRecordsByTypeQueryHookResult = ReturnType<typeof useGePaginatedContentRecordsByTypeQuery>;
export type GePaginatedContentRecordsByTypeLazyQueryHookResult = ReturnType<typeof useGePaginatedContentRecordsByTypeLazyQuery>;
export type GePaginatedContentRecordsByTypeSuspenseQueryHookResult = ReturnType<typeof useGePaginatedContentRecordsByTypeSuspenseQuery>;
export type GePaginatedContentRecordsByTypeQueryResult = Apollo.QueryResult<GePaginatedContentRecordsByTypeQuery, GePaginatedContentRecordsByTypeQueryVariables>;
export const DeleteContentManagementRecordDocument = gql`
    mutation DeleteContentManagementRecord($input: DeleteContentInput!) {
  deleteContent(input: $input) {
    boolean
  }
}
    `;
export type DeleteContentManagementRecordMutationFn = Apollo.MutationFunction<DeleteContentManagementRecordMutation, DeleteContentManagementRecordMutationVariables>;

/**
 * __useDeleteContentManagementRecordMutation__
 *
 * To run a mutation, you first call `useDeleteContentManagementRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentManagementRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentManagementRecordMutation, { data, loading, error }] = useDeleteContentManagementRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContentManagementRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContentManagementRecordMutation, DeleteContentManagementRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContentManagementRecordMutation, DeleteContentManagementRecordMutationVariables>(DeleteContentManagementRecordDocument, options);
      }
export type DeleteContentManagementRecordMutationHookResult = ReturnType<typeof useDeleteContentManagementRecordMutation>;
export type DeleteContentManagementRecordMutationResult = Apollo.MutationResult<DeleteContentManagementRecordMutation>;
export type DeleteContentManagementRecordMutationOptions = Apollo.BaseMutationOptions<DeleteContentManagementRecordMutation, DeleteContentManagementRecordMutationVariables>;
export const GePaginatedProductsDocument = gql`
    query gePaginatedProducts($countryId: Int!, $cursor: Int, $take: Int = 50) {
  products(
    countryId: $countryId
    first: $take
    last: $cursor
    order: {createdAt: DESC}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
    }
    totalCount
    nodes {
      id
      title
      description
      featuredImageUrl
      fileName
      companyId
      productMeasurementId
      unitOfMeasureId
      itemCode
      createdAt
      isPublished
    }
  }
}
    `;

/**
 * __useGePaginatedProductsQuery__
 *
 * To run a query within a React component, call `useGePaginatedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGePaginatedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGePaginatedProductsQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGePaginatedProductsQuery(baseOptions: Apollo.QueryHookOptions<GePaginatedProductsQuery, GePaginatedProductsQueryVariables> & ({ variables: GePaginatedProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GePaginatedProductsQuery, GePaginatedProductsQueryVariables>(GePaginatedProductsDocument, options);
      }
export function useGePaginatedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GePaginatedProductsQuery, GePaginatedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GePaginatedProductsQuery, GePaginatedProductsQueryVariables>(GePaginatedProductsDocument, options);
        }
export function useGePaginatedProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GePaginatedProductsQuery, GePaginatedProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GePaginatedProductsQuery, GePaginatedProductsQueryVariables>(GePaginatedProductsDocument, options);
        }
export type GePaginatedProductsQueryHookResult = ReturnType<typeof useGePaginatedProductsQuery>;
export type GePaginatedProductsLazyQueryHookResult = ReturnType<typeof useGePaginatedProductsLazyQuery>;
export type GePaginatedProductsSuspenseQueryHookResult = ReturnType<typeof useGePaginatedProductsSuspenseQuery>;
export type GePaginatedProductsQueryResult = Apollo.QueryResult<GePaginatedProductsQuery, GePaginatedProductsQueryVariables>;
export const UpsertProductDocument = gql`
    mutation UpsertProduct($input: UpsertProductInput!) {
  upsertProduct(input: $input) {
    product {
      id
    }
  }
}
    `;
export type UpsertProductMutationFn = Apollo.MutationFunction<UpsertProductMutation, UpsertProductMutationVariables>;

/**
 * __useUpsertProductMutation__
 *
 * To run a mutation, you first call `useUpsertProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductMutation, { data, loading, error }] = useUpsertProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertProductMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProductMutation, UpsertProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertProductMutation, UpsertProductMutationVariables>(UpsertProductDocument, options);
      }
export type UpsertProductMutationHookResult = ReturnType<typeof useUpsertProductMutation>;
export type UpsertProductMutationResult = Apollo.MutationResult<UpsertProductMutation>;
export type UpsertProductMutationOptions = Apollo.BaseMutationOptions<UpsertProductMutation, UpsertProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($input: DeleteProductInput!) {
  deleteProduct(input: $input) {
    boolean
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const GetUnitOfMeasureDocument = gql`
    query getUnitOfMeasure {
  unitsOfMeasure {
    id
    description
  }
}
    `;

/**
 * __useGetUnitOfMeasureQuery__
 *
 * To run a query within a React component, call `useGetUnitOfMeasureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitOfMeasureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitOfMeasureQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnitOfMeasureQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitOfMeasureQuery, GetUnitOfMeasureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitOfMeasureQuery, GetUnitOfMeasureQueryVariables>(GetUnitOfMeasureDocument, options);
      }
export function useGetUnitOfMeasureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitOfMeasureQuery, GetUnitOfMeasureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitOfMeasureQuery, GetUnitOfMeasureQueryVariables>(GetUnitOfMeasureDocument, options);
        }
export function useGetUnitOfMeasureSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUnitOfMeasureQuery, GetUnitOfMeasureQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnitOfMeasureQuery, GetUnitOfMeasureQueryVariables>(GetUnitOfMeasureDocument, options);
        }
export type GetUnitOfMeasureQueryHookResult = ReturnType<typeof useGetUnitOfMeasureQuery>;
export type GetUnitOfMeasureLazyQueryHookResult = ReturnType<typeof useGetUnitOfMeasureLazyQuery>;
export type GetUnitOfMeasureSuspenseQueryHookResult = ReturnType<typeof useGetUnitOfMeasureSuspenseQuery>;
export type GetUnitOfMeasureQueryResult = Apollo.QueryResult<GetUnitOfMeasureQuery, GetUnitOfMeasureQueryVariables>;
export const GetProductMeasurementsDocument = gql`
    query getProductMeasurements {
  productMeasurements {
    id
    description
  }
}
    `;

/**
 * __useGetProductMeasurementsQuery__
 *
 * To run a query within a React component, call `useGetProductMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductMeasurementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductMeasurementsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductMeasurementsQuery, GetProductMeasurementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductMeasurementsQuery, GetProductMeasurementsQueryVariables>(GetProductMeasurementsDocument, options);
      }
export function useGetProductMeasurementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductMeasurementsQuery, GetProductMeasurementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductMeasurementsQuery, GetProductMeasurementsQueryVariables>(GetProductMeasurementsDocument, options);
        }
export function useGetProductMeasurementsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductMeasurementsQuery, GetProductMeasurementsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductMeasurementsQuery, GetProductMeasurementsQueryVariables>(GetProductMeasurementsDocument, options);
        }
export type GetProductMeasurementsQueryHookResult = ReturnType<typeof useGetProductMeasurementsQuery>;
export type GetProductMeasurementsLazyQueryHookResult = ReturnType<typeof useGetProductMeasurementsLazyQuery>;
export type GetProductMeasurementsSuspenseQueryHookResult = ReturnType<typeof useGetProductMeasurementsSuspenseQuery>;
export type GetProductMeasurementsQueryResult = Apollo.QueryResult<GetProductMeasurementsQuery, GetProductMeasurementsQueryVariables>;
export const GetPaginatedContactsDocument = gql`
    query getPaginatedContacts($countryId: Int!, $cursor: Int, $take: Int = 50) {
  contacts(
    countryId: $countryId
    first: $take
    last: $cursor
    order: {createdAt: DESC}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
    }
    totalCount
    nodes {
      id
      location {
        name
        latitude
        longitude
      }
      locationId
      telephoneNumber
      physicalAddress
      postalAddress
      email
      createdAt
    }
  }
}
    `;

/**
 * __useGetPaginatedContactsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedContactsQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPaginatedContactsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedContactsQuery, GetPaginatedContactsQueryVariables> & ({ variables: GetPaginatedContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedContactsQuery, GetPaginatedContactsQueryVariables>(GetPaginatedContactsDocument, options);
      }
export function useGetPaginatedContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedContactsQuery, GetPaginatedContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedContactsQuery, GetPaginatedContactsQueryVariables>(GetPaginatedContactsDocument, options);
        }
export function useGetPaginatedContactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedContactsQuery, GetPaginatedContactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedContactsQuery, GetPaginatedContactsQueryVariables>(GetPaginatedContactsDocument, options);
        }
export type GetPaginatedContactsQueryHookResult = ReturnType<typeof useGetPaginatedContactsQuery>;
export type GetPaginatedContactsLazyQueryHookResult = ReturnType<typeof useGetPaginatedContactsLazyQuery>;
export type GetPaginatedContactsSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedContactsSuspenseQuery>;
export type GetPaginatedContactsQueryResult = Apollo.QueryResult<GetPaginatedContactsQuery, GetPaginatedContactsQueryVariables>;
export const UpsertContactDocument = gql`
    mutation UpsertContact($input: UpsertContactInput!) {
  upsertContact(input: $input) {
    contact {
      id
    }
  }
}
    `;
export type UpsertContactMutationFn = Apollo.MutationFunction<UpsertContactMutation, UpsertContactMutationVariables>;

/**
 * __useUpsertContactMutation__
 *
 * To run a mutation, you first call `useUpsertContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContactMutation, { data, loading, error }] = useUpsertContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertContactMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContactMutation, UpsertContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContactMutation, UpsertContactMutationVariables>(UpsertContactDocument, options);
      }
export type UpsertContactMutationHookResult = ReturnType<typeof useUpsertContactMutation>;
export type UpsertContactMutationResult = Apollo.MutationResult<UpsertContactMutation>;
export type UpsertContactMutationOptions = Apollo.BaseMutationOptions<UpsertContactMutation, UpsertContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($input: DeleteContactInput!) {
  deleteContact(input: $input) {
    boolean
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const UpsertPaymentApprovalDocument = gql`
    mutation upsertPaymentApproval($input: UpsertPaymentApprovalInput!) {
  upsertPaymentApproval(input: $input) {
    paymentApproval {
      id
    }
  }
}
    `;
export type UpsertPaymentApprovalMutationFn = Apollo.MutationFunction<UpsertPaymentApprovalMutation, UpsertPaymentApprovalMutationVariables>;

/**
 * __useUpsertPaymentApprovalMutation__
 *
 * To run a mutation, you first call `useUpsertPaymentApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPaymentApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPaymentApprovalMutation, { data, loading, error }] = useUpsertPaymentApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPaymentApprovalMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPaymentApprovalMutation, UpsertPaymentApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPaymentApprovalMutation, UpsertPaymentApprovalMutationVariables>(UpsertPaymentApprovalDocument, options);
      }
export type UpsertPaymentApprovalMutationHookResult = ReturnType<typeof useUpsertPaymentApprovalMutation>;
export type UpsertPaymentApprovalMutationResult = Apollo.MutationResult<UpsertPaymentApprovalMutation>;
export type UpsertPaymentApprovalMutationOptions = Apollo.BaseMutationOptions<UpsertPaymentApprovalMutation, UpsertPaymentApprovalMutationVariables>;
export const ApprovePaymentDocument = gql`
    mutation approvePayment($input: ApprovePaymentInput!) {
  approvePayment(input: $input) {
    payment {
      id
      paymentStatusId
      createdAt
    }
  }
}
    `;
export type ApprovePaymentMutationFn = Apollo.MutationFunction<ApprovePaymentMutation, ApprovePaymentMutationVariables>;

/**
 * __useApprovePaymentMutation__
 *
 * To run a mutation, you first call `useApprovePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePaymentMutation, { data, loading, error }] = useApprovePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApprovePaymentMutation(baseOptions?: Apollo.MutationHookOptions<ApprovePaymentMutation, ApprovePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApprovePaymentMutation, ApprovePaymentMutationVariables>(ApprovePaymentDocument, options);
      }
export type ApprovePaymentMutationHookResult = ReturnType<typeof useApprovePaymentMutation>;
export type ApprovePaymentMutationResult = Apollo.MutationResult<ApprovePaymentMutation>;
export type ApprovePaymentMutationOptions = Apollo.BaseMutationOptions<ApprovePaymentMutation, ApprovePaymentMutationVariables>;
export const UpsertPaymentTranscriptionDocument = gql`
    mutation upsertPaymentTranscription($input: UpsertPaymentTranscriptionInput!) {
  upsertPaymentTranscription(input: $input) {
    paymentTranscription {
      id
    }
  }
}
    `;
export type UpsertPaymentTranscriptionMutationFn = Apollo.MutationFunction<UpsertPaymentTranscriptionMutation, UpsertPaymentTranscriptionMutationVariables>;

/**
 * __useUpsertPaymentTranscriptionMutation__
 *
 * To run a mutation, you first call `useUpsertPaymentTranscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPaymentTranscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPaymentTranscriptionMutation, { data, loading, error }] = useUpsertPaymentTranscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertPaymentTranscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertPaymentTranscriptionMutation, UpsertPaymentTranscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertPaymentTranscriptionMutation, UpsertPaymentTranscriptionMutationVariables>(UpsertPaymentTranscriptionDocument, options);
      }
export type UpsertPaymentTranscriptionMutationHookResult = ReturnType<typeof useUpsertPaymentTranscriptionMutation>;
export type UpsertPaymentTranscriptionMutationResult = Apollo.MutationResult<UpsertPaymentTranscriptionMutation>;
export type UpsertPaymentTranscriptionMutationOptions = Apollo.BaseMutationOptions<UpsertPaymentTranscriptionMutation, UpsertPaymentTranscriptionMutationVariables>;
export const GetManualPaymentDocument = gql`
    query getManualPayment($paymentId: Int!) {
  payments(where: {and: [{id: {eq: $paymentId}}]}) {
    nodes {
      id
      amount
      paymentMode {
        name
      }
      paymentApprovals {
        id
        amountApproved
        settlementBankId
        reasonId
        paymentApprovalStatusId
      }
      salesVoucher {
        id
        voucherNumber
        orderQuote
        createdAt
        salesVoucherItems {
          id
          product {
            description
            title
            itemCode
            featuredImageUrl
            uoS
          }
          salesVoucherOrders {
            salesOrderNumber
          }
          trucksCount
          unitPrice
          quantity
        }
        customer {
          customerName
          customerCode
          email
          phoneNumber
          createdAt
        }
      }
      paymentModeId
      paymentMode {
        id
        name
        documentType {
          id
          description
        }
      }
      paymentReference
      paymentStatus {
        id
        description
      }
      paymentTranscriptions {
        bank {
          id
          name
        }
        bankBranch {
          id
          name
        }
        documentType {
          id
          description
        }
        amount
        depositorContact
        depositorName
        documentDate
        documentReference
        notes
        documentDate
      }
      paymentDocuments {
        createdAt
        documentName
        documentUrl
        id
        paymentId
        updatedAt
      }
      transactionId
      updatedAt
    }
  }
}
    `;

/**
 * __useGetManualPaymentQuery__
 *
 * To run a query within a React component, call `useGetManualPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualPaymentQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useGetManualPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetManualPaymentQuery, GetManualPaymentQueryVariables> & ({ variables: GetManualPaymentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManualPaymentQuery, GetManualPaymentQueryVariables>(GetManualPaymentDocument, options);
      }
export function useGetManualPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManualPaymentQuery, GetManualPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManualPaymentQuery, GetManualPaymentQueryVariables>(GetManualPaymentDocument, options);
        }
export function useGetManualPaymentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetManualPaymentQuery, GetManualPaymentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManualPaymentQuery, GetManualPaymentQueryVariables>(GetManualPaymentDocument, options);
        }
export type GetManualPaymentQueryHookResult = ReturnType<typeof useGetManualPaymentQuery>;
export type GetManualPaymentLazyQueryHookResult = ReturnType<typeof useGetManualPaymentLazyQuery>;
export type GetManualPaymentSuspenseQueryHookResult = ReturnType<typeof useGetManualPaymentSuspenseQuery>;
export type GetManualPaymentQueryResult = Apollo.QueryResult<GetManualPaymentQuery, GetManualPaymentQueryVariables>;
export const GetPaymentTranscriptionsDocument = gql`
    query getPaymentTranscriptions($paymentId: Int!) {
  paymentTranscriptions(where: {paymentId: {eq: $paymentId}}) {
    nodes {
      id
      amount
      bankId
      bank {
        id
        name
      }
      bankBranch {
        id
        name
      }
      bankBranchId
      bankId
      createdAt
      depositorContact
      depositorName
      documentDate
      documentReference
      documentType {
        id
        description
      }
      documentTypeId
      notes
      payment {
        id
      }
      paymentId
      paymentTranscriptionStatus {
        id
        description
      }
      paymentTranscriptionStatusId
      updatedAt
      createdBy {
        id
        email
        fullName
        userName
      }
    }
  }
}
    `;

/**
 * __useGetPaymentTranscriptionsQuery__
 *
 * To run a query within a React component, call `useGetPaymentTranscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentTranscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentTranscriptionsQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useGetPaymentTranscriptionsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentTranscriptionsQuery, GetPaymentTranscriptionsQueryVariables> & ({ variables: GetPaymentTranscriptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentTranscriptionsQuery, GetPaymentTranscriptionsQueryVariables>(GetPaymentTranscriptionsDocument, options);
      }
export function useGetPaymentTranscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentTranscriptionsQuery, GetPaymentTranscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentTranscriptionsQuery, GetPaymentTranscriptionsQueryVariables>(GetPaymentTranscriptionsDocument, options);
        }
export function useGetPaymentTranscriptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentTranscriptionsQuery, GetPaymentTranscriptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentTranscriptionsQuery, GetPaymentTranscriptionsQueryVariables>(GetPaymentTranscriptionsDocument, options);
        }
export type GetPaymentTranscriptionsQueryHookResult = ReturnType<typeof useGetPaymentTranscriptionsQuery>;
export type GetPaymentTranscriptionsLazyQueryHookResult = ReturnType<typeof useGetPaymentTranscriptionsLazyQuery>;
export type GetPaymentTranscriptionsSuspenseQueryHookResult = ReturnType<typeof useGetPaymentTranscriptionsSuspenseQuery>;
export type GetPaymentTranscriptionsQueryResult = Apollo.QueryResult<GetPaymentTranscriptionsQuery, GetPaymentTranscriptionsQueryVariables>;
export const GetPaymentApprovalsDocument = gql`
    query getPaymentApprovals($paymentId: Int!) {
  paymentApprovals(where: {paymentId: {eq: $paymentId}}) {
    nodes {
      id
      createdAt
      approvalComment
      amountApproved
      settlementBankId
      settlementBank {
        id
        name
      }
      paymentApprovalStatusId
      paymentApprovalStatus {
        id
        description
      }
      createdAt
      createdBy {
        id
        userName
      }
      rejectionComment
    }
  }
}
    `;

/**
 * __useGetPaymentApprovalsQuery__
 *
 * To run a query within a React component, call `useGetPaymentApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentApprovalsQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useGetPaymentApprovalsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentApprovalsQuery, GetPaymentApprovalsQueryVariables> & ({ variables: GetPaymentApprovalsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentApprovalsQuery, GetPaymentApprovalsQueryVariables>(GetPaymentApprovalsDocument, options);
      }
export function useGetPaymentApprovalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentApprovalsQuery, GetPaymentApprovalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentApprovalsQuery, GetPaymentApprovalsQueryVariables>(GetPaymentApprovalsDocument, options);
        }
export function useGetPaymentApprovalsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentApprovalsQuery, GetPaymentApprovalsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentApprovalsQuery, GetPaymentApprovalsQueryVariables>(GetPaymentApprovalsDocument, options);
        }
export type GetPaymentApprovalsQueryHookResult = ReturnType<typeof useGetPaymentApprovalsQuery>;
export type GetPaymentApprovalsLazyQueryHookResult = ReturnType<typeof useGetPaymentApprovalsLazyQuery>;
export type GetPaymentApprovalsSuspenseQueryHookResult = ReturnType<typeof useGetPaymentApprovalsSuspenseQuery>;
export type GetPaymentApprovalsQueryResult = Apollo.QueryResult<GetPaymentApprovalsQuery, GetPaymentApprovalsQueryVariables>;
export const GetPaymentsDataDocument = gql`
    query getPaymentsData($companyId: Int!, $cursor: String, $take: Int = 100) {
  payments(
    where: {and: [{salesVoucher: {companyId: {eq: $companyId}}}]}
    after: $cursor
    first: $take
    order: {id: DESC}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
    }
    totalCount
    nodes {
      id
      amount
      createdAt
      paymentMode {
        name
      }
      salesVoucher {
        id
        orderQuote
        voucherNumber
        customer {
          customerName
          customerCode
        }
      }
      paymentModeId
      paymentMode {
        id
        name
      }
      paymentReference
      paymentStatus {
        id
        description
      }
      amountApproved
      amount
      paymentDocuments {
        createdAt
        documentName
        documentUrl
        id
        paymentId
        updatedAt
      }
      transactionId
      updatedAt
    }
  }
}
    `;

/**
 * __useGetPaymentsDataQuery__
 *
 * To run a query within a React component, call `useGetPaymentsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPaymentsDataQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentsDataQuery, GetPaymentsDataQueryVariables> & ({ variables: GetPaymentsDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsDataQuery, GetPaymentsDataQueryVariables>(GetPaymentsDataDocument, options);
      }
export function useGetPaymentsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsDataQuery, GetPaymentsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsDataQuery, GetPaymentsDataQueryVariables>(GetPaymentsDataDocument, options);
        }
export function useGetPaymentsDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentsDataQuery, GetPaymentsDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentsDataQuery, GetPaymentsDataQueryVariables>(GetPaymentsDataDocument, options);
        }
export type GetPaymentsDataQueryHookResult = ReturnType<typeof useGetPaymentsDataQuery>;
export type GetPaymentsDataLazyQueryHookResult = ReturnType<typeof useGetPaymentsDataLazyQuery>;
export type GetPaymentsDataSuspenseQueryHookResult = ReturnType<typeof useGetPaymentsDataSuspenseQuery>;
export type GetPaymentsDataQueryResult = Apollo.QueryResult<GetPaymentsDataQuery, GetPaymentsDataQueryVariables>;
export const GetSalesVoucherPaymentsDocument = gql`
    query getSalesVoucherPayments($salesVoucherId: Int!) {
  payments(where: {and: [{salesVoucher: {id: {eq: $salesVoucherId}}}]}) {
    nodes {
      id
      amount
      paymentMode {
        name
      }
      salesVoucher {
        id
      }
      paymentModeId
      paymentMode {
        id
        name
      }
      paymentReference
      paymentStatus {
        id
        description
      }
      paymentDocuments {
        createdAt
        documentName
        documentUrl
        id
        paymentId
        updatedAt
      }
      transactionId
      updatedAt
    }
  }
}
    `;

/**
 * __useGetSalesVoucherPaymentsQuery__
 *
 * To run a query within a React component, call `useGetSalesVoucherPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesVoucherPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesVoucherPaymentsQuery({
 *   variables: {
 *      salesVoucherId: // value for 'salesVoucherId'
 *   },
 * });
 */
export function useGetSalesVoucherPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetSalesVoucherPaymentsQuery, GetSalesVoucherPaymentsQueryVariables> & ({ variables: GetSalesVoucherPaymentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesVoucherPaymentsQuery, GetSalesVoucherPaymentsQueryVariables>(GetSalesVoucherPaymentsDocument, options);
      }
export function useGetSalesVoucherPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesVoucherPaymentsQuery, GetSalesVoucherPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesVoucherPaymentsQuery, GetSalesVoucherPaymentsQueryVariables>(GetSalesVoucherPaymentsDocument, options);
        }
export function useGetSalesVoucherPaymentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSalesVoucherPaymentsQuery, GetSalesVoucherPaymentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSalesVoucherPaymentsQuery, GetSalesVoucherPaymentsQueryVariables>(GetSalesVoucherPaymentsDocument, options);
        }
export type GetSalesVoucherPaymentsQueryHookResult = ReturnType<typeof useGetSalesVoucherPaymentsQuery>;
export type GetSalesVoucherPaymentsLazyQueryHookResult = ReturnType<typeof useGetSalesVoucherPaymentsLazyQuery>;
export type GetSalesVoucherPaymentsSuspenseQueryHookResult = ReturnType<typeof useGetSalesVoucherPaymentsSuspenseQuery>;
export type GetSalesVoucherPaymentsQueryResult = Apollo.QueryResult<GetSalesVoucherPaymentsQuery, GetSalesVoucherPaymentsQueryVariables>;
export const GetPaymentByIdDocument = gql`
    query getPaymentById($paymentId: Int!) {
  payments(where: {and: [{id: {eq: $paymentId}}]}) {
    nodes {
      id
      amount
      paymentMode {
        name
      }
      salesVoucher {
        id
      }
      paymentModeId
      paymentMode {
        id
        name
      }
      paymentReference
      paymentStatus {
        id
        description
      }
      paymentDocuments {
        createdAt
        documentName
        documentUrl
        id
        paymentId
        updatedAt
      }
      transactionId
      updatedAt
    }
  }
}
    `;

/**
 * __useGetPaymentByIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentByIdQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useGetPaymentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentByIdQuery, GetPaymentByIdQueryVariables> & ({ variables: GetPaymentByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentByIdQuery, GetPaymentByIdQueryVariables>(GetPaymentByIdDocument, options);
      }
export function useGetPaymentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentByIdQuery, GetPaymentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentByIdQuery, GetPaymentByIdQueryVariables>(GetPaymentByIdDocument, options);
        }
export function useGetPaymentByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentByIdQuery, GetPaymentByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentByIdQuery, GetPaymentByIdQueryVariables>(GetPaymentByIdDocument, options);
        }
export type GetPaymentByIdQueryHookResult = ReturnType<typeof useGetPaymentByIdQuery>;
export type GetPaymentByIdLazyQueryHookResult = ReturnType<typeof useGetPaymentByIdLazyQuery>;
export type GetPaymentByIdSuspenseQueryHookResult = ReturnType<typeof useGetPaymentByIdSuspenseQuery>;
export type GetPaymentByIdQueryResult = Apollo.QueryResult<GetPaymentByIdQuery, GetPaymentByIdQueryVariables>;
export const UpsertQuickFilterDocument = gql`
    mutation upsertQuickFilter($input: UpsertPredefinedReportFilterInput!) {
  upsertPredefinedReportFilter(input: $input) {
    predefinedReportFilter {
      id
      name
    }
  }
}
    `;
export type UpsertQuickFilterMutationFn = Apollo.MutationFunction<UpsertQuickFilterMutation, UpsertQuickFilterMutationVariables>;

/**
 * __useUpsertQuickFilterMutation__
 *
 * To run a mutation, you first call `useUpsertQuickFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertQuickFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertQuickFilterMutation, { data, loading, error }] = useUpsertQuickFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertQuickFilterMutation(baseOptions?: Apollo.MutationHookOptions<UpsertQuickFilterMutation, UpsertQuickFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertQuickFilterMutation, UpsertQuickFilterMutationVariables>(UpsertQuickFilterDocument, options);
      }
export type UpsertQuickFilterMutationHookResult = ReturnType<typeof useUpsertQuickFilterMutation>;
export type UpsertQuickFilterMutationResult = Apollo.MutationResult<UpsertQuickFilterMutation>;
export type UpsertQuickFilterMutationOptions = Apollo.BaseMutationOptions<UpsertQuickFilterMutation, UpsertQuickFilterMutationVariables>;
export const GetQuickFiltersDocument = gql`
    query getQuickFilters($locationId: Int!, $reportTypeId: Int!) {
  predefinedReportFilters(
    where: {locationId: {eq: $locationId}, reportTypeId: {eq: $reportTypeId}}
  ) {
    id
    name
    data
    locationId
    reportTypeId
    reportType {
      description
    }
  }
}
    `;

/**
 * __useGetQuickFiltersQuery__
 *
 * To run a query within a React component, call `useGetQuickFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuickFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuickFiltersQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      reportTypeId: // value for 'reportTypeId'
 *   },
 * });
 */
export function useGetQuickFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetQuickFiltersQuery, GetQuickFiltersQueryVariables> & ({ variables: GetQuickFiltersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuickFiltersQuery, GetQuickFiltersQueryVariables>(GetQuickFiltersDocument, options);
      }
export function useGetQuickFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuickFiltersQuery, GetQuickFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuickFiltersQuery, GetQuickFiltersQueryVariables>(GetQuickFiltersDocument, options);
        }
export function useGetQuickFiltersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQuickFiltersQuery, GetQuickFiltersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuickFiltersQuery, GetQuickFiltersQueryVariables>(GetQuickFiltersDocument, options);
        }
export type GetQuickFiltersQueryHookResult = ReturnType<typeof useGetQuickFiltersQuery>;
export type GetQuickFiltersLazyQueryHookResult = ReturnType<typeof useGetQuickFiltersLazyQuery>;
export type GetQuickFiltersSuspenseQueryHookResult = ReturnType<typeof useGetQuickFiltersSuspenseQuery>;
export type GetQuickFiltersQueryResult = Apollo.QueryResult<GetQuickFiltersQuery, GetQuickFiltersQueryVariables>;
export const DeleteQuickFilterDocument = gql`
    mutation deleteQuickFilter($input: DeletePredefinedReportFilterInput!) {
  deletePredefinedReportFilter(input: $input) {
    boolean
  }
}
    `;
export type DeleteQuickFilterMutationFn = Apollo.MutationFunction<DeleteQuickFilterMutation, DeleteQuickFilterMutationVariables>;

/**
 * __useDeleteQuickFilterMutation__
 *
 * To run a mutation, you first call `useDeleteQuickFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuickFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuickFilterMutation, { data, loading, error }] = useDeleteQuickFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuickFilterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuickFilterMutation, DeleteQuickFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuickFilterMutation, DeleteQuickFilterMutationVariables>(DeleteQuickFilterDocument, options);
      }
export type DeleteQuickFilterMutationHookResult = ReturnType<typeof useDeleteQuickFilterMutation>;
export type DeleteQuickFilterMutationResult = Apollo.MutationResult<DeleteQuickFilterMutation>;
export type DeleteQuickFilterMutationOptions = Apollo.BaseMutationOptions<DeleteQuickFilterMutation, DeleteQuickFilterMutationVariables>;
export const GetSalesVouchersByCompanyDocument = gql`
    query getSalesVouchersByCompany($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!, $order: SortEnumType = DESC, $take: Int = 100, $cursor: String) {
  salesVouchers(
    order: {id: $order}
    first: $take
    after: $cursor
    where: {and: [{companyId: {eq: $companyId}}, {createdAt: {gte: $startDate}}, {createdAt: {lte: $endDate}}]}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
    nodes {
      id
      createdAt
      voucherNumber
      orderQuote
      salesVoucherStatusId
      createdAt
      destination {
        id
        name
      }
      createdBy {
        id
        userName
        fullName
      }
      salesVoucherStatus {
        id
        description
      }
      sourceLocation {
        id
        name
      }
      destinationId
      companyId
      customer {
        id
        customerName
      }
      salesVoucherItems {
        id
        destination {
          id
          name
        }
        salesVoucherOrders {
          salesOrderNumber
        }
        product {
          id
          title
          description
        }
        quantity
        trucksCount
      }
      payments {
        id
        paymentReference
        paymentStatusId
        paymentStatus {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetSalesVouchersByCompanyQuery__
 *
 * To run a query within a React component, call `useGetSalesVouchersByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesVouchersByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesVouchersByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetSalesVouchersByCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetSalesVouchersByCompanyQuery, GetSalesVouchersByCompanyQueryVariables> & ({ variables: GetSalesVouchersByCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesVouchersByCompanyQuery, GetSalesVouchersByCompanyQueryVariables>(GetSalesVouchersByCompanyDocument, options);
      }
export function useGetSalesVouchersByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesVouchersByCompanyQuery, GetSalesVouchersByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesVouchersByCompanyQuery, GetSalesVouchersByCompanyQueryVariables>(GetSalesVouchersByCompanyDocument, options);
        }
export function useGetSalesVouchersByCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSalesVouchersByCompanyQuery, GetSalesVouchersByCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSalesVouchersByCompanyQuery, GetSalesVouchersByCompanyQueryVariables>(GetSalesVouchersByCompanyDocument, options);
        }
export type GetSalesVouchersByCompanyQueryHookResult = ReturnType<typeof useGetSalesVouchersByCompanyQuery>;
export type GetSalesVouchersByCompanyLazyQueryHookResult = ReturnType<typeof useGetSalesVouchersByCompanyLazyQuery>;
export type GetSalesVouchersByCompanySuspenseQueryHookResult = ReturnType<typeof useGetSalesVouchersByCompanySuspenseQuery>;
export type GetSalesVouchersByCompanyQueryResult = Apollo.QueryResult<GetSalesVouchersByCompanyQuery, GetSalesVouchersByCompanyQueryVariables>;
export const GetEPaymentsByCompanyDocument = gql`
    query getEPaymentsByCompany($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!, $order: SortEnumType = DESC, $take: Int = 100, $cursor: String) {
  payments(
    order: {id: $order}
    first: $take
    after: $cursor
    where: {and: [{salesVoucher: {companyId: {eq: $companyId}}}, {paymentMode: {documentTypeId: {eq: 1}}}, {createdAt: {gte: $startDate}}, {createdAt: {lte: $endDate}}]}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
    nodes {
      amount
      amountApproved
      createdAt
      customReference
      id
      paymentApprovals {
        approvalComment
        createdBy {
          id
          userName
          fullName
        }
      }
      paymentMode {
        id
        name
        documentType {
          id
          description
        }
      }
      paymentReference
      paymentStatusId
      paymentStatus {
        id
        description
      }
      reason {
        detail
      }
      rejectionComment
    }
  }
}
    `;

/**
 * __useGetEPaymentsByCompanyQuery__
 *
 * To run a query within a React component, call `useGetEPaymentsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEPaymentsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEPaymentsByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetEPaymentsByCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetEPaymentsByCompanyQuery, GetEPaymentsByCompanyQueryVariables> & ({ variables: GetEPaymentsByCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEPaymentsByCompanyQuery, GetEPaymentsByCompanyQueryVariables>(GetEPaymentsByCompanyDocument, options);
      }
export function useGetEPaymentsByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEPaymentsByCompanyQuery, GetEPaymentsByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEPaymentsByCompanyQuery, GetEPaymentsByCompanyQueryVariables>(GetEPaymentsByCompanyDocument, options);
        }
export function useGetEPaymentsByCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEPaymentsByCompanyQuery, GetEPaymentsByCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEPaymentsByCompanyQuery, GetEPaymentsByCompanyQueryVariables>(GetEPaymentsByCompanyDocument, options);
        }
export type GetEPaymentsByCompanyQueryHookResult = ReturnType<typeof useGetEPaymentsByCompanyQuery>;
export type GetEPaymentsByCompanyLazyQueryHookResult = ReturnType<typeof useGetEPaymentsByCompanyLazyQuery>;
export type GetEPaymentsByCompanySuspenseQueryHookResult = ReturnType<typeof useGetEPaymentsByCompanySuspenseQuery>;
export type GetEPaymentsByCompanyQueryResult = Apollo.QueryResult<GetEPaymentsByCompanyQuery, GetEPaymentsByCompanyQueryVariables>;
export const GetManualPaymentsByCompanyDocument = gql`
    query getManualPaymentsByCompany($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!, $order: SortEnumType = DESC, $take: Int = 100, $cursor: String) {
  payments(
    order: {id: $order}
    first: $take
    after: $cursor
    where: {and: [{salesVoucher: {companyId: {eq: $companyId}}}, {paymentMode: {documentTypeId: {neq: 1}}}, {createdAt: {gte: $startDate}}, {createdAt: {lte: $endDate}}]}
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
    nodes {
      amount
      amountApproved
      createdAt
      customReference
      id
      paymentApprovals {
        approvalComment
        createdBy {
          id
          userName
          fullName
        }
      }
      paymentMode {
        id
        name
        documentType {
          id
          description
        }
      }
      paymentReference
      paymentStatusId
      paymentStatus {
        id
        description
      }
      reason {
        detail
      }
      rejectionComment
    }
  }
}
    `;

/**
 * __useGetManualPaymentsByCompanyQuery__
 *
 * To run a query within a React component, call `useGetManualPaymentsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualPaymentsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualPaymentsByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetManualPaymentsByCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetManualPaymentsByCompanyQuery, GetManualPaymentsByCompanyQueryVariables> & ({ variables: GetManualPaymentsByCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManualPaymentsByCompanyQuery, GetManualPaymentsByCompanyQueryVariables>(GetManualPaymentsByCompanyDocument, options);
      }
export function useGetManualPaymentsByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManualPaymentsByCompanyQuery, GetManualPaymentsByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManualPaymentsByCompanyQuery, GetManualPaymentsByCompanyQueryVariables>(GetManualPaymentsByCompanyDocument, options);
        }
export function useGetManualPaymentsByCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetManualPaymentsByCompanyQuery, GetManualPaymentsByCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManualPaymentsByCompanyQuery, GetManualPaymentsByCompanyQueryVariables>(GetManualPaymentsByCompanyDocument, options);
        }
export type GetManualPaymentsByCompanyQueryHookResult = ReturnType<typeof useGetManualPaymentsByCompanyQuery>;
export type GetManualPaymentsByCompanyLazyQueryHookResult = ReturnType<typeof useGetManualPaymentsByCompanyLazyQuery>;
export type GetManualPaymentsByCompanySuspenseQueryHookResult = ReturnType<typeof useGetManualPaymentsByCompanySuspenseQuery>;
export type GetManualPaymentsByCompanyQueryResult = Apollo.QueryResult<GetManualPaymentsByCompanyQuery, GetManualPaymentsByCompanyQueryVariables>;
export const GetUserReportDocument = gql`
    query getUserReport($cursor: String, $take: Int = 10000, $locationId: Int!) {
  users(
    where: {locationId: {eq: $locationId}}
    after: $cursor
    first: $take
    order: {id: ASC}
  ) {
    totalCount
    nodes {
      id
      userName
      fullName
      email
      locationId
      companyId
      companies {
        id
        name
        code
      }
      location {
        id
        name
        code
        type
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUserReportQuery__
 *
 * To run a query within a React component, call `useGetUserReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReportQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetUserReportQuery(baseOptions: Apollo.QueryHookOptions<GetUserReportQuery, GetUserReportQueryVariables> & ({ variables: GetUserReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserReportQuery, GetUserReportQueryVariables>(GetUserReportDocument, options);
      }
export function useGetUserReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserReportQuery, GetUserReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserReportQuery, GetUserReportQueryVariables>(GetUserReportDocument, options);
        }
export function useGetUserReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserReportQuery, GetUserReportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserReportQuery, GetUserReportQueryVariables>(GetUserReportDocument, options);
        }
export type GetUserReportQueryHookResult = ReturnType<typeof useGetUserReportQuery>;
export type GetUserReportLazyQueryHookResult = ReturnType<typeof useGetUserReportLazyQuery>;
export type GetUserReportSuspenseQueryHookResult = ReturnType<typeof useGetUserReportSuspenseQuery>;
export type GetUserReportQueryResult = Apollo.QueryResult<GetUserReportQuery, GetUserReportQueryVariables>;
export const GetPaginatedSalesVouchersDocument = gql`
    query GetPaginatedSalesVouchers($companyId: Int!, $customerName: String, $orderNumber: String, $cursor: String, $take: Int = 10) {
  salesVouchers(
    where: {companyId: {eq: $companyId}, and: [{voucherNumber: {contains: $orderNumber}}, {customer: {customerName: {contains: $customerName}}}]}
    after: $cursor
    first: $take
    order: {id: DESC}
  ) {
    edges {
      cursor
    }
    totalCount
    nodes {
      id
      voucherNumber
      customer {
        customerName
        customerCode
      }
      customerReference
      customOrderNumber
      customerId
      orderQuote
      salesVoucherStatus {
        description
      }
      updatedAt
      createdAt
      sourceLocation {
        name
      }
      destination {
        name
      }
      salesVoucherStatus {
        id
        description
      }
      payments {
        paymentMode {
          name
        }
        amount
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPaginatedSalesVouchersQuery__
 *
 * To run a query within a React component, call `useGetPaginatedSalesVouchersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedSalesVouchersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedSalesVouchersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customerName: // value for 'customerName'
 *      orderNumber: // value for 'orderNumber'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPaginatedSalesVouchersQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedSalesVouchersQuery, GetPaginatedSalesVouchersQueryVariables> & ({ variables: GetPaginatedSalesVouchersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedSalesVouchersQuery, GetPaginatedSalesVouchersQueryVariables>(GetPaginatedSalesVouchersDocument, options);
      }
export function useGetPaginatedSalesVouchersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedSalesVouchersQuery, GetPaginatedSalesVouchersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedSalesVouchersQuery, GetPaginatedSalesVouchersQueryVariables>(GetPaginatedSalesVouchersDocument, options);
        }
export function useGetPaginatedSalesVouchersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedSalesVouchersQuery, GetPaginatedSalesVouchersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedSalesVouchersQuery, GetPaginatedSalesVouchersQueryVariables>(GetPaginatedSalesVouchersDocument, options);
        }
export type GetPaginatedSalesVouchersQueryHookResult = ReturnType<typeof useGetPaginatedSalesVouchersQuery>;
export type GetPaginatedSalesVouchersLazyQueryHookResult = ReturnType<typeof useGetPaginatedSalesVouchersLazyQuery>;
export type GetPaginatedSalesVouchersSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedSalesVouchersSuspenseQuery>;
export type GetPaginatedSalesVouchersQueryResult = Apollo.QueryResult<GetPaginatedSalesVouchersQuery, GetPaginatedSalesVouchersQueryVariables>;
export const SalesVouchersCountDocument = gql`
    query salesVouchersCount($companyId: Int!) {
  salesVouchers(where: {companyId: {eq: $companyId}}) {
    totalCount
  }
}
    `;

/**
 * __useSalesVouchersCountQuery__
 *
 * To run a query within a React component, call `useSalesVouchersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesVouchersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesVouchersCountQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSalesVouchersCountQuery(baseOptions: Apollo.QueryHookOptions<SalesVouchersCountQuery, SalesVouchersCountQueryVariables> & ({ variables: SalesVouchersCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesVouchersCountQuery, SalesVouchersCountQueryVariables>(SalesVouchersCountDocument, options);
      }
export function useSalesVouchersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesVouchersCountQuery, SalesVouchersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesVouchersCountQuery, SalesVouchersCountQueryVariables>(SalesVouchersCountDocument, options);
        }
export function useSalesVouchersCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SalesVouchersCountQuery, SalesVouchersCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SalesVouchersCountQuery, SalesVouchersCountQueryVariables>(SalesVouchersCountDocument, options);
        }
export type SalesVouchersCountQueryHookResult = ReturnType<typeof useSalesVouchersCountQuery>;
export type SalesVouchersCountLazyQueryHookResult = ReturnType<typeof useSalesVouchersCountLazyQuery>;
export type SalesVouchersCountSuspenseQueryHookResult = ReturnType<typeof useSalesVouchersCountSuspenseQuery>;
export type SalesVouchersCountQueryResult = Apollo.QueryResult<SalesVouchersCountQuery, SalesVouchersCountQueryVariables>;
export const GetSalesVouchersDocument = gql`
    query getSalesVouchers($companyId: Int!) {
  salesVouchers(where: {companyId: {eq: $companyId}}, first: 20) {
    nodes {
      id
      voucherNumber
    }
  }
}
    `;

/**
 * __useGetSalesVouchersQuery__
 *
 * To run a query within a React component, call `useGetSalesVouchersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesVouchersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesVouchersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetSalesVouchersQuery(baseOptions: Apollo.QueryHookOptions<GetSalesVouchersQuery, GetSalesVouchersQueryVariables> & ({ variables: GetSalesVouchersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesVouchersQuery, GetSalesVouchersQueryVariables>(GetSalesVouchersDocument, options);
      }
export function useGetSalesVouchersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesVouchersQuery, GetSalesVouchersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesVouchersQuery, GetSalesVouchersQueryVariables>(GetSalesVouchersDocument, options);
        }
export function useGetSalesVouchersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSalesVouchersQuery, GetSalesVouchersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSalesVouchersQuery, GetSalesVouchersQueryVariables>(GetSalesVouchersDocument, options);
        }
export type GetSalesVouchersQueryHookResult = ReturnType<typeof useGetSalesVouchersQuery>;
export type GetSalesVouchersLazyQueryHookResult = ReturnType<typeof useGetSalesVouchersLazyQuery>;
export type GetSalesVouchersSuspenseQueryHookResult = ReturnType<typeof useGetSalesVouchersSuspenseQuery>;
export type GetSalesVouchersQueryResult = Apollo.QueryResult<GetSalesVouchersQuery, GetSalesVouchersQueryVariables>;
export const GetSalesVoucherDocument = gql`
    query getSalesVoucher($id: Int!, $companyId: Int!) {
  salesVouchers(where: {and: [{id: {eq: $id}}, {companyId: {eq: $companyId}}]}) {
    nodes {
      id
      voucherNumber
      customer {
        customerName
        customerCode
      }
      customerReference
      customOrderNumber
      customerId
      orderQuote
      updatedAt
      createdAt
    }
  }
}
    `;

/**
 * __useGetSalesVoucherQuery__
 *
 * To run a query within a React component, call `useGetSalesVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesVoucherQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetSalesVoucherQuery(baseOptions: Apollo.QueryHookOptions<GetSalesVoucherQuery, GetSalesVoucherQueryVariables> & ({ variables: GetSalesVoucherQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesVoucherQuery, GetSalesVoucherQueryVariables>(GetSalesVoucherDocument, options);
      }
export function useGetSalesVoucherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesVoucherQuery, GetSalesVoucherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesVoucherQuery, GetSalesVoucherQueryVariables>(GetSalesVoucherDocument, options);
        }
export function useGetSalesVoucherSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSalesVoucherQuery, GetSalesVoucherQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSalesVoucherQuery, GetSalesVoucherQueryVariables>(GetSalesVoucherDocument, options);
        }
export type GetSalesVoucherQueryHookResult = ReturnType<typeof useGetSalesVoucherQuery>;
export type GetSalesVoucherLazyQueryHookResult = ReturnType<typeof useGetSalesVoucherLazyQuery>;
export type GetSalesVoucherSuspenseQueryHookResult = ReturnType<typeof useGetSalesVoucherSuspenseQuery>;
export type GetSalesVoucherQueryResult = Apollo.QueryResult<GetSalesVoucherQuery, GetSalesVoucherQueryVariables>;
export const UpsertSalesVoucherDocument = gql`
    mutation UpsertSalesVoucher($input: UpsertSalesVoucherInput!) {
  upsertSalesVoucher(input: $input) {
    salesVoucher {
      id
    }
  }
}
    `;
export type UpsertSalesVoucherMutationFn = Apollo.MutationFunction<UpsertSalesVoucherMutation, UpsertSalesVoucherMutationVariables>;

/**
 * __useUpsertSalesVoucherMutation__
 *
 * To run a mutation, you first call `useUpsertSalesVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSalesVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSalesVoucherMutation, { data, loading, error }] = useUpsertSalesVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSalesVoucherMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSalesVoucherMutation, UpsertSalesVoucherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSalesVoucherMutation, UpsertSalesVoucherMutationVariables>(UpsertSalesVoucherDocument, options);
      }
export type UpsertSalesVoucherMutationHookResult = ReturnType<typeof useUpsertSalesVoucherMutation>;
export type UpsertSalesVoucherMutationResult = Apollo.MutationResult<UpsertSalesVoucherMutation>;
export type UpsertSalesVoucherMutationOptions = Apollo.BaseMutationOptions<UpsertSalesVoucherMutation, UpsertSalesVoucherMutationVariables>;
export const GetSalesVoucherDatailsByOrderIdDocument = gql`
    query getSalesVoucherDatailsByOrderId($orderNumber: String!) {
  salesVouchers(where: {voucherNumber: {eq: $orderNumber}}) {
    nodes {
      salesVoucherItems {
        id
        trucksCount
        quantity
        salesVoucherOrders {
          salesOrderNumber
          salesVoucherOrderStatus {
            description
          }
        }
        destination {
          name
        }
        product {
          itemCode
          title
          uoS
        }
      }
      customer {
        customerName
        customerCode
        phoneNumber
        active
      }
      salesVoucherStatus {
        description
      }
      payments {
        id
        amount
        salesVoucherId
        transactionId
        paymentMode {
          name
        }
        createdAt
        paymentReference
      }
      orderQuote
      sourceLocation {
        code
        pmEmployee
        contact {
          telephoneNumber
          email
        }
      }
    }
  }
}
    `;

/**
 * __useGetSalesVoucherDatailsByOrderIdQuery__
 *
 * To run a query within a React component, call `useGetSalesVoucherDatailsByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesVoucherDatailsByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesVoucherDatailsByOrderIdQuery({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *   },
 * });
 */
export function useGetSalesVoucherDatailsByOrderIdQuery(baseOptions: Apollo.QueryHookOptions<GetSalesVoucherDatailsByOrderIdQuery, GetSalesVoucherDatailsByOrderIdQueryVariables> & ({ variables: GetSalesVoucherDatailsByOrderIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesVoucherDatailsByOrderIdQuery, GetSalesVoucherDatailsByOrderIdQueryVariables>(GetSalesVoucherDatailsByOrderIdDocument, options);
      }
export function useGetSalesVoucherDatailsByOrderIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesVoucherDatailsByOrderIdQuery, GetSalesVoucherDatailsByOrderIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesVoucherDatailsByOrderIdQuery, GetSalesVoucherDatailsByOrderIdQueryVariables>(GetSalesVoucherDatailsByOrderIdDocument, options);
        }
export function useGetSalesVoucherDatailsByOrderIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSalesVoucherDatailsByOrderIdQuery, GetSalesVoucherDatailsByOrderIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSalesVoucherDatailsByOrderIdQuery, GetSalesVoucherDatailsByOrderIdQueryVariables>(GetSalesVoucherDatailsByOrderIdDocument, options);
        }
export type GetSalesVoucherDatailsByOrderIdQueryHookResult = ReturnType<typeof useGetSalesVoucherDatailsByOrderIdQuery>;
export type GetSalesVoucherDatailsByOrderIdLazyQueryHookResult = ReturnType<typeof useGetSalesVoucherDatailsByOrderIdLazyQuery>;
export type GetSalesVoucherDatailsByOrderIdSuspenseQueryHookResult = ReturnType<typeof useGetSalesVoucherDatailsByOrderIdSuspenseQuery>;
export type GetSalesVoucherDatailsByOrderIdQueryResult = Apollo.QueryResult<GetSalesVoucherDatailsByOrderIdQuery, GetSalesVoucherDatailsByOrderIdQueryVariables>;
export const GetSalesVoucherOrdersBySalesVoucherItemIdDocument = gql`
    query getSalesVoucherOrdersBySalesVoucherItemId($salesVoucherItemId: Int!) {
  salesVoucherOrders(where: {salesVoucherItemId: {eq: $salesVoucherItemId}}) {
    nodes {
      id
      salesOrderNumber
      paymentRef
      salesVoucherOrderStatus {
        description
      }
      quantity
      uoS
      destination {
        name
      }
      salesVoucherItem {
        id
        trucksCount
        quantity
        createdAt
        destination {
          name
        }
        product {
          itemCode
          title
          uoS
        }
      }
    }
  }
}
    `;

/**
 * __useGetSalesVoucherOrdersBySalesVoucherItemIdQuery__
 *
 * To run a query within a React component, call `useGetSalesVoucherOrdersBySalesVoucherItemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesVoucherOrdersBySalesVoucherItemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesVoucherOrdersBySalesVoucherItemIdQuery({
 *   variables: {
 *      salesVoucherItemId: // value for 'salesVoucherItemId'
 *   },
 * });
 */
export function useGetSalesVoucherOrdersBySalesVoucherItemIdQuery(baseOptions: Apollo.QueryHookOptions<GetSalesVoucherOrdersBySalesVoucherItemIdQuery, GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables> & ({ variables: GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesVoucherOrdersBySalesVoucherItemIdQuery, GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables>(GetSalesVoucherOrdersBySalesVoucherItemIdDocument, options);
      }
export function useGetSalesVoucherOrdersBySalesVoucherItemIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesVoucherOrdersBySalesVoucherItemIdQuery, GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesVoucherOrdersBySalesVoucherItemIdQuery, GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables>(GetSalesVoucherOrdersBySalesVoucherItemIdDocument, options);
        }
export function useGetSalesVoucherOrdersBySalesVoucherItemIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSalesVoucherOrdersBySalesVoucherItemIdQuery, GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSalesVoucherOrdersBySalesVoucherItemIdQuery, GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables>(GetSalesVoucherOrdersBySalesVoucherItemIdDocument, options);
        }
export type GetSalesVoucherOrdersBySalesVoucherItemIdQueryHookResult = ReturnType<typeof useGetSalesVoucherOrdersBySalesVoucherItemIdQuery>;
export type GetSalesVoucherOrdersBySalesVoucherItemIdLazyQueryHookResult = ReturnType<typeof useGetSalesVoucherOrdersBySalesVoucherItemIdLazyQuery>;
export type GetSalesVoucherOrdersBySalesVoucherItemIdSuspenseQueryHookResult = ReturnType<typeof useGetSalesVoucherOrdersBySalesVoucherItemIdSuspenseQuery>;
export type GetSalesVoucherOrdersBySalesVoucherItemIdQueryResult = Apollo.QueryResult<GetSalesVoucherOrdersBySalesVoucherItemIdQuery, GetSalesVoucherOrdersBySalesVoucherItemIdQueryVariables>;
export const GetPaginatedSalesOrdersDocument = gql`
    query getPaginatedSalesOrders($cursor: String, $take: Int = 10, $salesVoucher: String, $customerName: String) {
  salesVoucherOrders(
    where: {salesVoucherItem: {salesVoucher: {voucherNumber: {contains: $salesVoucher}, customer: {customerName: {contains: $customerName}}}}}
    after: $cursor
    first: $take
    order: {id: DESC}
  ) {
    totalCount
    __typename
    edges {
      cursor
    }
    nodes {
      salesVoucherItem {
        salesVoucher {
          voucherNumber
          salesVoucherStatus {
            description
          }
          customer {
            customerCode
            customerName
          }
        }
        product {
          itemCode
        }
      }
      quotationId
      salesOrderNumber
      salesVoucherOrderStatus {
        description
      }
      createdAt
      updatedAt
    }
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
  }
}
    `;

/**
 * __useGetPaginatedSalesOrdersQuery__
 *
 * To run a query within a React component, call `useGetPaginatedSalesOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedSalesOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedSalesOrdersQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      salesVoucher: // value for 'salesVoucher'
 *      customerName: // value for 'customerName'
 *   },
 * });
 */
export function useGetPaginatedSalesOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetPaginatedSalesOrdersQuery, GetPaginatedSalesOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedSalesOrdersQuery, GetPaginatedSalesOrdersQueryVariables>(GetPaginatedSalesOrdersDocument, options);
      }
export function useGetPaginatedSalesOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedSalesOrdersQuery, GetPaginatedSalesOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedSalesOrdersQuery, GetPaginatedSalesOrdersQueryVariables>(GetPaginatedSalesOrdersDocument, options);
        }
export function useGetPaginatedSalesOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaginatedSalesOrdersQuery, GetPaginatedSalesOrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaginatedSalesOrdersQuery, GetPaginatedSalesOrdersQueryVariables>(GetPaginatedSalesOrdersDocument, options);
        }
export type GetPaginatedSalesOrdersQueryHookResult = ReturnType<typeof useGetPaginatedSalesOrdersQuery>;
export type GetPaginatedSalesOrdersLazyQueryHookResult = ReturnType<typeof useGetPaginatedSalesOrdersLazyQuery>;
export type GetPaginatedSalesOrdersSuspenseQueryHookResult = ReturnType<typeof useGetPaginatedSalesOrdersSuspenseQuery>;
export type GetPaginatedSalesOrdersQueryResult = Apollo.QueryResult<GetPaginatedSalesOrdersQuery, GetPaginatedSalesOrdersQueryVariables>;