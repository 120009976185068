import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { DropdownItemSelectedParams } from "./DropdownItemSelectedParams";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import LanguageIcon from "@mui/icons-material/Language";

type Props = {
  searchList: string[];
  onItemSelected: (itemSelectedParams: DropdownItemSelectedParams) => void;
  initialSelectedIndex?: number;
  highlightedIndex?: number;
  size?: "sm" | "md" | "lg" | "xl";
  textSize?: number;
  frontLogoType?: "location" | "scale" | "language" | undefined;
  centeredPopup?: boolean;
  hideCaret?: boolean;
  className?: string;
  fixedDisplayElement?: JSX.Element;
};

const DropDown = ({
  searchList,
  onItemSelected,
  initialSelectedIndex = 0,
  size = "sm",
  textSize = 13,
  frontLogoType,
  centeredPopup = false,
  hideCaret = false,
  className,
  highlightedIndex,
  fixedDisplayElement,
}: Props) => {
  const [onItemSelectedProxy, setonItemSelectedProxy] =
    useState<(itemSelectedParams: DropdownItemSelectedParams) => void>();

  const [selectedOption, setSelectedOption] =
    useState<DropdownItemSelectedParams>({
      selectedItem: "",
      selectedIndex: -1,
      selectionProcess: "Automatic",
    });

  if (!selectedOption.selectedItem && searchList.length > 0) {
    initialSelectedIndex = initialSelectedIndex >= 0 ? initialSelectedIndex : 0;
    setSelectedOption({
      selectedItem: searchList[initialSelectedIndex],
      selectedIndex: initialSelectedIndex,
      selectionProcess: "Automatic",
    });
  }

  useEffect(() => {
    if (!onItemSelectedProxy && onItemSelected) {
      setonItemSelectedProxy(() => onItemSelected);
    }
  }, [onItemSelected, setonItemSelectedProxy, onItemSelectedProxy]);

  useEffect(() => {
    if (onItemSelectedProxy && selectedOption)
      onItemSelectedProxy(selectedOption);
  }, [selectedOption, onItemSelectedProxy]);

  return (
    <>
      <div
        className={`text-right w-full ${className}`}
        style={{
          zIndex: 11,
        }}
      >
        <Menu as="div" className="relative inline-block text-left w-full">
          <div className="flex items-center text-gray-700 ml-2 hover:text-gray-500">
            {frontLogoType === "location" && (
              <LocationOnOutlinedIcon className="" />
            )}
            {frontLogoType === "scale" && <ScaleOutlinedIcon className="" />}
            {frontLogoType === "language" && (
              <LanguageIcon fontSize="large" className="" />
            )}
            <Menu.Button
              className={` text-[${textSize}]
               ${
                 frontLogoType ? "!pl-1" : ""
               } inline-flex items-center w-full rounded-md ${
                fixedDisplayElement ? "" : "px-4"
              } py-2 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 whitespace-nowrap justify-between bg-white`}
            >
              {fixedDisplayElement ? (
                <>{fixedDisplayElement}</>
              ) : (
                selectedOption.selectedItem
              )}
              {!hideCaret && (
                <ChevronDownIcon
                  className="ml-2 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{
                transform: centeredPopup ? "translateX(-25%)" : "",
                maxHeight: "40rem",
              }}
              className="absolute mt-4 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full  z-11 overflow-y-auto overflow-x-hidden"
            >
              <div className="px-1 py-1 w-full">
                {searchList.map((item: string, index: number) => {
                  return (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            setSelectedOption({
                              selectedItem: item,
                              selectedIndex: index,
                              selectionProcess: "Manual",
                            });
                          }}
                          className={`${
                            active ? "bg-yardBlue text-white" : "text-gray-900"
                          }  group flex w-full items-center rounded-md px-2 py-2 text-[${textSize}] ${
                            highlightedIndex == index ? "bg-gray-300" : ""
                          }`}
                        >
                          {item}
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

export default DropDown;
